import axios from 'axios';
import { config } from '_config';
import { INftSpot } from '_interfaces';

import { AuthenticationService, customerAuthenticationService } from "_services/common";

class CustomerNftManagementService
{
  constructor (
    protected apiBaseUrl: string,
    protected authenticationService: AuthenticationService,
  ) {}

  // async updateOwner(tokenOwner: ITokenOwner, nftId: number | undefined = 0): Promise<any> {
  //   const url = `${this.apiBaseUrl}/management/nft/${nftId}`;
  //   try {
  //     const response: any = await axios.put(url, tokenOwner, await this.authenticationService.getAuthHeader());
  //     if (response.data.error) {
  //       throw Error(response.data.error.message);
  //     }
  //     return response.data.success as boolean;
  //   } catch (err: any) {
  //     throw Error(err.message);
  //   }
  // }

  async getMyNfts(): Promise<INftSpot[]> {
    const url = `${this.apiBaseUrl}/management/spots`;
    try {
      const response: any = await axios.get(url, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as INftSpot[];
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async updateVoucher (voucher: any, uid: number): Promise<any> {
    const url = `${this.apiBaseUrl}/management/nft/${uid}`;
    try {
      const response: any = await axios.put(url, {voucher}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (err: any) {
      throw Error((err as Error).message);
    }
  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const customerNftManagementService = new CustomerNftManagementService(apiBaseUrl, customerAuthenticationService);

export { customerNftManagementService, CustomerNftManagementService }