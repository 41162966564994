import React from "react";
import { IArticle } from "_interfaces";
import { customerArticleApiService } from "_services";
import { ListContainer } from "_common";
import { ArticleForm } from "./ArticleForm";
import { FormButtons } from "_constants";

const ArticleList: React.FunctionComponent = () => {
  const emptyValues: IArticle = {
    title: "",
    description: "",
  };

  const onSelectedRows = async (ids: string[]) => {};

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ["id", ["ID", undefined]],
    ["collectionId", ["Collection ID", undefined]],
    ["title", ["Title", undefined]],
    ["description", ["Description", undefined]],
    ["image", ["Image", undefined]],
  ]);

  return (
    <div className="container-md my-2">
      <ListContainer
        apiService={customerArticleApiService}
        emptyValues={emptyValues}
        onSelectedRows={onSelectedRows}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        hasMultiSelect={false}
        title="Manage Articles"
        fieldList={fieldList}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
          return (
            <ArticleForm
              isEditing={isEditing}
              onSuccess={onSuccess}
              initialValues={initialValues}
              onCancelClick={onCancelClick}
            />
          );
        }}
      </ListContainer>
    </div>
  );
};

export { ArticleList };
