import React, { useContext, useEffect, useState } from "react";
import { ICollection } from "_interfaces";

import { publicCollectionApiService } from "_services";
import PageTitle from "neftify/components/layout/PageTitle";
import { CollectionStatusEnum, Url } from "_constants";
import { Loading } from "_common";
import { MessengerContext } from "_providers";
import { useHistory } from "react-router-dom";

const Collections: React.FunctionComponent = (props: any) => {
  const [collections, setCollections] = useState<ICollection[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const messengerContext = useContext(MessengerContext);
  const history = useHistory();

  useEffect(() => {
    const ethAddress = props.match.params.ethAddress;
    const _init = async () => {
      try {
        const collections = await publicCollectionApiService.getAll();
        if (ethAddress) {
          setCollections(collections.filter((collection: ICollection) => collection.ownerEthAddress === ethAddress))          
        } else {
          setCollections(collections.filter((collection: ICollection) => collection.statusId === CollectionStatusEnum.ACTIVE));
        }
      } catch (error: any) {
        error.console('error loading Collections', {error});
        messengerContext.setMessage({title: 'Error', body: (error as Error).message});
      }
      setLoaded(true);
    };
    _init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CollectionItems = () => {
    return (
      <div className="container">
        <PageTitle pageTitle="Collections" pageTitleSub="Top Collections" child="" parent="" />
        {collections.sort((a, b) => b.creationTimestamp > a.creationTimestamp ? -1 : 1).map((item, i: number) => (
          <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6" key={`__collItem_key_${i}`}>
            <div className="card items">
              <div className="card-body">
                <div className="items-img position-relative">
                  <img src={`${item.imageUrl}`} className="img-fluid rounded mb-3" alt="" />
                  <img src={`${item?.user?.avatar}`} className="creator" width="50" alt="" />
                </div>

                <h4 className="card-title">{item.name}</h4>
                {/* <p>
                                {item.desc}
                            </p> */}

                <div className="d-flex justify-content-between">
                  {/* <div className="text-start">
                    <p className="mb-2">Auction</p>
                    <h5 className="text-muted">3h 1m 50s</h5>
                  </div> */}
                  {/* <div className="text-end">
                    <p className="mb-2">
                      Bid : <strong className="text-primary">{item.currentBid} ETH</strong>
                    </p>
                    <h5 className="text-muted">{item.price} ETH</h5>
                  </div> */}
                </div>

                <div className="d-flex justify-content-center mt-3">
                  <button type="button" className="btn btn-primary" onClick={()=>history.push(`${Url.CUSTOMER_COLLECTION_DETAIL_PATH}/${item.uid}`)}>
                    Details
                  </button>
                  {/* <a href={`${Url.CUSTOMER_COLLECTION_DETAIL_PATH}/${item.uid}`} className="btn btn-primary">
                    Details
                  </a> */}
                  {/* <a href="" className="btn btn-outline-primary">
                                    Details
                                </a> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="collections section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="filter-tab">
              {/* <div className="filter-nav mb-4">
                <a className="active">All</a>
                <a>Games</a>
                <a>Artwork</a>
              </div> */}
              <div className="row">
                {!loaded && <Loading />}
                {collections.length > 0 && <CollectionItems />}
                {loaded && collections.length === 0 && <div>Empty Collection</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Collections };
