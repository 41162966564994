import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useHistory } from "react-router";
import { AccountContext } from "_providers";
import { Url } from "_constants";
import { IAccountContext } from "_interfaces";

const Navigation = () => {
  const history = useHistory();

  const accountContext: IAccountContext = useContext(AccountContext);
 
  const onClickMyAccount = () => {
    history.push(Url.CUSTOMER_ACCOUNT_PROFILE);
  };

  const onClickLogin = () => {
    history.push(Url.ADMIN_LOGIN);

  }
  const logout = () => {
    accountContext.logout();
    history.push(Url.DEFAULT_HOME);
  };

  return (
    <Navbar expand="lg">
      <Navbar.Brand href={Url.ADMIN_HOME_PAGE}>MintDesk Admin</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="justify-content-end w-100">
          <Nav.Link href={Url.ADMIN_HOME_PAGE}>Home</Nav.Link>
          <Nav.Item>
            <div className="btn-toolbar ml-auto">
              {!accountContext?.account ? (
                <button type="button" className="btn btn-primary mx-2" onClick={onClickLogin}>Login</button>
              ) : (
                <>
                  {/*<Nav.Link href={Url.ADMIN_HOME_PAGE}>Labels and Mailings</Nav.Link>*/}
                  <Nav.Link href={Url.ADMIN_PROFILE} onClick={onClickMyAccount}>My Account</Nav.Link>
                  <Nav.Link href={Url.ADMIN_HOME_PAGE} onClick={logout}>Sign Out</Nav.Link>
                </>
              )}
            </div>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export { Navigation as AdminNavigation};
