import React from "react";
import { IDataField, ILayout } from "_interfaces";
import { adminLayoutApiService } from "_services";
import { ListContainer } from "_common";
import { AdminLayoutForm } from ".";
import { FormButtons, LayoutTypeEnum } from "_constants";

const LayoutList: React.FunctionComponent = () => {
  
  const emptyValues: ILayout = {
    userId: null,
    typeId: LayoutTypeEnum.ARTICLE_PLAIN,
    isDefault: false,
    name: "",
    header: "",
    title: "",
    footer: "",
    viewAllClickUrl: "",
    css: "",
  };

  const onSelectedRows = (ids: string[]) => {
    //console.log('selected ', ids);
  };

  const editableFields: IDataField[] = [
    { name: "uid", title: "UID", editable: false },
    { name: "userId", title: "User", editable: true },
    { name: "isDefault", title: "Default", editable: true },
    { name: "typeId", title: "Type ID", editable: false },
    { name: "name", title: "Name", editable: true },
    { name: "header", title: "Header", editable: true },
    { name: "title", title: "Title", editable: true },
    { name: "footer", title: "Footer", editable: true },
    { name: "css", title: "CSS", editable: true },
    { name: "viewAllClickUrl", title: "View All Click URL", editable: true },
  ];

  //@TODO: figure out why username is not rendering
  // const getUserName = (row: any) => {
  //   console.log('getUserName',{row});
  //   if (row["userId"]) {
  //     // Using async/await on this function causes an error.
  //     // Need to use .then/.catch instead of async/await. 
  //     adminUserApiService.getById(row["userId"])
  //     .then((user: IUser) => {console.log({user: user.username}); return <>{user.username}</>})
  //     .catch((error: any) => {
  //       console.error({error});
  //       return <>Err</>;
  //     })
  //   } else {
  //     return <>N/A</>
  //   }
  // };

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ["id", ["ID", undefined]],
    ["userId", ["User ID", undefined]], // @TODO: change with getUserName when you figure it out.
    ["typeId", ["Type ID", undefined]],
    ["name", ["Name", undefined]],
    ["header", ["Header", undefined]],
    ["title", ["Title", undefined]],
    ["footer", ["Footer", undefined]],
    ["isDefault", ["Is Default", undefined]],
    ["viewAllClickUrl", ["View All Click URL", undefined]],
    ["cssUrl", ["CSS URL", undefined]],
  ]);

  return (
    <ListContainer
      apiService={adminLayoutApiService}
      emptyValues={emptyValues}
      onSelectedRows={onSelectedRows}
      buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
      hasMultiSelect={false}
      title="Manage Layouts"
      fieldList={fieldList}
      editableFields={editableFields}
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <AdminLayoutForm
            isEditing={isEditing}
            onSuccess={onSuccess}
            initialValues={initialValues}
            onCancelClick={onCancelClick}
            editableFields={editableFields}
          />
        );
      }}
    </ListContainer>
  );
};

export { LayoutList as AdminLayoutList };
