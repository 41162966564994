import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { IAccount } from "_interfaces";
import { validationMap, Validator } from "_components/_schemas";
import { AddressInfo, ContactInfo, PersonalInfo } from "./_subforms";

import { AccountContext } from "_providers";
import { IAccountContext } from "_interfaces";

import { FileUpload } from "_common";
import { customerStorageService } from "_services";

const WalletProfile: React.FunctionComponent = () => {
  const accountContext: IAccountContext | null = useContext(AccountContext);

  const initialFormState: any = {
    firstName: accountContext?.account?.firstName,
    lastName: accountContext?.account?.lastName,
    email: accountContext?.account?.email,
    password: "",
    confirmPassword: "",
    address1: accountContext?.account?.address1,
    address2: accountContext?.account?.address2,
    city: accountContext?.account?.city,
    state: accountContext?.account?.state,
    zipcode: accountContext?.account?.zipcode,
    phoneNumber: accountContext?.account?.phoneNumber,
    dateOfBirth: accountContext?.account?.dateOfBirth,
    avatar: accountContext?.account?.avatar,
  };


  console.log({initialFormState});

  const validationSchema = validationMap.get(Validator.PROFILE_FORM);

  const onSubmit = async (data: any, actions: any) => {

    const { setStatus, setSubmitting } = actions;

    try {
      data.avatar = await customerStorageService.uploadImage(data.avatar);
    } catch (error: any) {
      setSubmitting(false);
      setStatus('Error uploading file');
      console.error({error});
      throw Error(error.message);      
    }

    if (data.password === "") {
      delete data.password;
      delete data.confirmPassword;
    }

    try {
      setSubmitting(true);
      await accountContext.update(data as IAccount);
      setStatus("Account Updated");

    } catch (err: any) {
      setStatus(`Error: ${err}`);
    }
  };

  return (
    <>
      <div className="container-lg py-1" id="questionnaire">
        <div className="d-flex justify-content-center my-5">
          <div className="medium-text-1 mb-3 fw-bold text-primary">My Account</div>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={initialFormState}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, errors, touched, setFieldValue, values }) => {
            return (
              <Form autoComplete="off">
                <pre>{JSON.stringify(values, null, 2)}</pre>
                <div className="container-md my-3">
                  <div className="row justify-content-center mt-5">
                    <div className="col-md-6">
                      {/* Personal Information*/}
                      <PersonalInfo errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />

                      <div className="my-5"></div>
                      <FileUpload
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        name="image-upload"
                        targetField="avatar"
                        touched={touched}
                        title="Avatar"
                      />

                      {accountContext.account.avatarUrl && 
                      <img src={accountContext.account.avatarUrl} alt="Avatar" />}

                    </div>


                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">
                        {/* Address */}
                        <AddressInfo errors={errors} touched={touched} />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">
                        {/* Contact Information*/}
                        <ContactInfo errors={errors} touched={touched} />
                      </div>
                    </div>


                  </div>

                  <div className="row justify-content-center mt-5">
                    <div className="col-md-6">{status && <div className="alert my-3">{status}</div>}</div>
                  </div>

                  <div className="row justify-content-center mt-5">
                    <div className="col-md-6 text-center">
                      <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-md">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export { WalletProfile };
