import React, { useEffect, useState } from 'react';
import { Helper } from '_common';

const TimeRemaining: React.FunctionComponent<{timeRemaining: any}>= ({timeRemaining}) => {
  return(
    <span className="js-countdown" data-timer="416400" data-labels=" :  ,  : , : , ">
      <div aria-hidden="true" className="countdown__timer">
        <span className="countdown__item">
          <span className="countdown__value countdown__value--0 js-countdown__value--0">{timeRemaining.days}</span>
          <span className="countdown__label">:</span>
        </span>
        <span className="countdown__item">
          <span className="countdown__value countdown__value--1 js-countdown__value--1">{timeRemaining.hours.toString().padStart(2, '0')}</span>
          <span className="countdown__label">:</span>
        </span>
        <span className="countdown__item">
          <span className="countdown__value countdown__value--2 js-countdown__value--2">{timeRemaining.minutes.toString().padStart(2, '0')}</span>
          <span className="countdown__label">:</span>
        </span>
        <span className="countdown__item">
          <span className="countdown__value countdown__value--3 js-countdown__value--3">{timeRemaining.seconds.toString().padStart(2, '0')}</span>
          <span className="countdown__label"></span>
        </span>
      </div>
    </span>    
  );
}

const CountdownTimer: React.FunctionComponent<{startDateTime: string, endDateTime: string, endedMessage: string, activeMessage: string}> = ({startDateTime, endDateTime, endedMessage, activeMessage}) => {

  let counter: number = 0;
  const [timeRemainingEndDate, setTimeRemainingEndDate] = useState<any>(undefined);

  useEffect(()=>{
    const timeRemainingEndDate = Helper.getSecondsRemaining(new Date(`${endDateTime} UTC` ));
    if (timeRemainingEndDate === 0) {
      setTimeRemainingEndDate('Ended');
    } else {

      // update the timer
      const i = window.setInterval(()=>{
        const timeRemainingEndDate = Helper.getTimeRemaining(new Date(`${endDateTime} UTC`), new Date());
        setTimeRemainingEndDate(timeRemainingEndDate);
        counter++;
      }, 1000)

      return( ()=> {
        // prevent multiple intervals. clear before unmounting.
        window.clearInterval(i);
      })

    }
  },[counter, endDateTime])

  return(
    <>
    {timeRemainingEndDate && timeRemainingEndDate === 'Ended' && 
    <div className="item count-down">
      {endedMessage}
    </div>
    }
    {timeRemainingEndDate && timeRemainingEndDate !== 'Ended' && 
    <div className="item count-down">
    <span className="heading style-2">{activeMessage}</span>

    <TimeRemaining timeRemaining={timeRemainingEndDate} />

  </div>} 
  </>
  )
}

export { CountdownTimer }