import React from "react";
import { BaseForm, FieldWithError, FileUpload } from "_common";
import { IDataField, IUser } from "_interfaces";
import { adminLayoutApiService as apiService, adminStorageService } from "_services";
import { Validator, validationMap } from "_components/_schemas";
import { LayoutTypeField } from "_common/form/LayoutTypeField";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IUser) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

const LayoutForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {

  const beforeSubmit = async (data: any, actions: any) => {
    if (!data.css || data.css === '') {
      delete data['css'];
    }
    else {
      try {
        console.log("beforeSubmit", { data });
        data.css = await adminStorageService.uploadImage(data.css);
      } catch (error: any) {
        throw Error(error.message);
      }
    }
  };

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_LAYOUT_FORM)}
      beforeSubmit={beforeSubmit}
      editableFields={editableFields}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <>
            {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            <div className="container">
              <div className="row">
                <div className="form-group">
                  <div>Layout Type</div>
                  <LayoutTypeField errors={errors} touched={touched} name="typeId" disabled={isEditing}/>
                </div>
              </div>

              <div className="row">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="userId"
                  placeholder="User ID"
                  label="User ID"
                />
              </div>

              <div className="row">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="name"
                  placeholder="Layout Name"
                  label="Layout Name"
                />
              </div>

              <div className="row">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="header"
                  placeholder="Layout Header"
                  label="Layout Header"
                />
              </div>

              <div className="row">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="title"
                  placeholder="Layout Title"
                  label="Layout Title"
                />
              </div>

              <div className="row">
                <FieldWithError errors={errors} touched={touched} fieldName="footer" placeholder="Footer" label="Footer" />
              </div>

              <div className="row">
                <div>css: {values['cssUrl']}</div>
                <FileUpload name="_css" errors={errors} setFieldValue={setFieldValue} targetField="css"
                title="CSS" touched={touched} values={values} readOnly={false} preview={false}/>

              </div>
              <div className="row">
                <FieldWithError errors={errors} touched={touched} fieldName="viewAllClickUrl" placeholder="View All Click URL" label="View All Click URL" />
              </div>
            </div>
          </>
        );
      }}
    </BaseForm>
  );
};

export { LayoutForm as AdminLayoutForm };
