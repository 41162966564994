import React, { useContext, useState } from "react";
import { IAccount, ICredentials } from "_interfaces";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { LoginForm, ModalWaiting } from "_common";
import { Role, Url } from "_constants";
import { AccountContext, Web3Context } from "_providers";
import web3 from "_connection/web3";

const Login: React.FunctionComponent = (props: any) => {

  const history = useHistory();
  const accountContext = useContext(AccountContext);
  const web3Context = useContext(Web3Context);
  const [ showModal, setShowModal ] = useState<boolean>(false);

  const initialFormState: ICredentials = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email address"),
    password: Yup.string().required("Password required"),
  });

  const onSubmit = async (data: any, actions: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    const redir = queryParams.get("redir");

    const { email, password } = data;
    const { setStatus } = actions;

    try {
      console.log('logging in ', {email, password});
      const account: IAccount = await accountContext.login({ email, password });

      // see if there's a redirect first and redirect there
      if (redir) {
        history.push(redir);
      } else {
        // if no redir, go to the Role's default page
        console.log({role: account.roleId});
        switch (account.roleId) {
          case Role.ADMIN:
            history.push(Url.ADMIN_HOME_PAGE);
            break;
          case Role.CUSTOMER:
          case Role.PUBLISHER:
            history.push(Url.CUSTOMER_DASHBOARD);
            break;
          default:
            history.push(Url.DEFAULT_HOME);
        }
      }
    } catch (error: any) {
      setStatus(`Invalid Login: ${error.message}`);
    }
  };

  const onClickRegister = () => {
    history.push(Url.CUSTOMER_REGISTRATION);
  };

  const onClickReconnect = async () => {
    setShowModal(true);
    await web3Context.connectWallet(web3);
    setShowModal(false);
    history.push(Url.CUSTOMER_ACCOUNT_PROFILE);
  }

  return (
    <>
    <ModalWaiting show={showModal} />
    <LoginForm
      onClickRegister={onClickRegister}
      onClickReconnect={onClickReconnect}
      initialFormState={initialFormState}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    />
    </>
  );
};

export { Login };
