import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { FieldWithError, ResaleTypeField } from "_common";
import { INftSpot } from "_interfaces";
import { AccountContext, MessengerContext } from "_providers";
import { customerSpotManagementApiService, nftService } from "_services";
import { validationMap, Validator } from "_components/_schemas";
import { NftSpotStatusEnum, ResaleTypeEnum } from "_constants";

const SellForm: React.FunctionComponent<{
  nftSpot: INftSpot;
  closeModal: () => void;
  refresh: () => void;
}> = ({ nftSpot, closeModal, refresh }) => {
  const initialValues: any = {
    price: 0,
    spotUid: nftSpot.uid,
    resaleType: ResaleTypeEnum.OFFER,
  };

  console.log({ initialValues, nftSpot });

  const validationSchema = validationMap.get(Validator.SPOT_RESELL_FORM);
  const accountContext = useContext(AccountContext);
  const messengerContext = useContext(MessengerContext);

  const onSubmit = async (data: any, actions: any) => {
    console.log("onsubmit", { data });
    const { setSubmitting } = actions;
    console.log({data})
    try {
      switch ( parseInt(data.resaleType)) {
        case ResaleTypeEnum.BUY_NOW:
          const tokenId = nftSpot.nft?.tokenId || 0;
          const price = data.price;
          const feePct = accountContext.account.feePctMd;
          if (!feePct) {
            throw Error('Fee % not found');
          }
          await nftService.createVoucherNftSpot(tokenId, price, feePct, nftSpot.uid || 0)
          break;
        case ResaleTypeEnum.OFFER:
          console.log("update postdata", { statusId: data.statusId, price: data.price });
          await customerSpotManagementApiService.update(
            { statusId: NftSpotStatusEnum.RESELLING_OFFER, price: data.price } as INftSpot,
            data.spotUid
          );
          break;
        default:
          throw Error('invalid resale type');
      }


      closeModal();
      refresh();
      messengerContext.setMessage({ title: "Success", body: "NFT set for resale" });
    } catch (error: any) {
      messengerContext.setMessage({ title: "Error", body: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
        return (
          <Form className="form container">
            <div className="form-group my-2">
              <span className="mr-2">Resale Type </span>
              <ResaleTypeField errors={errors} touched={touched} name="resaleType" />
            </div>

            <FieldWithError errors={errors} touched={touched} fieldName="price" label="Enter Price" placeholder="0" />

            <button type="submit" className="btn btn-primary mt-2" disabled={isSubmitting}>
              Submit
            </button>
            {isSubmitting && (
              <div className="spinner-border m-5" role="status">
                <span className="visually-hidden">Saving...</span>
              </div>
            )}
            {status && <div className="alert alert-warning">{status}</div>}
          </Form>
        );
      }}
    </Formik>
  );
};

export { SellForm };
