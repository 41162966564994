import React from "react";
import { Field } from "formik";
import { LayoutTypeNames } from "_constants";

const LayoutTypeField = ({ ...rest }) => {
  return (
    <Field {...rest} as="select">
      {
        Array.from(LayoutTypeNames.keys()).map( (key: number, i: number) =>
          <option key={`code_${i}`} value={key}>{LayoutTypeNames.get(key)}</option>
        )
      }

    </Field>
  );
};

export { LayoutTypeField };
