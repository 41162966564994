import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ProfilePage } from "_components";



const Dashboard = () => {

  const Bids = () => {
    return (
      <>
        <h4>Orders and Payments</h4>
      </>
    );
  };

  const Collections = () => {
    return (
      <>
        <h4>Tests</h4>

      </>
    );
  };

  return (
   
    <div>
      <Tabs defaultActiveKey="tests" id="orders-and-tests" className="mb-3">
      <Tab eventKey="profile" title="My Account Profile">
          <ProfilePage />
        </Tab>
        <Tab eventKey="collections" title="Collections">
          <Collections />
        </Tab>
        <Tab eventKey="bids" title="Bids">
          <Bids />
        </Tab>
      </Tabs>
    </div>
  );
};

export { Dashboard };
