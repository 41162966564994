import { NftSpotStatusEnum } from "_constants";
import { INft, INftSpot, ISpot } from "_interfaces";

class Helper {

  /**
   * Clones an object with only the given fields as its properties
   * @param fields 
   * @param object 
   * @returns 
   */
  public static cloneObject<T>(fields: string[], object: Object) {
    const clone: any = Object.assign({}, object);
    // eslint-disable-next-line array-callback-return
    Object.keys(clone).map( k => {
      if (fields.indexOf(k) < 0) {
        delete clone[k];
      }
     })
     return clone as T;
  }

  public static getSecondsRemaining(targetDate: Date): number {
    const today = new Date();

    console.log({targetDate});

    const diffInMs =  targetDate.getTime() - today.getTime();

    if (diffInMs < 0) {
      return 0;
    }

    // @TODO: localize the date
    return diffInMs / 1000;
  }

  public static getTimeRemaining(toDate: Date, fromDate: Date): {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  } {

    const diffInMs =  toDate.getTime() - fromDate.getTime();

    if (diffInMs < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    }

    const seconds = diffInMs / 1000;
    const minutes =  seconds / 60;
    const hours =  minutes / 60;
    const days = hours / 24;

    // localize the date
    return {
      days : Math.floor(days),
      hours: Math.floor(hours % 24),
      minutes: Math.floor(minutes % 60),
      seconds: Math.floor(seconds % 60),
    }
  }

  public static getUtcDate (date: Date) : string {
    if (!date) date = new Date();

    let year = date.getUTCFullYear();
    let month = (1 + date.getUTCMonth()).toString().padStart(2, '0');
    let day = date.getUTCDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }

  public static getFormattedDate (date: Date): string {
    if (!date) date = new Date();

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }

  public static getFormattedDateMDY ( date: Date) : string {
    if (!date) {
    	date = new Date();
    }

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

		return month + '/' + day + '/' + year;
  }

  /**
   * for example, if given 01/01/2020 12:30:26:
   * return 01/01/2020 00:00:00 if isEnd === false (in milliseconds)
   * return 01/01/2020 23:59:59 if isEnd === true  (in milliseconds)
   * @param timestamp 
   */
  public static getAbsoluteDateTime(date: Date, isEnd:boolean = false): number {
    if (isEnd) {
      return new Date(`${this.getFormattedDate(date)} 23:59:59` ).getTime()
    } else {
      return new Date(`${this.getFormattedDate(date)} 00:00:00` ).getTime()
    }
  }

  /**
   * return the date in integer format
   * @param date 
   * @returns 
   */
  public static getEpochTime (date: Date): number {
    return date.getTime() / 1000;
  }

  public static restoreDash(code: string): string {
    return code.slice(0,4) + '-' + code.slice(4);
  }

  public static async getPreviewFile (file: any) {
    return new Promise ( (resolved, reject) => {
      const reader = new window.FileReader();
      reader.onload = () => {
        resolved(reader.result);   
      }
      reader.onerror = () => {
        reject('could not upload');
      }
      reader.readAsDataURL(file);
    })
  }

  public static remainingShares = (nft: INft): number => {

    const amount = nft.amount || 0;
    const minted = nft.amountMinted || 0;

    return amount - minted;
  }

  public static shortenEthAddress = (ethAddress: string | undefined) => {
    if (!ethAddress) {
      return "";
    }
    // return first 5 last 5
    if (ethAddress.length <= 10) {
      return ethAddress;
    }
    return `${ethAddress.substring(2,7).toUpperCase()}...${ethAddress.slice(-5).toUpperCase()}`;
  }

  public static isMySpot (spot: ISpot | undefined, account: string): boolean {
    if (!spot || !spot.ownerEthAddress) {
      return false;
    }
    return spot.ownerEthAddress?.toUpperCase() === account?.toUpperCase() ? true : false;
  }

  public static isReselling = (nftSpot: INftSpot): boolean => {
    return [NftSpotStatusEnum.RESELLING_BUY_NOW, NftSpotStatusEnum.RESELLING_OFFER].indexOf(nftSpot.statusId || 0) >=0
  }

  public static isPublisherExclusive = (exclusiveEndDate: any): boolean => {
    return this.getEpochTime(new Date()) < this.getEpochTime(new Date(exclusiveEndDate));
  }

  public static formatEth = (n?: number | null): string => {
    return n ? `${n.toFixed(2)} ETH` : '';
  }

}

export { Helper }