import React, { useContext, useReducer } from "react";
import { ethers } from "ethers";
import { IAccount, IWeb3Context } from "../_interfaces";
import { Web3Context } from "./Web3Context";
import { customerAccountService } from "_services";
import { AccountContext } from "_providers";
import web3 from "_connection/web3";
import { MessengerContext } from "./MessengerContext";

interface IWeb3State {
  account: any; // this is the wallet address
  // provider: ethers.providers.Web3Provider | null,
  // signature: any;
}

const defaultWeb3State: IWeb3State = {
  account: null,
  // provider: null,
  // signature: null,
};

const web3Reducer = (state: any, action: any) => {
  switch (action.type) {
    case "ACCOUNT":
      console.log("web3Reducer", { state, action });
      return {
        account: action.account,
        //provider: action.provider,
        //signature: action.signature,
      };
    // case 'NETWORKID':
    //   return {
    //     account: state.account,
    //     networkId: action.networkId,
    //   }
    default:
      return defaultWeb3State;
  }
};

const Web3Provider: React.FunctionComponent = ({ children }) => {
  const [web3State, dispatchWeb3Action] = useReducer(web3Reducer, defaultWeb3State);
  const accountContext = useContext(AccountContext);

  const messengerContext = useContext(MessengerContext);

  const loadAccountHandler = async (provider: ethers.providers.ExternalProvider, connectToDb: boolean = false) => {
    let account: string;
    if (provider.request) {
      const accounts = await provider.request({ method: "eth_accounts" });
      //const accounts = await provider.send("eth_accounts", []); // deprecated
      account = accounts[0];
      console.log("found an account called loadAccountHandler", account);
      if (account) {
        dispatchWeb3Action({
          type: "ACCOUNT",
          account,
        });
        return account;
      }
    } else {
      throw Error('Invalid provider');
    }

    return null;
  };

  const disconnectHandler = () => {
    accountContext.logout();
    dispatchWeb3Action({
      type: "ACCOUNT",
      account: null,
    });
  };

  const connectWalletHandler = async (provider: ethers.providers.ExternalProvider) => {
    let accounts;
    if (provider.request) {
      try {
        accounts = await provider.request({method: "eth_requestAccounts"});
      } catch (error: any) {
        if (error.code === -32002) {
          messengerContext.setMessage({title: 'Wallet Error', body: <div>
            Message from wallet {error.message}.
            <br/>
            Tip: Open the wallet and check for messages.
          </div>})
        } else {
          messengerContext.setMessage({title: 'Wallet Error', body: (error as Error).message})
          
        }
        console.error('wallet error', (error as Error).message);
        throw Error('wallet error');
      }
    } else {
      throw Error('invalid provider');
    }
    console.log("connectWalletHandler", { accounts });
    if (accounts[0]) {
      try {
        const web3Provider: ethers.providers.Web3Provider = new ethers.providers.Web3Provider(web3);
        const signature = await customerAccountService.getSignatureFromSignedMessage(accounts[0], web3Provider);
        console.log("connectWalletHandler: about to call loginWithWallet", { signature: signature });

        const exists = await customerAccountService.checkEthAddress(accounts[0]);
        let accountResult: IAccount;
        if (exists) {
          accountResult = await accountContext.loginWithWallet(signature);
        } else {
          accountResult = await accountContext.registerWallet(signature);
        }
        console.log("connectWalletHandler account", accountResult);
      } catch (error: any) {
        console.error("connectWalletHandler error", { error });
        throw Error(error.message);
      }

      console.log("dispatching from connectWalletHandler");
      dispatchWeb3Action({
        type: "ACCOUNT",
        account: accounts[0],
        // provider: provider,
        // signature: signature
      });
      return accounts[0];
    }
  };

  const web3Context: IWeb3Context = {
    account: web3State.account,
    loadAccount: loadAccountHandler,
    disconnect: disconnectHandler,
    connectWallet: connectWalletHandler,
  };

  return <Web3Context.Provider value={web3Context}>{children}</Web3Context.Provider>;
};

export { Web3Provider };
