import React, { useEffect, useState } from "react";

import { customerNftManagementService, customerSpotManagementApiService } from "_services";
import { ModalBadge } from '_components/badge';
import { INftSpot } from "_interfaces";
import { Helper, Loading } from "_common";
import { BadgePreview } from "_components/badge/BadgePreview";
import { ModalSell } from "./common/ModalSell";
import { ModalCustomUrl } from "_components/_account";
import { NftSpotStatusEnum } from "_constants";

const MyNfts: React.FunctionComponent = () => {
  const [nftSpots, setNftSpots] = useState<INftSpot[]>([]);
  const [status, setStatus] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    const _load = async () => {
      try {
        console.log('calling getMyNfts');
        const nftSpots: any = await customerNftManagementService.getMyNfts();
        setNftSpots(nftSpots);
      } catch (error: any) {
        console.error("error loading MyFts", { error });
        setStatus((error as Error).message);
      }
      setLoaded(true);
    };
    _load();
  }, [counter]);
  return (
    <>
      {/* <pre>{JSON.stringify(nftSpots, null, 2)}</pre> */}
      {!loaded && <Loading />}
      {loaded &&
        <NFTCard nftSpots={nftSpots} setCounter={() => setCounter(counter + 1)} />
      }
      {status && <div className="alert alert-warning">{status}</div>}
    </>
  );
};


const NFTCard: React.FunctionComponent<{ nftSpots: INftSpot[], setCounter: any }> = ({ nftSpots, setCounter }) => {

  const [showModalBadge, setShowModalBadge] = useState<boolean>(false);
  const [showModalSell, setShowModalSell] = useState<boolean>(false);
  const [showModalCustomUrl, setShowModalCustomUrl] = useState<boolean>(false);
  const [customUrl, setCustomUrl] = useState<string>('');

  const [nftSpot, setNftSpot] = useState<INftSpot>({} as INftSpot)

  const onEditClick = (nftSpot: INftSpot) => {
    setNftSpot(nftSpot)
    setShowModalBadge(true);
  }

  const onEdit = () => {
    setCounter(); // fires the useEffect callback
  }

  const onSellClick = (nftSpot: INftSpot) => {
    console.log('selling', { nftSpot });
    setNftSpot(nftSpot);
    setShowModalSell(true);
  }

  const onCustomUrlClick = (nftSpot: INftSpot) => {
    setCustomUrl(`${nftSpot.article?.url}?mintdeskrefid=${nftSpot.uid}`)
    setShowModalCustomUrl(true);
  }

  const onCancelSale = async (nftSpot: INftSpot) => {
    if (!!!window.confirm('Are you sure?')) {
      return;
    }
    try {
      await customerSpotManagementApiService.update({ statusId: NftSpotStatusEnum.SOLD, price: null } as INftSpot, nftSpot.uid || 0);
      setCounter();
    } catch (error) {
      console.error('error canceling sale', { error });
      throw Error((error as Error).message);
    }
  }

  return (
    <>
      <ModalSell nftSpot={nftSpot} show={showModalSell} handleClose={() => setShowModalSell(false)} refresh={setCounter} />
      <ModalBadge nftSpot={nftSpot} show={showModalBadge} handleClose={() => setShowModalBadge(false)} onEdit={onEdit} />
      <ModalCustomUrl customUrl={customUrl} show={showModalCustomUrl} handleClose={() => setShowModalCustomUrl(false)} />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="row">
              {nftSpots ? (
                nftSpots.sort((a, b) => { return (a.purchaseDate as string) > (b.purchaseDate as string) ? -1 : 1 }).map((myNftSpot: INftSpot, i: number) => (
                  <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6" key={`_mynft_key_${i}`}>
                    <div className="card items">
                      <div className="card-body">
                        {/* <pre>{JSON.stringify(myNftSpot, null, 2)}</pre> */}
                        <div className="items-img position-relative">
                          <img src={myNftSpot.article?.imageUrl} className="img-fluid rounded mb-3" alt="" />
                          <img src="/images/avatar/1.jpg" className="creator" width="50" alt="" />
                        </div>
                        <h4 className="card-title">{myNftSpot?.article?.title}</h4>
                        {/* <h5 className="">{myNftSpot?.article?.description}</h5> */}
                        <div className="justify-content-center">
                          <div className="">Slot: {(myNftSpot?.nft?.position || 0) + 1}</div>
                          <div className="">Price Paid: {Helper.formatEth(myNftSpot?.purchasePrice)}</div>
                          <div className="">Purchase Date: {myNftSpot?.purchaseDate}</div>
                          <div>URL: {myNftSpot?.article?.url}</div>
                          {/* <ul>
                            <li>articleId: {myNftSpot.article?.uid}</li>
                            <li>nftId: {myNftSpot.nft?.uid}</li>
                            <li>spotId: {myNftSpot.uid}</li>
                          </ul> */}
                        </div>

                        {myNftSpot.badge && !myNftSpot.badge.title &&
                          <div className="alert alert-warning">This badge will not show until you customize it. Please click the
                            Edit Badge below to set it up.</div>
                        }
                        <h5 className="mt-4">Your Owner Badge Will Appear Like This:</h5>
                        {myNftSpot.badge && <BadgePreview badge={myNftSpot.badge} />}

                        {Helper.isReselling(myNftSpot) && (
                          <>
                            <h5 className="mt-3">FOR RESALE</h5>
                            <div>Price: {Helper.formatEth(myNftSpot.price)}</div>
                          </>
                        )}

                        <div className="row my-3">
                          <div className="col-md-4 my-2">
                            <button
                              type="button"
                              className="btn btn-primary h-100"
                              onClick={() => onEditClick(myNftSpot)}
                            >
                              Edit Badge
                            </button>
                          </div>
                          <div className="col-md-4 my-2">
                            <button type="button" className="btn btn-primary h-100" onClick={() => onCustomUrlClick(myNftSpot)}>
                              Badge URL
                            </button>
                          </div>
                          <div className="col-md-4 my-2">
                            {Helper.isReselling(myNftSpot) ?
                              <button type="button" className="btn btn-primary h-100" onClick={() => onCancelSale(myNftSpot)}>
                                Cancel Sale
                              </button>
                              :
                              <button type="button" className="btn btn-primary h-100" onClick={() => onSellClick(myNftSpot)}>
                                Sell My Badge
                              </button>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>No Data</>
              )}

              {nftSpots.length === 0 && <>Empty Collection</>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { MyNfts };
