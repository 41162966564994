import { IRoute } from "_interfaces";
import {
  Login,
  ProfilePage,
} from "_components";

import { Role, Url } from "_constants";
import { AdminContractList, AdminDashboard, AdminHomePage } from "_components/_admin";
import { AdminNftList } from "_components/_admin/_nft";
import { AdminLayoutList } from "_components/_admin/_layouts";

const adminRoutes: IRoute[] = [
  //Admin, Anonymous Routes
  { path: Url.ADMIN_HOME_PAGE, component: AdminHomePage },
  { path: Url.ADMIN_LOGIN, component: Login },

  // Admin, Authenticated Routes
  { path: Url.ADMIN_PROFILE, component: ProfilePage, roles: [Role.ADMIN] },
  { path: Url.ADMIN_DASHBOARD, component: AdminDashboard, roles: [Role.ADMIN] },
  { path: Url.ADMIN_CONTRACT, component: AdminContractList, roles: [Role.ADMIN] },
  { path: Url.ADMIN_NFT, component: AdminNftList, roles: [Role.ADMIN]},

  { path: Url.ADMIN_LAYOUT, component: AdminLayoutList, roles: [Role.ADMIN]},


];

export { adminRoutes }