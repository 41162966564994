enum NftEventTypeEnum {
  VOUCHER_CREATED             = 1,
  MINTED                      = 2,
  TRANSFER                    = 3,
  SALE                        = 4,
  BID                         = 5,
  OFFER                       = 6,
  AUCTION_STATUS              = 7,
  UNSUPPORTED                 = 10, //event is not supported
}

const NftEventTypeEnumName: Map<number, string> = new Map([
  [NftEventTypeEnum.VOUCHER_CREATED, 'Voucher Created'],
  [NftEventTypeEnum.MINTED, 'Minted'],
  [NftEventTypeEnum.TRANSFER, 'Transfer'],
  [NftEventTypeEnum.SALE, 'Sale'],
  [NftEventTypeEnum.BID, 'Bid'],
  [NftEventTypeEnum.OFFER, 'Offer'],
  [NftEventTypeEnum.AUCTION_STATUS, 'Auction Status'],
  [NftEventTypeEnum.UNSUPPORTED, 'Unsupported'],
])

export { NftEventTypeEnum, NftEventTypeEnumName }