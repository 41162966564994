/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRouter } from "./useRouter";
import { Url } from "../../../_constants/Urls";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AccountContext } from "_providers";
import { useContext } from "react";
import { IRoute } from "_interfaces";

const ProfileMenu: React.FunctionComponent<{offerCount?: number, menuItems?: Map<string, IRoute>}> = ({offerCount, menuItems}) => {
  const router = useRouter();
  const history = useHistory();
  const accountContext = useContext(AccountContext);
  const match = useRouteMatch();

  return (
    <>
      <ul className="settings-menu">
        {menuItems && Array.from(menuItems.entries()).map(([key, menuItem], i: number) => {
          if (!menuItem?.roles || (
            menuItem.roles &&
            (
              accountContext.account.roleId &&
              menuItem.roles.indexOf(accountContext.account.roleId) >= 0
            )
          )) {
            return (
              <li className={`${router.pathname.toLowerCase() === `${match.path}/${key.toLowerCase()}` ? "active" : ""}`} key={`__key_${i}`}>
                <a className="position-relative"
                  onClick={() => {
                    history.push(key.toLowerCase());
                  }}
                >
                  {key}
                  {offerCount && offerCount > 0 && `${match.path}/${key.toLowerCase()}` === Url.ACCOUNT_SECTION_OFFERS ?
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{offerCount}</span>
                    :
                    <></>
                  }
                </a>
              </li>
            );
          } else {
            return <span key={`__key_${i}`}></span>
          }
        })}
      </ul>
    </>
  );
}
export default ProfileMenu;
