import React, { useReducer, useState } from "react";
import { IMessengerContext, IMessage } from "_interfaces";
import { MessengerContext } from "./MessengerContext";
import { Modal } from "react-bootstrap";

const ModalMessage: React.FunctionComponent<{ show: any; handleClose: any; message: IMessage; }> = ({
  show,
  handleClose,
  message,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header>
        {message.title && <Modal.Title id="contained-modal-title-vcenter">{message.title}</Modal.Title>}
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-12">{message.body}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

interface IMessengerState {
  message: IMessage;
}

enum MessengerAction {
  SET = 1,
  CLEAR = 2,
}

const messageReducer = (state: any, action: any) => {
  switch (action.type) {
    case MessengerAction.CLEAR:
      return {
        message: "",
      };
    case MessengerAction.SET:
      return {
        message: action.payload.message,
      };
    default:
      throw Error("Invalid action in MessageReducer");
  }
};

const MessengerProvider: React.FunctionComponent<{ children: any }> = ({ children }) => {
  const defaultMessengerState: IMessengerState = {message: {} as IMessage};

  const [show, setShow] = useState<boolean>(false);
  const [messengerState, dispatchMessengerAction] = useReducer(messageReducer, defaultMessengerState);

  const setMessageHandler = (message: IMessage) => {
    dispatchMessengerAction({ type: MessengerAction.SET, payload: { message } });
    setShow(true);
  };

  const clearMessageHandler = () => {
    dispatchMessengerAction({ type: MessengerAction.CLEAR, payload: { message: {} as IMessage } });
  };

  const messengerContext: IMessengerContext = {
    message: messengerState.message,
    setMessage: setMessageHandler,
    clearMessage: clearMessageHandler,
  };

  return (
    <MessengerContext.Provider value={messengerContext}>
      <ModalMessage show={show} handleClose={() => setShow(false)} message={messengerState.message} />
      {children}
    </MessengerContext.Provider>
  );
};

export { MessengerProvider };
