import React from 'react';



const Slots:React.FunctionComponent = () => {

  // const initialValues = {
  //   slots: [
  //     {
  //       position: undefined,
  //       price: undefined
  //     }
  //   ]
  // }

  return(
    <>
    Slots
    </>
  );


}

export { Slots }