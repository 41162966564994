/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { AccountContext, MessengerContext } from "_providers";
import { ProfileForm, ProfileCard } from ".";
import { customerAccountService } from "_services";


const ProfilePage: React.FunctionComponent = () => {

  const accountContext = useContext(AccountContext);
  const messengerContext = useContext(MessengerContext);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onEdit = () => {
    setIsEditing(true);
  }

  const onCancel = () => {
    setIsEditing(false);
    window.scrollTo(0, 0);
  }

  const verify = async () => {
    try {

      await customerAccountService.verifyAccount();
      messengerContext.setMessage({ title: 'Verify Account', body: 'Please check your inbox for a verification email. Click the link on this email to confirm your email address.' })
    } catch (error: any) {
      console.error('verify error', { error });
      messengerContext.setMessage({ title: 'Verification Error', body: `An unexpected error occurred. ${(error as Error).message}` })
    }
  }

  return (
    <div className="row">
      <div className="col-xxl-4 col-xl-12">
        <div className="card welcome-profile">
          <div className="card-body">
            <img src={accountContext.account.avatarUrl} alt="" />
            <h4>
              Welcome, {accountContext.account.firstName} {accountContext.account.lastName}
            </h4>
            <p>Looks like you are not verified yet. Verify yourself to use the full potential of MintDesk.</p>

            <ul>
              <li>
                <a href="#">
                  <span className="verified">
                    <i className="ri-check-line"></i>
                  </span>
                  Verify account
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="not-verified">
                    <i className="ri-shield-check-line"></i>
                  </span>
                  Two-factor authentication (2FA)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-xxl-4 col-xl-6">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">VERIFY &amp; UPGRADE </h4>
          </div>
          <div className="card-body">
            <h5>
              Account Status :{" "}
              {accountContext.account.emailConfirmed ? (
                <span className="text-success">
                  Confirmed <i className="icofont-success"></i>
                </span>
              ) : (
                <>
                  <span className="text-warning">
                    Pending <i className="icofont-warning"></i>
                  </span>
                  <p>Your account is unverified. Get verified to enable funding, trading, and withdrawal.</p>
                  <button className="btn btn-primary" onClick={verify}>
                    {" "}
                    Get Verified
                  </button>
                </>
              )}
            </h5>
          </div>
        </div>
      </div>
      <div className="col-xxl-12">{isEditing ? <ProfileForm onCancel={onCancel} /> : <ProfileCard onEdit={onEdit} />}</div>
    </div>
  );
};

export { ProfilePage };
