import PageTitle from "neftify/components/layout/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helper, Loading } from "_common";
import { NftPriceTypeEnum, NftStatusEnum, Url } from "_constants";
import { IArticle, ICollection, IMessage, INft } from "_interfaces";
import { AccountContext, MessengerContext, Web3Context } from "_providers";

import { publicCollectionApiService, publicArticleApiService } from "_services";

const Slot = ({ slot, userAddress, articleId }: { slot: INft; userAddress: string; articleId: number }) => {

   const history = useHistory();
   const accountContext = useContext(AccountContext);
   const messengerContext = useContext(MessengerContext);
   const web3Context = useContext(Web3Context);

   const buy = (url: string) => {
    if (!accountContext.account) {
      return messengerContext.setMessage({
        title: 'Buy',
        body: 'You need to be logged in to Buy this item'
      } as IMessage);
    }
    history.push(url)
   }

   const bid = (url: string) => {
    if (!accountContext.account) {
      return messengerContext.setMessage({
        title: 'Bid',
        body: 'You need to be logged in to Bid on this item'
      } as IMessage);
    }
    history.push(url)
   }

  switch (slot.priceTypeId) {
    case NftPriceTypeEnum.FIXED:
      return (
        <>
          <h5>{`Slot ${slot.position ? slot.position + 1 : 1} - ${slot.priceFixed ? Helper.formatEth(slot.priceFixed) : ''}`}</h5>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => buy(`${Url.CUSTOMER_NFT_BUY_PATH}/${articleId}/${slot.uid}`)}
          >
            Buy
          </button>
        </>
      );
    case NftPriceTypeEnum.AUCTION:
      return (
        <>
          <h5>{`Slot ${slot.position ? slot.position + 1 : 1} - ${slot.priceReserve} ETH`}</h5>
          {/* <pre>{JSON.stringify(slot, null, 2)}</pre> */}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => bid(`${Url.CUSTOMER_NFT_BID_PATH}/${articleId}/${slot.uid}${slot.spots && slot.spots[0] ? `/${slot.spots[0].uid}` : '' }`)}
          >
            {slot.lastBidder !== null && slot.statusId === NftStatusEnum.SOLD && slot.spots && slot.spots.length > 0 && Helper.isMySpot(slot.spots[0], web3Context.account) ? "Owned - View Details" : 
            slot.statusId === NftStatusEnum.SELLING && new Date(`${slot.auctionEnd} UTC`).getTime() > new Date().getTime() ? "Bid" : 
            slot.statusId === NftStatusEnum.SOLD ? "Make an Offer" : "Details" }
          </button>
        </>
      );
    default:
      return <></>;
  }
};

const Collection: React.FunctionComponent = (props: any) => {
  const id = props.match.params.id;
  const [collection, setCollection] = useState<ICollection>({} as ICollection);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [status, setStatus] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false);
  const web3Context = useContext(Web3Context);

  useEffect(() => {
    const _load = async () => {
      try {
        const collection = await publicCollectionApiService.getById(id);
        setCollection(collection);
        const articles = await publicArticleApiService.getByCollectionId(id);
        setArticles(articles);
      } catch (error: any) {
        console.error(error);
        setStatus(`Error: ${(error as Error).message}`);
      }
      setLoaded(true);
    };

    _load();
  }, [id]);

  return (
    <>
      <div className="slot">
        <div className="container">
          {collection && (
            <PageTitle
              pageTitle={collection.name ? collection.name : ""}
              pageTitleSub={collection.description ? collection.description : ""}
              child=""
              parent=""
            />
          )}

          {!loaded && <Loading />}
          {articles ?
            articles.length > 0 &&
            articles.map((article: IArticle, i: number) => (
              <div className="row" key={`article_key_${i}`}>
                <div className="col-xl-12">
                  <h2>{article.title}</h2>
                  <div>{article.description}</div>
                  <div className="mb-2">URL: {article.url}</div>
                  <div className="slot-content mb-5">
                    <div className="row align-content-center justify-content-between">
                      <div className="col-xl-6">
                        <div className="slot-img">
                          <img src={article.imageUrl} alt={article.title} className="img-fluid rounded" />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <h4 className="card-title mb-3">Available Slots</h4>
                        <div className=" card slot-list ">
                          <div className="card-body">
                            <ul>
                              {article.slots &&
                                article.slots.length > 0 &&
                                article.slots.map((slot: any, i: number) => (
                                  <li className="d-flex justify-content-between my-3" key={`_article_slot_key_${i}`}>
                                    <Slot slot={slot} userAddress={web3Context.account} articleId={article.uid ? article.uid : 0} />
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )) : <>No Data</>}
        </div>
      </div>

      {/* <pre>{JSON.stringify(collection, null, 2)}</pre>
      <pre>{JSON.stringify(articles, null, 2)}</pre> */}
      {status && <div className="alert alert-warning">{status}</div>}
    </>
  );
};

export { Collection };
