import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Web3Provider, AccountProvider, MessengerProvider } from "_providers";
import { customerAuthenticationService } from "_services";

ReactDOM.render(
  <MessengerProvider>
  <AccountProvider authService={customerAuthenticationService}>
    <Web3Provider>
      <Router>
        <App />
      </Router>
    </Web3Provider>
  </AccountProvider>
  </MessengerProvider>,
  document.getElementById("root")
);
