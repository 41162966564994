import { BaseApiService, customerAuthenticationService} from '_services';
import { config } from '_config';
import { INft, ICollection, IArticle, INftEvent, INftSpot, ISpotOffer, ITag, IUserNftSpot } from '_interfaces';
import { NFTService } from '_services/common';
import { customerNftManagementService } from './CustomerNftManagementService';

class CustomerApiService<T> extends BaseApiService<T> {}

class CustomerArticleApiService<T> extends CustomerApiService<T> {
  async getByCollectionId(id: number) {
    const url = `${this.allUrl}?fCollectionUid=${id}`;
    try {
      const data: IArticle[] = await this.httpGet<IArticle[]>(url);
      return data;
    } catch (error: any) {
      console.error('error getting articles', {error});
      throw Error((error as Error).message);
    }
  }
}

class CustomerNftEventService<T> extends CustomerApiService<T> {
  async getByNftId(id: number) {
    const url = `${this.allUrl}?fNftUid=${id}`;
    try {
      const data: IArticle[] = await this.httpGet<IArticle[]>(url);
      return data;
    } catch (error: any) {
      console.error('error getting articles', {error});
      throw Error((error as Error).message);
    }
  }

  async getByEthAddress(addr: string) {
    const url = `${this.allUrl}?fActorEthAddress=${addr}`;
    try {
      const data: IArticle[] = await this.httpGet<IArticle[]>(url);
      return data;
    } catch (error: any) {
      console.error('error getting articles', {error});
      throw Error((error as Error).message);
    }
  }
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;

const customerNftApiService: CustomerApiService<INft> = new CustomerApiService<INft>(apiBaseUrl, '/nft', '/nfts', customerAuthenticationService);
const customerCollectionManagementApiService: CustomerApiService<ICollection> = new CustomerApiService<ICollection>(apiBaseUrl, '/management/collection', '/management/collections', customerAuthenticationService);
const customerCollectionApiService: CustomerApiService<ICollection> = new CustomerApiService<ICollection>(apiBaseUrl, '/collection', '/collections', customerAuthenticationService);
const customerArticleManagementApiService: CustomerApiService<IArticle> = new CustomerApiService<IArticle>(apiBaseUrl, '/management/article', '/management/articles', customerAuthenticationService);
const customerArticleApiService: CustomerArticleApiService<IArticle> = new CustomerArticleApiService<IArticle>(apiBaseUrl, '/article', '/articles', customerAuthenticationService);
const customerNftEventsApiService: CustomerNftEventService<INftEvent> = new CustomerNftEventService<INftEvent>(apiBaseUrl, '/nft-events', '/nft-events', customerAuthenticationService);
const customerUserNftSpotApiService: CustomerApiService<IUserNftSpot> = new CustomerApiService<IUserNftSpot>(apiBaseUrl, '/management/spot-badge', '/management/spot-badges', customerAuthenticationService)
const customerSpotOfferManagementService: CustomerApiService<ISpotOffer> = new CustomerApiService<ISpotOffer>(apiBaseUrl, '/management/spot-offer', '/management/spot-offers', customerAuthenticationService);
const customerTagManagementApiService: CustomerApiService<ITag> = new CustomerApiService<ITag>(apiBaseUrl, '/management/tag', '/management/tags', customerAuthenticationService);
const customerSpotManagementApiService: CustomerApiService<INftSpot> = new CustomerApiService<INftSpot>(apiBaseUrl, '/management/spot', '/management/spots', customerAuthenticationService);

const nftService = new NFTService(config.articleNftContractAddress, config.contractId, customerArticleManagementApiService, customerNftManagementService, customerSpotManagementApiService);



export { 
  CustomerApiService, 
  customerNftApiService, 
  customerCollectionApiService, 
  customerArticleApiService, 
  customerArticleManagementApiService, 
  customerCollectionManagementApiService,
  customerNftEventsApiService,
  nftService,
  customerUserNftSpotApiService,
  customerSpotOfferManagementService,
  customerTagManagementApiService,
  customerSpotManagementApiService
}