import React from "react";
import { BaseForm, FieldWithError } from "_common";
import { IDataField, IUser } from "_interfaces";
import { customerTagManagementApiService as apiService } from "_services";
import { Validator, validationMap } from "_components/_schemas";



interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IUser) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

const TagForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.TAG_FORM)}
      editableFields={editableFields}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <>
            {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            <div className="container">
              <div className="row">
                <FieldWithError errors={errors} touched={touched} fieldName="layoutUid" placeholder="Layout UID" label="Layout UID" />
              </div>

              <div className="row">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="articleUid"
                  placeholder="Article UID"
                  label="Article UID"
                />
              </div>

              <div className="row">
                <FieldWithError errors={errors} touched={touched} fieldName="spotUid" placeholder="Spot UID" label="Spot UID" />
              </div>

              <div className="row">
                <FieldWithError errors={errors} touched={touched} fieldName="name" placeholder="Name" label="Name" />
              </div>
            </div>
          </>
        );
      }}
    </BaseForm>
  );
};

export { TagForm  };
