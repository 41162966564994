import axios
  from "axios";
import { config } from "_config";
import { adminAuthenticationService, AuthenticationService } from "_services/common";

class AdminNftApiService  {

  constructor(
    protected apiBaseUrl:string, 
    protected authenticationService: AuthenticationService) {

    }

  async getNfts() {
    try {
      const url = `${this.apiBaseUrl}/nfts`;
      const response: any = await axios.post(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getTransaction(hash: string) {
    // GET /v1/admin/transaction/:hash - get transaction state    

    try {
      const url = `${this.apiBaseUrl}/transaction/${hash}`;
      const response: any = await axios.get(url, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error.message);
    }
  }

}

const adminNftApiService = new AdminNftApiService(`${config.apiDomain}${config.apiBasePath}/admin`, adminAuthenticationService);

export { adminNftApiService }