import { ArticleSlot } from "mdlib/interface/article-slot";
import React, { useContext, useEffect, useState } from "react";
import { ModalVoucherEditForm } from "_components/_publisher";
import { NftEventTypeEnum } from "_constants/NftEventTypeEnum";
import { IArticle, IArticleSlot, INft, INftEvent, ISpot } from "_interfaces";
import { MessengerContext } from "_providers";
import { customerArticleApiService, customerNftEventsApiService, nftService, publicNftSpotApiService } from "_services";
import { ItemActivity, BidItemDetail, ModalBid, ModalOffer } from "./common";

const NftBidPage: React.FunctionComponent = (props: any) => {
  const [nft, setNft] = useState<INft | undefined>(undefined); // the slot being bid on
  const [status, setStatus] = useState<string>("");
  const [article, setArticle] = useState<IArticle | undefined>(undefined);
  const [spot, setSpot] = useState<ISpot | undefined>(undefined);

  const [ showModalBid, setShowModalBid] = useState<boolean>(false);
  const [ showModalOffer, setShowModalOffer] = useState<boolean>(false);
  const [ showModalVoucherEditForm, setShowModalVoucherEditForm ] = useState<boolean>(false);

  const [ counter, setCounter] = useState<number>(0);
  const [ bidHistory, setBidHistory] = useState<INftEvent[]>([]);

  const messengerContext = useContext(MessengerContext);

  const nftUid: number = props.match.params.nftUid ? parseInt(props.match.params.nftUid) : 0;
  const articleUid = props.match.params.articleUid;
  const spotUid: number = props.match.params.spotUid;

  useEffect(() => {
    const _load = async () => {
      try {
        const article = await customerArticleApiService.getById(articleUid, false);
        if (!article.slots) {
          throw Error("No Slots. Invalid Article");
        }

        const spots = await publicNftSpotApiService.getByNftUid(nftUid);
        const slots = article.slots.filter((slot: ArticleSlot) => (slot as IArticleSlot).uid === nftUid);

        if (spots) {
          const filteredSpots = spots.filter((spot: ISpot) => spot.uid.toString() === spotUid.toString());
          if (filteredSpots.length > 0) {
            setSpot(filteredSpots[0])
          } else {
            setSpot(undefined);
            console.warn('spotUid not found');
          }
        }
        const nftEvents = await customerNftEventsApiService.getByNftId(nftUid);
        setBidHistory(nftEvents.filter((e: INftEvent) => e.typeId === NftEventTypeEnum.BID));

        console.log('article', {article, slots});
        setNft(slots[0]);
        setArticle(article);
      } catch (error: any) {
        console.error(error);
        setStatus(error.message);
      }
    };
console.log('in useEffect of NftBidPage');
    _load();
  }, [articleUid, nftUid, spotUid, counter]);

  const bid = async (nft: INft) => {
    setShowModalBid(true);
  };

  const offer = async (nft: INft) => {
    setShowModalOffer(true);
  }

  const refresh = () => {
    setCounter(counter + 1);
  }
  
  const buyNow = async (spot: ISpot) => {
    console.log('buyNow', {voucher: spot.voucher});
    try {
      await nftService.redeemSpot(spot, setStatus);
      refresh();
      messengerContext.setMessage({title: 'NFT Purchase', body: 'Purchase complete.'});
    } catch (error: any) {
      console.error('buyNow Error', {error});
      setStatus((error as Error).message);
    }
  }

  const edit = async (nft: INft) => {
    setShowModalVoucherEditForm(true);
  }

  const onSuccessfulOffer = (message: string | React.ReactNode) => {
    setShowModalOffer(false);
    messengerContext.setMessage({title: 'Offer', body: message});
    refresh();
  }

  const onSuccessfulBid = (message: string | React.ReactNode) => {
    setShowModalBid(false);
    messengerContext.setMessage({title: 'Bid', body: message});
    refresh();
  }

  const onSuccessfulVoucherEdit = (message: string) => {
    setShowModalVoucherEditForm(false);
    refresh();
    messengerContext.setMessage({title: 'Voucher Edit', body: message});
  }

  const onErrorVoucherEdit = (message: string) => {
    messengerContext.setMessage({title: 'Voucher Edit Error', body: message});
  }

  return (
    <>
      {nft && <ModalOffer handleClose={() => setShowModalOffer(false)} show={showModalOffer} nft={nft} spot={spot} onSuccess={onSuccessfulOffer}/>}

      {nft && <ModalBid handleClose={() => setShowModalBid(false)} show={showModalBid} nft={nft} onSuccess={onSuccessfulBid}/>}

      {nft && article && <ModalVoucherEditForm article={article} nft={nft} handleClose={()=>setShowModalVoucherEditForm(false)} show={showModalVoucherEditForm} onSuccess={onSuccessfulVoucherEdit} onError={onErrorVoucherEdit}/>}

      {article && nft && <BidItemDetail spot={spot} article={article} onEdit={edit} nft={nft} onBid={bid} onOffer={offer} onSuccessfulOffer={onSuccessfulOffer} onBuySpot={buyNow} bidHistory={bidHistory} refresh={()=>refresh()}/>}

      {nft && <ItemActivity nft={nft} counter={counter}/>}

      {status && <div className="alert alert-warning">{status}</div>}
    </>
  );
};

export { NftBidPage };
