import React, { useReducer } from 'react';
import { Helper } from '_common';
import { IAccount, ICredentials, ISignature, IAccountContext } from '_interfaces';
import { AccountContext } from '_providers';

import { AuthenticationService, customerAccountService } from '_services';

enum AccountAction {
  LOGIN = 1,
  LOGOUT = 2,
  UPDATE = 3,
  CHANGE_PASSWORD = 4,
  REGISTER = 5,
  REGISTER_WALLET = 6,
  ERROR = 9,
}

interface IAccountState {
  account: IAccount | null;
  error: any;
}

interface IAccountAction {
  type: AccountAction,
  payload?: any,
}

const accountReducer = (state: IAccountState, action: IAccountAction): any => {

  switch(action.type) {
    case AccountAction.LOGIN: {
      const result = {
        error: null,
        account: action.payload.account,
      };
      console.log('dispatched login with result ', result);
      return result;
    }

    case AccountAction.LOGOUT: {
      return {
        error: null,
        account: null,
      }
    }

    case AccountAction.ERROR: {
      return {
        account: state.account,
        error: action.payload.error,
      }
    }

    //@TODO:
    case AccountAction.UPDATE: {
      console.log('update dispatch', {
        error: null,
        account: action.payload.account,
      })
      return {
        error: null,
        account: action.payload.account,
      }
    }

    case AccountAction.REGISTER: {
      return {
        error: null,
        account: action.payload.account,
      }
    }

    case AccountAction.REGISTER_WALLET: {
      return {
        error: null,
        account: action.payload.account,
      }
    }

    default:
      throw new Error(`Invalid Action Type: ${action.type}`);
  }

}

const AccountProvider: React.FunctionComponent<{authService: AuthenticationService, children: any}> = ({authService, children}) => {

  const defaultAccountState: IAccountState = {
    account: authService.getLocalAccount() || null,
    error: null,
  }
  
  const [ accountState, dispatchAccountAction ] = useReducer( accountReducer, defaultAccountState);

  const loginHandler = async (credentials: ICredentials) => {
    try {
      await authService.login(credentials);
      const account: any = await authService.getLocalAccount();
      dispatchAccountAction({type: AccountAction.LOGIN, payload: {account}});
      return account;
    } catch (error: any) {
      console.error('in loginhandler error', error);
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const loginWithWalletHandler = async (signature: ISignature) => {
    try {
      if (!signature.ethAddress) {
        throw Error('missing signature');
      }

      const exists = await customerAccountService.checkEthAddress(signature.ethAddress);

      let account: IAccount | null;
      if (!exists) {
        // register eth address with placeholder
        const shortEthAddress = Helper.shortenEthAddress(signature.ethAddress);
        const email = `${shortEthAddress}@placeholder-domain.com`;
        const accountData: IAccount = {
          username: shortEthAddress.replace('...', '-'),
          email: email.replace('...', '-'),
          ethAddress: signature.ethAddress,
        }
        console.log({accountData});
        account = await customerAccountService.registerWallet(accountData, signature);
      } else {
        await authService.login(signature);
        account = await authService.getLocalAccount();
      }

      if (!account) {
        throw Error('Unexpected login failure.');
      }
      dispatchAccountAction({type: AccountAction.LOGIN, payload: {account}});
      return account as IAccount;
    } catch (error: any) {
      console.error('error logging in with wallet', error);
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const logoutHandler = (callback?: () => void ) => {
    authService.logout();
    dispatchAccountAction({type: AccountAction.LOGOUT, payload: {}});
    if (callback) {
      callback();
    }
  }

  const updateHandler = async (account: IAccount) => {
    try {
      const response = await customerAccountService.updateAccount(account);
      dispatchAccountAction({type: AccountAction.UPDATE, payload: {account: response}});
      return response as IAccount;
    } catch (error: any) {
      throw Error(error);
    }
  }

  const registerHandler = async (account: IAccount) => {
    try {
      const response = await customerAccountService.register(account);
      // @TODO: dispatch
      return response as IAccount;
    } catch (error: any) {
      throw Error(error);
    }
  }

  const registerWalletHandler = async (signature: ISignature) => {
    try {
      const shortEthAddress = Helper.shortenEthAddress(signature.ethAddress);
      const email = `${shortEthAddress}@placeholder-domain.com`;
      const accountData: IAccount = {
        username: shortEthAddress.replace('...', '-'),
        email: email.replace('...', '-'),
        ethAddress: signature.ethAddress,
        password: new Date().toUTCString()
      }
      console.log({accountData});
      const account = await customerAccountService.registerWallet(accountData, signature);      //@TODO: dispatch
      dispatchAccountAction({type: AccountAction.REGISTER_WALLET, payload: {account}});
      return account as IAccount;
    } catch (error: any) {
      throw Error(error);
    }
  }

  const changePasswordHandler = async () => {
  }

  const accountContext: IAccountContext = {
    account: accountState.account,
    login: loginHandler,
    logout: logoutHandler,
    update: updateHandler,
    register: registerHandler,
    registerWallet: registerWalletHandler,
    changePassword: changePasswordHandler,
    loginWithWallet: loginWithWalletHandler,
    error: accountState.error,
  }

  return (
    <AccountContext.Provider value={accountContext}>
      {children}
    </AccountContext.Provider>
  );

}


export { AccountProvider }
