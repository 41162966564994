import React from 'react';
import { IBadge } from '_interfaces';
import 'scss/badge/mintdesk.scss';

const BadgePreview: React.FunctionComponent<{badge: IBadge}> = ({badge}) => {
  return(
    <div className="mintdesk-card mintdesk-border mintdesk-border-shadow mintdesk-my-1">
    <div className="mintdesk-card-alias-image">
      <div className="mintdesk-card-alias-image-name">{badge.alias}</div>
      <div className="mintdesk-card-alias-image-image">
        {badge.imageUrl ? <img src={badge.imageUrl} alt={badge.alias} />
        :
        <img src="/images/blank-avatar-male.jpg" alt="badge placeholder"/>
        }
      </div>
    </div>
    <div className="mintdesk-card-description w-100">
      <div className="mintdesk-card-description-buy text-end">Buy</div>
      <div className="mintdesk-card-description-title">{badge.title}</div>
      <div className="mintdesk-card-description-subtitle">{badge.subtitle}</div>
      <div className="mintdesk-card-description-link">{badge.otherLink}</div>
    </div>
  </div>
  );
}

export { BadgePreview }