import React from 'react';
import { Modal } from 'react-bootstrap';
import { INft } from '_interfaces';
import { VoucherEditForm } from './VoucherEditForm';
import { IArticle } from "_interfaces";


const ModalVoucherEditForm: React.FunctionComponent<{ show: any, handleClose: any, nft: INft, article: IArticle, onSuccess: any, onError: any }> = ({ show, handleClose, nft, article, onSuccess, onError }) => {

  return(
    <Modal
    show={show}
    onHide={handleClose}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">Modify and Re-List NFT</Modal.Title>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
    </Modal.Header>
    <Modal.Body>
      <div className="row justify-content-center">
        <div className="col-12">
          <VoucherEditForm article={article} nft={nft} onSuccess={onSuccess} onError={onError}/>
        </div>
      </div>

    </Modal.Body>
  </Modal>

  );
}

export { ModalVoucherEditForm }