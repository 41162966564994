import { Route, Switch } from "react-router-dom";
import { PageNotFound, AdminLayout, HomePage, Login, ProfileSection } from "_components";
import LayoutFront from "neftify/components/layout/LayoutFront";
import { PrivateRoute, ScrollToTop } from "_common";
import { adminRoutes, customerRoutes } from "_config/routes";
import { IRoute } from "_interfaces";
import { Role, Url } from "_constants";
import { adminAuthenticationService } from "_services";

import "public/scss/style.scss";

import { AccountProvider } from "_providers";
import { useContext, useEffect } from "react";
import { Web3Context } from "_providers";
import web3 from "_connection/web3";
import { AccountContext } from "_providers";

const App = () => {

  const web3Context = useContext(Web3Context);
  const accountContext = useContext(AccountContext);

  if (web3) {
    web3.on("accountsChanged", async (accounts: Array<string>) => {
      console.log('triggered onAccountsChanged');
      if (accounts.length > 0) {
        console.log('account changing to something. calling loadAccount from accountsChanged. will reload.');
        accountContext.logout();  // logout previous user

        console.log('calling loadAccount from onAccountsChanged');
        await web3Context.loadAccount(web3);
        console.log('done calling loadAccount from accountsChanged')
        //console.log('about to reload window');
        //window.location.reload();
      } else {
        console.log('account changing to nothing');
        web3Context.disconnect();
        window.location.href = Url.DEFAULT_HOME;
      }
    });

  }

  useEffect(() => {

    const _init = async () => {

      if (web3) {
        try {
          console.log('calling App.useEffect loadAccount');
          await web3Context.loadAccount(web3);
        } catch (error) {
          console.error(error);
        }
      }
    }
    console.log('app useeffect called');
    _init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <Switch>
      <PrivateRoute
        path="/account-section"
        component={ProfileSection}
        roles={[Role.PUBLISHER, Role.CUSTOMER]}
        redirectUrl={Url.CUSTOMER_REGISTRATION}
      />

      <Route path={customerRoutes.map((r: IRoute) => r.path)}>
        <ScrollToTop>
          <LayoutFront>
            <Switch>
              {customerRoutes.map((r: IRoute, i: number) => (
                <PrivateRoute
                  key={`cust-${i}`}
                  exact
                  path={r.path}
                  component={r.component}
                  roles={r.roles || []}
                  redirectUrl={Url.CUSTOMER_REGISTRATION}
                />
              ))}
            </Switch>
          </LayoutFront>
        </ScrollToTop>
      </Route>

      <Route path={adminRoutes.map((r: IRoute) => r.path)}>
        <AccountProvider authService={adminAuthenticationService}>
          <AdminLayout>
            <Switch>
              {adminRoutes.map((r: IRoute, i: number) => (
                <PrivateRoute
                  key={`admin-${i}`}
                  exact
                  path={r.path}
                  component={r.component}
                  roles={r.roles || []}
                  redirectUrl={Url.ADMIN_LOGIN}
                />
              ))}
              <PageNotFound />
            </Switch>
          </AdminLayout>
        </AccountProvider>
      </Route>

      <Route path="/" exact>
        <LayoutFront>
          <Route exact path="/" component={HomePage} />
        </LayoutFront>
      </Route>

      <LayoutFront>
        <Switch>
          <Route exact path="/login" component={Login} />
          <PageNotFound />
        </Switch>
      </LayoutFront>
    </Switch>
  );
};

export default App;
