import React from "react";
import { BaseForm, FieldWithError, FileUpload } from "_common";
import { ICollection, IDataField } from "_interfaces";
import { customerCollectionManagementApiService as apiService, customerStorageService } from "_services";
import { Validator, validationMap } from "_components/_schemas";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: ICollection) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

const CollectionForm: React.FunctionComponent<IAppProps> = ({
  isEditing,
  onSuccess,
  initialValues,
  onCancelClick,
  editableFields
}: IAppProps) => {

  const beforeSubmit = async (data: any, actions: any) => {
    try {
      if (data.image) {
        data.image = await customerStorageService.uploadImage(data.image);
      } else {
        delete data['image'];
      }
    } catch (error: any) {
      throw Error(error.message);      
    }
  };

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.COLLECTION_FORM)}
      beforeSubmit={beforeSubmit}
      editableFields={editableFields}
    >
      {({ errors, touched, setFieldValue, values }) => {
        return (
          <>
            {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            <div className="container-md my-2">
              <FieldWithError
                errors={errors}
                touched={touched}
                fieldName="name"
                placeholder="Collection Name"
                label="Collection Name"
              />

              <FieldWithError
                errors={errors}
                touched={touched}
                fieldName="description"
                placeholder="Collection Description"
                label="Collection Description"
              />

              <FileUpload
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                name="image-upload"
                targetField="image"
                touched={touched}
                title="Collection Cover Image"
              />
            </div>
          </>
        );
      }}
    </BaseForm>
  );
};

export { CollectionForm };
