/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { AccountContext } from '_providers';

const ProfileCard: React.FunctionComponent<{onEdit: any}> = ({onEdit}) => {

  const accountContext = useContext(AccountContext);

  return (
    <>
      <div className="card-header px-0">
        <h4 className="card-title">Information </h4>
        <a className="btn btn-primary" onClick={onEdit}>
          Edit
        </a>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="user-info">
                <span>USER ID</span>
                <h4>{accountContext.account.username}</h4>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="user-info">
                <span>EMAIL ADDRESS</span>
                <h4>{accountContext.account.email}</h4>
              </div>
            </div>
            {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="user-info">
                <span>COUNTRY OF RESIDENCE</span>
                <h4>{accountContext.account.country}</h4>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="user-info">
                <span>JOINED SINCE</span>
                <h4>20/10/2020</h4>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="user-info">
                <span>TYPE</span>
                <h4>Personal</h4>
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
};

export { ProfileCard }