import React, { useState } from "react";
import { Loading } from "_common/utils";
import { FormButtons  } from "_constants";

interface ITableProps<T> {
  data: T[];
  onAddClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onEditClick?: (item: T, e: React.MouseEvent<HTMLElement>) => void;
  onDeleteClick?: (item: T, e:React.MouseEvent<HTMLElement>) => void;
  onSelectClick: (ids: string[], e:React.MouseEvent<HTMLElement>) => void;
  hasMultiSelect?: boolean;
  multiSelectLabel?: string;
  readOnly?: boolean;
  buttons?: number[];
  fieldList?: Map<string, [string, Function | undefined]>;
  loading?: boolean;
}

const BaseTable = <T extends { id?: number }>({
    data,
    onAddClick,
    onEditClick,
    onDeleteClick,
    onSelectClick,
    hasMultiSelect,
    multiSelectLabel,
    readOnly,
    buttons,
    fieldList,
    loading
  }: ITableProps<T>) => {
  const [selected, setSelected] = useState<string[]>([]);

  if (data.length === 0) {
    return (
      <>
        {loading && <Loading /> }

        <h5>No Data </h5>

        {buttons && buttons.indexOf(FormButtons.ADD) >= 0 && (
          <button type="button" className="btn btn-primary" onClick={onAddClick}>
            Add New
          </button>
        )}
      </>
    );
  }

  const onSelect = (e: React.MouseEvent<HTMLElement>) => {
    const currentTarget = e.currentTarget as HTMLInputElement;
    if (currentTarget.checked) {
      selected.push(currentTarget.value);
      setSelected(selected);
    } else {
      setSelected(selected.filter((i: string) => i !== currentTarget.value));
    }
    e.stopPropagation();
  };

  const onRowClick = (item: T, e: React.MouseEvent<HTMLElement>) => {
    // add code to handle clicks on the row.

    // alert('row clicked');
    // console.log({item});
  }
  return (
    <>

    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th
            align="right"
            colSpan={
              Object.keys(data[0]).length +
              (buttons && buttons.indexOf(FormButtons.EDIT) >= 0 ? 1 : 0) +
              (buttons && buttons.indexOf(FormButtons.DELETE) >= 0 ? 1 : 0)
            }
          >
            {buttons && buttons.indexOf(FormButtons.ADD) >= 0 && onAddClick && (
              <button type="button" className="btn btn-primary" onClick={onAddClick}>
                Add New
              </button>
            )}
          </th>
        </tr>

        {/* column headers */}
        <tr>
          {hasMultiSelect && <th>Select</th>}

          {fieldList ? (
            <>
              {Array.from(fieldList.entries()).map((item: [string, [string, Function | undefined]], i: number) => {
                const [fieldName, [fieldTitle,  ]] = item;
                return <th key={`${fieldName}-${i}`}>{fieldTitle}</th>;
              })}
            </>
          ) : (
            <>
              {Object.keys(data[0]).map((key) => {
                return <th key={key}>{key}</th>;
              })}
            </>
          )}

          {buttons && buttons.indexOf(FormButtons.EDIT) >= 0 && <th>Edit</th>}
          {buttons && buttons.indexOf(FormButtons.DELETE) >= 0 && <th>Delete</th>}
        </tr>
      </thead>

      <tbody>
        {data.map((item: T, index: number) => (
          <tr key={index} onClick={(e: React.MouseEvent<HTMLElement>) => onRowClick(item, e)} >
            {hasMultiSelect && (
              <td>
                <input type="checkbox" name="selected" value={item.id?.toString()} onClick={onSelect} />
              </td>
            )}

            {fieldList ? (
              <>
                {Array.from(fieldList.entries()).map((field: [string, [string, Function | undefined]], i: number) => {
                  const [fieldName, [, fn]] = field;
                  if (typeof fn === 'function') {
                    return <td key={`${fieldName}-${i}`}>{fn(item)}</td>;
                  }
                  return <td key={`${fieldName}-${i}`}>{Object(item)[fieldName]}</td>;
                })}
              </>
            ) : (
              <>
                {Object.keys(data[0]).map((key) => {
                  return <td key={key.toString()}>{Object(item)[key]}</td>;
                })}
              </>
            )}

            {buttons && buttons.indexOf(FormButtons.EDIT) >= 0 && onEditClick && (
              <td>
                <button
                  className="btn btn-secondary"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    onEditClick(data[index], e);
                  }}
                >
                  Edit
                </button>
              </td>
            )}
            {buttons && buttons.indexOf(FormButtons.DELETE) >= 0 && onDeleteClick && (
              <td>
                <button
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    onDeleteClick(data[index], e);
                  }}
                  className="btn btn-secondary"
                >
                  Delete
                </button>
              </td>
            )}
          </tr>
        ))}
        <tr></tr>

      </tbody>
    </table>

    {hasMultiSelect && (
      <button
      className="btn btn-primary"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        onSelectClick(selected, e);
      }}
    >
      {multiSelectLabel}
    </button>
    )}
    </>

);
};

export { BaseTable };
