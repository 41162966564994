import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions container">
      <h1 className="text-center">Agreement Between User and MintDesk.com</h1>

      <p>Terms Placeholder...</p>
    </div>
  );
};

export { TermsAndConditions };
