import React, { useContext } from "react";
import { FieldWithError, FileUpload } from "_common";
import { AccountContext } from "_providers";

const PersonalInfo: React.FunctionComponent<{
  errors: any;
  touched: any;
  readOnly?: boolean;
  values: any;
  setFieldValue: any;
}> = ({ errors, touched, readOnly, values, setFieldValue }) => {


  const accountContext = useContext(AccountContext);

  return (
    <>{accountContext.account ? 
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="firstName"
            placeholder="Enter First Name"
            label="First Name"
            readOnly={readOnly}
          />
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="lastName"
            placeholder="Enter Last Name"
            label="Last Name"
            readOnly={readOnly}
          />
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="email"
            placeholder="your-email@domain.com"
            label="Enter Your E-Mail"
            readOnly={readOnly}
          />
        </div>


        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <div className="d-flex align-items-center mb-3">
            <img className="me-3 rounded-circle me-0 me-sm-3" src={accountContext.account.avatarUrl} width="55" height="55" alt="" />
            <div className="media-body">
              <h4 className="mb-0">{accountContext.account.firstName} {accountContext.account.lastName}</h4>
              <p className="mb-0">Max file size is 20mb</p>
            </div>
          </div>
        </div>

        <div className="col-12">
          <FileUpload
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            name="image-upload"
            targetField="avatar"
            touched={touched}
            title="Avatar"
          />
        </div>
      </div>
      :
      <div>Timed out.</div>
    }
    </>
  );
};

export { PersonalInfo };
