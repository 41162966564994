import React from 'react';
import ProfileMenu from 'neftify/components/layout/ProfileMenu';

const Created = () => {
  return(
    <div className="profile-page">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ProfileMenu />
        </div>
      </div>
    </div>
    Created:
  </div>
  );
}

export { Created }