import {providers, utils, Wallet} from "ethers";
import {CommonUtil} from "../util/common.util";
import {Voucher} from "../interface/voucher";
import {ArticleSlot} from "../interface/article-slot";
import {VoucherValueMint, VoucherValueSell} from "../interface/voucher-value";
import {CommonValidator} from "../validator/common.validator";

export class ArticleLazyNFT {
    /**
     *
     * @param voucher address
     */
    static verifyVoucher(voucher: Voucher) {
        const value = voucher.value;
        let address = utils.verifyTypedData(voucher.domain, voucher.types, value, voucher.signature);
        if (!address) {
            return false;
        }
        address = address.toLowerCase();
        return address === value.owner ? address : false;
    }

    static async createVoucher(
            signer: providers.JsonRpcSigner|Wallet,
            uriArticle: string,
            feePct: number,
            contractAddress: string,
            slots: ArticleSlot[]
    ) {
        //preprocess voucher, might better to create builder
        for (const s of slots) {
            s.priceFixed = CommonValidator.toBigNumber(s.priceFixed);
            s.priceStart = CommonValidator.toBigNumber(s.priceStart);
            s.priceReserve = CommonValidator.toBigNumber(s.priceReserve);
        }

        const value: VoucherValueMint = {
            owner:  (await signer.getAddress()).toLowerCase(),
            uri: uriArticle,
            fee: CommonUtil.pctToFeeContract(feePct),
            slots:slots
        };
        //const value = CommonValidator.validate(CommonValidator.voucherMintValue(), valueRaw);

        const domainData = {
            name: "MintDesk",
            version: "1",
            chainId: await signer.getChainId(),
            verifyingContract: contractAddress.toLowerCase(),
        };
        const types = {
            Voucher: [
                {name: 'owner', type: 'address'},
                {name: 'uri', type: 'string'},
                {name: 'fee', type: 'uint16'},
                {name: 'slots', type:'ArticleSlot[]'},
            ],
            ArticleSlot: [
                {name: 'pos', type: 'uint16'},
                {name: 'amount', type: 'uint16'},
                {name: 'uri', type: 'string'},
                {name: 'priceType', type: 'uint8'},
                {name: 'priceFixed', type: 'uint256'},
                {name: 'protectionEnd', type: 'uint256'},
                {name: 'priceStart', type: 'uint256'},
                {name: 'priceReserve', type: 'uint256'},
                {name: 'auctionStart', type: 'uint256'},
                {name: 'auctionEnd', type: 'uint256'},
            ],
        };

        const voucher = {
            domain: domainData,
            types: types,
            value: value,
            signature:  await signer._signTypedData(domainData, types, value)
        } as Voucher;
        // validate prepared voucher
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        CommonValidator.validate(CommonValidator.voucherMint(), voucher).value;
        return voucher;
    }

    static async createVoucherNftSpot(
            signer: providers.JsonRpcSigner,
            contractAddress: string,
            //buyer: string,
            tokenId: number,
            spotUids: number[],
            price: number,
            feePct: number,
            expireTimestamp: number,
    ) {
        const message = {
            owner:  (await signer.getAddress()).toLowerCase(),
            //buyer: buyer.toLowerCase(),
            tokenId: tokenId,
            spots: spotUids,
            price: utils.parseEther(price.toString()),
            fee: CommonUtil.pctToFeeContract(feePct),
            expireTimestamp: expireTimestamp,
        } as VoucherValueSell;

        const domainData = {
            name: "MintDesk",
            version: "1",
            chainId: await signer.getChainId(),
            verifyingContract: contractAddress.toLowerCase(),
        };
        const types = {
            VoucherNftSpot: [
                {name: 'owner', type: 'address'},
                //{name: 'buyer', type: 'address'},
                {name: 'tokenId', type: 'uint256'},
                {name: 'spots', type: 'uint256[]'},
                {name: 'price', type: 'uint256'},
                {name: 'fee', type: 'uint16'},
                {name: 'expireTimestamp', type:'uint256'},
            ],
        };

        const voucher = {
            domain: domainData as any,
            types: types as any,
            value: message,
            signature:  await signer._signTypedData(domainData, types, message)
        } as Voucher;
        //throws error
        CommonValidator.validate(CommonValidator.voucherSell(), voucher);
        return voucher;
    }
}
