import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Helper } from "_common";
import { SpotOfferActionEnum, SpotOfferStatusEnum, SpotOfferStatusNames } from "_constants";
import { IArticle, INft, ISpotOffer } from "_interfaces";
import { MessengerContext, Web3Context } from "_providers";
import { customerArticleApiService, customerSpotOfferManagementService, nftService } from "_services";

const NftCard: React.FunctionComponent<{ offer: ISpotOffer }> = ({ offer }) => {
  const [nft, setNft] = useState<INft>({} as INft);
  const [article, setArticle] = useState<IArticle>({} as IArticle);

  useEffect(() => {
    const _load = async () => {
      try {
        const article: IArticle = await customerArticleApiService.getById(offer.articleUid || 0);
        const slots: INft[] | undefined = article.slots?.filter((slot: INft) => slot.uid === offer.nftUid);
        setArticle(article);
        if (slots && slots[0]) {
          setNft(slots[0]);
        }
      } catch (error: any) {
        setNft(nft);
        console.log();
      }
    };
    _load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card items" style={{ maxWidth: "300px" }}>
      <div className="card-body">
        <div className="items-img position-relative">
          <img src={article?.imageUrl} className="img-fluid rounded mb-3" alt="" />
          <img src={article.creatorAvatarUrl} className="creator" width="50" alt="" />
        </div>
        <h4 className="card-title">{article?.title}</h4>
        <h5 className="">{article.description}</h5>
        <div className="d-flex justify-content-center">
          <h5 className="flex-fill">Slot: {(nft.position || 0) + 1}</h5>
          {/* <h5 className="flex-fill">Price Paid: {nft.purchasePrice} ETH</h5> */}
        </div>

        <div className="">URL: {article?.url}</div>
      </div>
    </div>
  );
};

const OfferDetails = ({ offer }: { offer: ISpotOffer }) => {
  return (
    <div className="container">
      <div>Bidder Address: {Helper.shortenEthAddress(offer.bidderEthAddress)}</div>
      <div>Owner Address: {Helper.shortenEthAddress(offer.ownerEthAddress)}</div>
      <div>Token ID:{offer.nftTokenId}</div>
      <div>Price: {offer.price} ETH</div>
      <div>Status: {SpotOfferStatusNames.get(offer.statusId)}</div>
    </div>
  );
};

const Offered: React.FunctionComponent<{refreshNotificationCounter?: ()=>void}> = ({refreshNotificationCounter}) => {
  const [offersFromBuyers, setOffersFromBuyers] = useState<ISpotOffer[]>([]);
  const [offersToOwners, setOffersToOwners] = useState<ISpotOffer[]>([]);
  const [, setStatus] = useState<string>("");
  const [counter, setCounter] = useState<number>(0);

  const messengerContext = useContext(MessengerContext);
  const web3Context = useContext(Web3Context);

  const refresh = () => {
    setCounter(counter + 1);
    if (refreshNotificationCounter) refreshNotificationCounter();
  }
  const accept = async (offer: ISpotOffer) => {
    try {
      await nftService.finishSpotOffer(offer, SpotOfferActionEnum.ACCEPT, setStatus);
      messengerContext.setMessage({ title: "Accept Offer", body: "Offer successfully closed. Item was transferred to buyer" });
      refresh();
    } catch (error: any) {
      messengerContext.setMessage({ title: "error", body: error.message });
    }
  };

  const reject = async (offer: ISpotOffer) => {
    try {
      await nftService.finishSpotOffer(offer, SpotOfferActionEnum.REJECT, setStatus);
      messengerContext.setMessage({ title: "Reject Offer", body: "Offer was rejected." });
      refresh();
    } catch (error: any) {
      messengerContext.setMessage({ title: "error", body: error.message });
    }
  };

  const cancel = async (offer: ISpotOffer) => {
    try {
      await nftService.finishSpotOffer(offer, SpotOfferActionEnum.CANCEL, setStatus);
      messengerContext.setMessage({ title: "Cancel Offer", body: "Offer was canceled." });
      refresh();
    } catch (error: any) {
      messengerContext.setMessage({ title: "error", body: error.message });
    }
  };

  useEffect(() => {
    const _load = async () => {
      try {
        const data: ISpotOffer[] = await customerSpotOfferManagementService.getAll();
        const offersFromBuyers = data.filter(
          (item: ISpotOffer) => item.ownerEthAddress && item.ownerEthAddress.toLowerCase() === web3Context.account?.toLowerCase()
        );
        const offersToOwners = data.filter(
          (item: ISpotOffer) => item.ownerEthAddress && item.ownerEthAddress.toLowerCase() !== web3Context.account?.toLowerCase()
        );

        setOffersFromBuyers(offersFromBuyers);
        setOffersToOwners(offersToOwners);
      } catch (error: any) {
        console.error({ error });
        //messengerContext.setMessage({title: 'Error', body: error.message});
      }
    };

    _load();
  }, [web3Context.account, counter]);



  return (
      <Tabs defaultActiveKey="toMe" id="offers">
        <Tab eventKey="toMe" title="Offers Made To Me">
          <div className="mt-2">
            {offersFromBuyers.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>NFT</th>
                    <th>Details</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {offersFromBuyers.filter((o:ISpotOffer) => o.statusId === SpotOfferStatusEnum.ACTIVE).sort((a, b) => { return a.statusId < b.statusId ? -1 : 1 }).map((offer: ISpotOffer, i: number) => (
                    <tr key={`__key_from_${i}`}>
                      <td>
                        <NftCard offer={offer} />
                      </td>
                      <td>
                        <OfferDetails offer={offer} />
                      </td>
                      <td>
                        {offer.statusId === SpotOfferStatusEnum.ACTIVE && (
                          <>
                            <td>
                              <button className="btn btn-primary" onClick={() => accept(offer)}>
                                Accept
                              </button>
                            </td>
                            <td>
                              <button className="btn btn-secondary" onClick={() => reject(offer)}>
                                Reject
                              </button>
                            </td>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>No Offers</>
            )}
          </div>
        </Tab>
        <Tab eventKey="toOthers" title="Offers Made To Others">
          <div className="mt-2">
            {offersToOwners.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>NFT</th>
                    <th>Details</th>
                    <th>Action</th>
                    {/* <th>Bidder Eth Address</th>
              <th>Owner Eth Address</th>
              <th>Token ID</th>
              <th>Price</th>
              <th>Status ID</th>
              <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {offersToOwners.filter((o:ISpotOffer) => o.statusId === SpotOfferStatusEnum.ACTIVE || SpotOfferActionEnum.REJECT).sort((a, b) => { return a.statusId < b.statusId ? -1 : 1 }).map((offer: ISpotOffer, i: number) => (
                    <tr key={`__key_to_${i}`}>
                      <td>
                        <NftCard offer={offer} />
                      </td>
                      <td>
                        <OfferDetails offer={offer} />
                      </td>
                      <td>
                        {offer.statusId === SpotOfferStatusEnum.ACTIVE ? (
                          <button className="btn btn-primary" onClick={() => cancel(offer)}>
                            Cancel
                          </button>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>No Offers</>
            )}{" "}
          </div>
        </Tab>
      </Tabs>
  );
};

export { Offered };
