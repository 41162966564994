import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { IArticle, INft } from "_interfaces";
import { nftService } from "_services";
import { FieldWithError, PriceTypeField } from "_common";
import { validationMap, Validator } from "_components/_schemas";
import { NftPriceTypeEnum } from "_constants";
import { AccountContext } from "_providers";

const VoucherEditForm: React.FunctionComponent<{ article: IArticle; nft: INft; onSuccess: any; onError: any }> = ({
  article,
  nft,
  onSuccess,
  onError,
}) => {
  interface IFormFields {
    url: string | undefined; // article URL
    uri: string; // JSON URI
    pos: number;
    amount: number;
    priceType: number;
    priceFixed: number;
    priceStart: number;
    priceReserve: number;
    auctionStart: number;
    auctionEnd: number;
  }

  const voucherSlots = (nft as any).voucher.value.slots.filter((slot: any) => slot.pos === (nft as any).position);
  const { auctionStart, auctionEnd } = nft;
  const initialValues: IFormFields = {
    url: article.url,
    uri: (voucherSlots[0] as any).uri,
    pos: (nft as any).position,
    amount: (voucherSlots[0] as any).amount,
    priceType: (nft as any).priceTypeId,
    priceFixed: (nft as any).priceFixed || 0,
    priceStart: (nft as any).priceStart,
    priceReserve: (nft as any).priceReserve,
    auctionStart: new Date(`${auctionStart} UTC`).getTime() / 1000,
    auctionEnd: new Date(`${auctionEnd} UTC`).getTime() / 1000,
  };

  const validationSchema = validationMap.get(Validator.NFT_EDIT_FORM);
  const accountContext = useContext(AccountContext);

  const onSubmit = async (data: IFormFields, actions: any) => {
    if (!nft.uid) {
      throw Error("Unexpected error. Could not find NFT ID");
    }

    // clean up the data
    const { uri, pos, amount, priceType, priceFixed, priceStart, priceReserve, auctionStart, auctionEnd } = data;
    const postData = {
      url: data.url,
      slots: [
        {
          uri,
          pos,
          amount,
          priceType,
          priceFixed: priceType === NftPriceTypeEnum.AUCTION ? 0 : priceFixed,
          priceStart: priceType === NftPriceTypeEnum.FIXED ? 0 : priceStart,
          priceReserve: priceType === NftPriceTypeEnum.FIXED ? 0 : priceReserve,
          auctionStart: priceType === NftPriceTypeEnum.FIXED ? 0 : auctionStart,
          auctionEnd: priceType === NftPriceTypeEnum.FIXED ? 0 : auctionEnd,
        },
      ],
    };

    console.log("data submitted", { postData });
    const { setStatus } = actions;
    try {
      if (accountContext.account.feePctMd) {
        const result = await nftService.updateVoucher(postData, setStatus, nft.uid, accountContext.account.feePctMd);
        console.log({ result });
        onSuccess("Successfully updated");
      } else {
        throw Error("Account timed out. Try logging out and logging back in.");
      }
    } catch (error: any) {
      onError(error.message);
    }
  };

  return (
    <>
      <h5>Edit Voucher</h5>
      {/* <pre>initialValues: {JSON.stringify(initialValues, null, 2)}</pre>
      <pre>nft: {JSON.stringify(nft, null, 2)}</pre> */}
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ values, errors, touched, isSubmitting, setStatus }) => (
          <Form>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div>Price Type</div>
                    <PriceTypeField errors={errors} touched={touched} name="priceType" />
                  </div>
                </div>

                <div className="col-md-6">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="amount"
                    placeholder="Shares/Spots"
                    label="Shares/Spots"
                  />
                </div>

                {values.priceType.toString() === NftPriceTypeEnum.FIXED.toString() && (
                  <>
                    <div className="col-md-6">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="priceFixed"
                        placeholder="Fixed Price"
                        label="Fixed Price"
                      />
                    </div>
                  </>
                )}

                {values.priceType.toString() === NftPriceTypeEnum.AUCTION.toString() && (
                  <>
                    <div className="col-md-6">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="priceStart"
                        placeholder="Starting Price"
                        label="Starting Price"
                      />
                    </div>
                    <div className="col-md-6">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="priceReserve"
                        placeholder="Reserve Price"
                        label="Reserve Price"
                      />
                    </div>
                    <div className="col-md-6">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="auctionStart"
                        placeholder="Auction Start"
                        label="Auction Start"
                      />
                    </div>
                    <div className="col-md-6">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="auctionEnd"
                        placeholder="Auction End"
                        label="Auction End"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row mt-2">
                <div className="col">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export { VoucherEditForm };
