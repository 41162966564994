import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { IAccount } from "_interfaces";
import { validationMap, Validator } from "_components/_schemas";
import { AddressInfo, PersonalInfo } from "../_subforms";

import { AccountContext } from "_providers";
import { IAccountContext } from "_interfaces";

import { customerStorageService } from "_services";

const ProfileForm: React.FunctionComponent<{onCancel: any}> = ({onCancel}) => {
  const accountContext: IAccountContext | null = useContext(AccountContext);

  const initialFormState: any = {
    firstName: accountContext?.account?.firstName,
    lastName: accountContext?.account?.lastName,
    email: accountContext?.account?.email,
    password: "",
    confirmPassword: "",
    address1: accountContext?.account?.address1,
    address2: accountContext?.account?.address2,
    city: accountContext?.account?.city,
    state: accountContext?.account?.state,
    zipcode: accountContext?.account?.zipcode,
    phoneNumber: accountContext?.account?.phoneNumber,
    dateOfBirth: accountContext?.account?.dateOfBirth,
    avatar: accountContext?.account?.avatar,
  };

  console.log({ initialFormState });

  const validationSchema = validationMap.get(Validator.PROFILE_FORM);

  const onSubmit = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;

    if (data.avatar) {
      try {
        data.avatar = await customerStorageService.uploadImage(data.avatar);
      } catch (error: any) {
        setSubmitting(false);
        setStatus("Error uploading file");
        console.error({ error });
        throw Error(error.message);
      }
    }

    if (data.password === "") {
      delete data.password;
      delete data.confirmPassword;
    }

    try {
      setSubmitting(true);
      await accountContext.update(data as IAccount);
      onCancel();
      //setStatus("Account Updated");
    } catch (err: any) {
      setStatus(`Error: ${err}`);
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ status, isSubmitting, errors, touched, setFieldValue, values }) => {
        return (
          <Form autoComplete="off">
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

            <div className="row">
              <div className="col-xxl-12">
                <h4 className="card-title mb-3">User Profile</h4>
                <div className="card">
                  <div className="card-body">
                    <PersonalInfo errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <h4 className="card-title mb-3">Contact Info</h4>
                <div className="card">
                  <div className="card-body">
                    <AddressInfo errors={errors} touched={touched} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">{status && <div className="alert alert-primary">{status}</div>}</div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-md">
                  Save Changes
                </button>

                <button type="button" className="btn btn-secondary btn-md mx-5" onClick={onCancel}>
                  Cancel
                </button>

              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { ProfileForm };
