import React from "react";
import { MyNfts } from "_components/_customer/Nft";

const Collected = () => {
  return (
    <MyNfts />
  );
};

export { Collected };
