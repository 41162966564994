import React, { useEffect, useState } from "react";
import { customerArticleApiService } from "_services";
import { IArticleNft, INft } from "_interfaces";
import { NftPriceTypeEnum } from "_constants";
import { useHistory } from "react-router-dom";
import { Helper } from "_common";


const CustomerNftCard: React.FunctionComponent<{ article: IArticleNft; setStatus: any }> = ({ article, setStatus }) => {
  return (
    <div className="card">
      <div className="card-body">
        <>
          <h5 className="card-title">{article.title}</h5>
          <img src={article.imageUrl} alt={article.title} />
          <p className="card-text">{article.description}</p>
          <div>Url: {article.url}</div>
          <div>
            <ul>Slots</ul>
            {article.uid && <Slots article={article} setStatus={setStatus} />}
          </div>
        </>
      </div>
    </div>
  );
};

const Slots: React.FunctionComponent<{ article: IArticleNft; setStatus: any }> = ({ article, setStatus }) => {

  const slots = (article as any).slots;
  const history = useHistory();

  const bid = async (nft: INft) => {
    history.push(`/nft/bid/${article.uid}/${nft.uid}`);
  };

  const buy = async (nft: INft) => {
    history.push(`/nft/buy/${article.uid}/${nft.uid}`);
  };


  const renderSlotAuctions = (slots: any) => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Position</th>
            <th>Shares/Spots</th>
            <th>Price Start</th>
            <th>Price Reserve</th>
            <th>Auction Start</th>
            <th>Auction End</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {slots.map((slot: any, i: number) => (
            <tr>
              <td>{slot.position}</td>
              <td>{slot.amount}/{slot.amountMinted}</td>
              <td>{slot.priceStart} ETH</td>
              <td>{slot.priceReserve} ETH</td>
              <td>{slot.auctionStart}</td>
              <td>{slot.auctionEnd}</td>
              <td><button type="button" className="btn btn-primary" onClick={() => bid(slot)}>Bid</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderSlotFixed = (slots: any) => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Position</th>
            <th>Available/Total Shares</th>
            <th>Price</th>
            <th>Action</th>
         </tr>
        </thead>
        <tbody>
          {slots.map((slot: any, i: number) => (
            <tr key={`__key_${i}`}>
              <td>{slot.position}</td>
              <td>{Helper.remainingShares({amount: slot.amount, amountMinted: slot.amountMinted})} / {slot.amount}</td>
              <td>{Helper.formatEth(slot.priceFixed)}</td>
              <td><button type="button" className="btn btn-primary" onClick={() => buy(slot)}>Buy</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }


  return (
    <div className="container">
      {/* <pre>{JSON.stringify(slots, null, 2)}</pre> */}

      {slots && slots.length > 0 && (slots[0] as any).priceTypeId === NftPriceTypeEnum.AUCTION && renderSlotAuctions(slots)}

      {slots && slots.length > 0 && (slots[0] as any).priceTypeId === NftPriceTypeEnum.FIXED && renderSlotFixed(slots)}


    </div>
  );
};

const CustomerArticles: React.FunctionComponent = () => {
  const [articles, setArticles] = useState<IArticleNft[]>([]);
  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    const _load = async () => {
      try {
        const articles = await customerArticleApiService.getAll();
        setArticles(articles);
        setStatus("");
      } catch (error: any) {
        alert(error);
        setStatus(error.message);
      }
    };
    _load();
  }, []);

  return (
    <div className="container">
      Articles
      {articles &&
        articles.length > 0 &&
        articles.map((article: IArticleNft, i: number) => (
          <div key={`article_${i}`}>
            {/* <pre>{JSON.stringify(article, null, 2)}</pre> */}
            <CustomerNftCard article={article} setStatus={setStatus} />
          </div>
        ))}
      {status && <div className="alert alert-warning">{status}</div>}
    </div>
  );
};

export { CustomerArticles };
