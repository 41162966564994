import React, { useEffect, useState } from 'react';
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import { publicCollectionApiService } from '_services'
import { ICollection } from '_interfaces';
import { CollectionStatusEnum } from '_constants';

SwiperCore.use([Autoplay, Navigation]);

const IntroSlider = () => {

    const [ data, setData ] = useState<ICollection[]>([]);
    //const [ status, setStatus ] = useState<string>('');

    useEffect(()=>{
        const _load = async () => {
            try {
                const data = await publicCollectionApiService.getAll();
                setData(data.filter((i: ICollection) => i.statusId === CollectionStatusEnum.ACTIVE));
            } catch (error: any) {
                console.error('error getting collection', {error});
                //setStatus((error as Error).message);
            }
        }

        _load();
    }, [])

    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false
                }}
                navigation={{
                    prevEl: ".intro_prev",
                    nextEl: ".intro_next",
                }}
                className="custom-class"
            >
                {data.map((item, i) => (
                    <SwiperSlide key={`__key_slider_${i}`}>
                        <div className="slider-item">
                            <img
                                src={item.imageUrl}
                                alt=""
                                className="img-fluid"
                            />
                            <div className="slider-item-avatar">
                                {/* <img
                                    src={`/images/avatar/${item.avatar}`}
                                    alt=""
                                /> */}
                                <div>
                                    <h5>{item.name}</h5>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}

                <div className="arrows">
                    <span className="intro_prev">
                        <i className="bi bi-arrow-left"></i>
                    </span>
                    <span className="intro_next">
                        <i className="bi bi-arrow-right"></i>
                    </span>
                </div>
            </Swiper>


        </>
    );
};

export default IntroSlider;

