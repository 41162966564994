enum ResaleTypeEnum {
  OFFER = 1,
  BUY_NOW = 2
}

const ResaleTypeNames: Map<number, string> = new Map<number, string>([
  [ResaleTypeEnum.OFFER, 'Auction - Buyers bid and you choose to accept or cancel'],
  [ResaleTypeEnum.BUY_NOW, 'Buy Now - Buyer pays your set price']
])

export { ResaleTypeEnum, ResaleTypeNames }