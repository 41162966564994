import React, { useEffect, useState  } from "react";
import Bottom from "./Bottom";
import Footer from "./Footer";
import HeaderLanding from "./HeaderLanding";

// interface LayoutProps {
// 	headTitle: string;
// 	children: React.ReactNode;
// 	pageTitle: string;
// 	pageTitleSub: string;
// 	pageClass: string;
// 	parent: string;
// 	child: string;
// }

const LayoutFront = ({children}: {children: any}
	// {
	// 	headTitle,
	// 	children,
	// 	pageTitle,
	// 	pageTitleSub,
	// 	pageClass,
	// 	parent,
	// 	child,
	// }: LayoutProps
) => {
	const [, setHeight] = useState(null);
	useEffect(() => {
		setHeight((window as any).screen.height);
	}, []);
	return (
		<>
			{/* <PageHead headTitle={headTitle} /> */}
			<div id="main-wrapper" className="front">
				<HeaderLanding />

				{/* {pageTitle && (
					<PageTitleLanding
						pageTitle={pageTitle}
						pageTitleSub={pageTitleSub}
						parent={parent}
						child={child}
					/>
				)} */}

				{children}

				<Bottom />
				<Footer />
			</div>
		</>
	);
};

export default LayoutFront;
