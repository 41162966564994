import React, { useContext, useEffect, useState } from 'react';
import ProfileMenu from 'neftify/components/layout/ProfileMenu';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Activity, Collected, Offered, ProfilePage, } from './profile';
import { PrivateRoute, ScrollToTop } from '_common';
import LayoutFront from 'neftify/components/layout/LayoutFront';
import { Role, SpotOfferStatusEnum, Url } from '_constants';
import { IRoute, ISpotOffer } from "_interfaces";
import { CollectionList, TagList } from '_components/_publisher';
import { ArticleNFTForm } from '_components/_publisher/Article/ArticleNFTForm';
import { PageNotFound } from '_components/PageNotFound';
import { customerSpotOfferManagementService } from '_services';
import { Web3Context } from '_providers';

const ProfileSection: React.FunctionComponent = () => {
  const match = useRouteMatch();
  const web3Context = useContext(Web3Context);

  const [offersFromBuyers, setOffersFromBuyers] = useState<ISpotOffer[]>([]);
  const [ counter, setCounter ] = useState<number>(0);

  const OfferedComponent = () => {
    return <Offered refreshNotificationCounter={()=>{setCounter(counter+1)}} />
  }
  const menuItems: Map<string, IRoute> = new Map<string, IRoute>([
    ['Profile', { path: Url.ACCOUNT_SECTION_PROFILE, component: ProfilePage, roles: [Role.PUBLISHER, Role.CUSTOMER] }],
    ['Collected', { path: Url.ACCOUNT_SECTION_COLLECTED, component: Collected, roles: [Role.PUBLISHER, Role.CUSTOMER] }],
    ['Activity', { path: Url.ACCOUNT_SECTION_ACTIVITY, component: Activity, roles: [Role.PUBLISHER, Role.CUSTOMER] }],
    ['Offers', { path: Url.ACCOUNT_SECTION_OFFERS, component: OfferedComponent, roles: [Role.PUBLISHER, Role.CUSTOMER] }],
    ['Tags', { path: Url.ACCOUNT_SECTION_TAGS, component: TagList, roles: [Role.PUBLISHER] }],
    ['Collections', { path: Url.ACCOUNT_SECTION_COLLECTIONS, component: CollectionList, roles: [Role.PUBLISHER] }],
    ['NFT', { path: Url.ACCOUNT_SECTION_NFT, component: ArticleNFTForm, roles: [Role.PUBLISHER] }],
  ])


  useEffect(() => {
    const _load = async () => {
      try {
        const data: ISpotOffer[] = await customerSpotOfferManagementService.getAll();
        const offersFromBuyers = data.filter(
          (item: ISpotOffer) => item.ownerEthAddress && item.ownerEthAddress.toLowerCase() === web3Context.account?.toLowerCase() && item.statusId === SpotOfferStatusEnum.ACTIVE
        );

        setOffersFromBuyers(offersFromBuyers);
      } catch (error: any) {
        console.error({ error });
        //messengerContext.setMessage({title: 'Error', body: error.message});
      }
    };

    _load();
  }, [web3Context.account, offersFromBuyers.length, counter]);
  



  return (

    <ScrollToTop>
      <LayoutFront>
        <div className="profile-page">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ProfileMenu offerCount={offersFromBuyers.length} menuItems={menuItems} />

                <Switch>

                  <PrivateRoute
                    exact
                    path={`${match.path}/profile`}
                    component={ProfilePage}
                    roles={[Role.CUSTOMER, Role.PUBLISHER]}
                    redirectUrl={Url.CUSTOMER_REGISTRATION}
                  />

                  <PrivateRoute
                    exact
                    path={`${match.path}/collected`}
                    component={Collected}
                    roles={[Role.CUSTOMER, Role.PUBLISHER]}
                    redirectUrl={Url.CUSTOMER_REGISTRATION}
                  />


                  <PrivateRoute
                    exact
                    path={`${match.path}/activity`}
                    component={Activity}
                    roles={[Role.CUSTOMER, Role.PUBLISHER]}
                    redirectUrl={Url.CUSTOMER_REGISTRATION}
                  />

                  <PrivateRoute
                    exact
                    path={`${match.path}/offers`}
                    component={OfferedComponent}
                    roles={[Role.CUSTOMER, Role.PUBLISHER]}
                    redirectUrl={Url.CUSTOMER_REGISTRATION}
                  />

                  <PrivateRoute
                    exact
                    path={`${match.path}/Tags`}
                    component={TagList}
                    roles={[Role.PUBLISHER]}
                    redirectUrl={Url.CUSTOMER_REGISTRATION}
                  />

                  <PrivateRoute
                    exact
                    path={`${match.path}/collections`}
                    component={CollectionList}
                    roles={[Role.CUSTOMER, Role.PUBLISHER]}
                    redirectUrl={Url.CUSTOMER_REGISTRATION}
                  />

                  <PrivateRoute
                    exact
                    path={`${match.path}/nft`}
                    component={ArticleNFTForm}
                    roles={[Role.PUBLISHER]}
                    redirectUrl={Url.CUSTOMER_REGISTRATION}
                  />

                  <Route><PageNotFound /></Route>


                  {/* <pre>{JSON.stringify(Array.from(menuItems.entries()), null, 2)}</pre> */}

                  {/* <Route exact path={`${match.path}/offers`}><Offered/></Route> */}
                  {/* {menuItems.map((r: IRoute, i: number) => (
                <PrivateRoute
                  key={`cust-${i}`}
                  exact
                  path={r.path}
                  component={r.component}
                  roles={r.roles || []}
                  redirectUrl={Url.CUSTOMER_REGISTRATION}
                />
              ))} */}
                </Switch>
              </div>
            </div>
          </div>
        </div>

      </LayoutFront>
    </ScrollToTop>
  );
}

export { ProfileSection }