import React from "react";
import { IDataField, ITag } from "_interfaces";
import { customerTagManagementApiService as apiService} from "_services";
import { ListContainer } from "_common";
import { TagForm } from ".";
import { FormButtons } from "_constants";

const TagList: React.FunctionComponent = () => {
  
  const emptyValues: ITag = {
    layoutUid: 0,
    articleUid: undefined,
    spotUid: undefined,
    name: "",
  };

  const onSelectedRows = (ids: string[]) => {
    //console.log('selected ', ids);
  };

  const editableFields: IDataField[] = [
    { name: "id", title: "id", editable: false },
    { name: "uid", title: "UID", editable: false },
    { name: "layoutUid", title: "Layout UID", editable: false },
    { name: "articleUid", title: "Article UID", editable: true },
    { name: "spotUid", title: "Spot UID", editable: true },
    { name: "name", title: "Name", editable: true },
  ];


  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ["uid", ["UID", undefined]],
    ["name", ["Name", undefined]],
    ["layoutUid", ["Layout UID", undefined]],
    ["articleUid", ["Article UID", undefined]],
    ["spotUid", ["Spot UID", undefined]],
  ]);

  return (
    <div className="container-md">
    <ListContainer
      apiService={apiService}
      emptyValues={emptyValues}
      onSelectedRows={onSelectedRows}
      buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
      hasMultiSelect={false}
      title="Manage Tags"
      fieldList={fieldList}
      editableFields={editableFields}
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <TagForm
            isEditing={isEditing}
            onSuccess={onSuccess}
            initialValues={initialValues}
            onCancelClick={onCancelClick}
            editableFields={editableFields}
          />
        );
      }}
    </ListContainer>
    </div>
  );
};

export { TagList  };
