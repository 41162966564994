import React from 'react';
import {Field, ErrorMessage } from "formik";

const SlotFieldGroup: React.FunctionComponent<{
  errors: any;
  touched: any;
  index: any;
  label: string;
  name: string;
  placeholder: string;
  readonly?: boolean;
}> = ({ errors, touched, index, label, name, placeholder, readonly }) => {
  return (
    <div className="form-group">
      {label}
      <Field
        className={
          "form-control" +
          (errors && errors.slots && errors.slots[index] && touched && touched.slots && touched.slots[index] ? " is-invalid" : "")
        }
        name={`slots.${index}.${name}`}
        type="input"
        placeholder={placeholder}
        disabled={!readonly ? false : readonly}
      />

      {errors && <ErrorMessage name={`slots.${index}.${name}`} component="div" className="invalid-feedback" />}
    </div>
  );
};

export {SlotFieldGroup}