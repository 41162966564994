
import { IAccount, ICredentials, ISignature } from "_interfaces";
import axios from "axios";
import { config } from '_config';
import { Role, StorageKey, UserRoleEnum } from "_constants";


class AuthenticationService {

  constructor(
    protected apiBaseUrl: string,
    protected storageTokenKey: string,
    protected storageAccountKey: string,
  ) { }

  async login(data: ICredentials | ISignature): Promise<IAccount | undefined> {

    const url: string = (data as ICredentials).password ? `${this.apiBaseUrl}/login` : `${this.apiBaseUrl}/login-signature`;
    try {
      const response: any = await axios.post(url, data);
      if (!response.data || response.data.error) {
        throw Error(`${response.data.error.message}`)
      }
      localStorage.setItem(this.storageTokenKey, response.data.token);
      await this.setLocalAccount();
      return response.data;
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  getLocalAccount(): IAccount | null {
    const data = localStorage.getItem(this.storageAccountKey);
    if (data) {
      const obj = JSON.parse(data);
      return obj.account;
    } else {
      return null;
    }
  }

  async setLocalAccount() {
    // get the account and save it
    try {
      const url: string = `${this.apiBaseUrl}/account`;
      const response = await axios.get(url, await this.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      if (response.data.account) {
        const currentRoleId = response.data.account.roleId;
        if (currentRoleId === UserRoleEnum.PUBLISHER || currentRoleId === UserRoleEnum.BASIC) {
          response.data.account.roleId = response.data.account.roleId === UserRoleEnum.PUBLISHER ? Role.PUBLISHER : Role.CUSTOMER;
        } 
      } else {
        throw Error('Unexpected API response. no account found');
      }
      localStorage.setItem(this.storageAccountKey, JSON.stringify(response.data));
    } catch (err) {
      console.error(err);
      throw Error(`error setting account: ${err}`);
    }
  }

  logout = () => {
    [
      this.storageTokenKey,
      this.storageAccountKey,
      StorageKey.WEB3_EXPIRY,
      StorageKey.WEB3_SIGNATURE,
    ].forEach((key: string) => localStorage.removeItem(key));
  }

  getToken(): string {
    const token = localStorage.getItem(this.storageTokenKey) || '';
    return token;
  }

  async getAuthHeader(redir?: string): Promise<Object> {

    if (this.getToken() === '') {
      return {
        headers: {
          Authorization: '',
          token: '',
        }
      }
    }

    // check if token is expired
    if (await this.isTokenExpired()) {
      console.log('isTokenExpired', 'token is expired');
      let redirPath = '/login';
      if (redir) {
        redirPath = `${redirPath}?redir={redir}`
      }
      //window.location.href = redirPath;
    }

    const header = {
      headers: {
        Authorization: 'Bearer ' + this.getToken(),
        token: this.getToken()
      }
    };

    return header;
  }

  async isTokenExpired(): Promise<boolean> {
    const url: string = `${this.apiBaseUrl}/account`;
    let result: boolean = false;

    const token = this.getToken();

    if (!token) {
      console.error('no token');
      //return true;
    }

    const header = {
      headers: {
        Authorization: 'Bearer ' + token,
        token: token
      }
    };

    try {
      console.log({url, header});
      const response = await axios.get(url, header);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      if (response.data.account) {
        result = false;
      }
    } catch (error: any) {
      if (error.message === 'Failed to authenticate token.') {
        result = true;
      } else {
        console.error(error);
        throw Error(error);
      }
    } finally {
      return result;
    }
  }

}


class AdminAuthenticationService extends AuthenticationService {

  constructor(
    protected apiBaseUrl: string,
    protected storageTokenKey: string,
    protected storageAccountKey: string,
  ) { 
    super(apiBaseUrl, storageTokenKey, storageAccountKey);
  }

  async setLocalAccount() {
    // get the account and save it
    try {
      const url: string = `${this.apiBaseUrl}/account`;
      const response = await axios.get(url, await this.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      if (response.data.account) {
        response.data.account.roleId = Role.ADMIN;
      } else {
        throw Error('Unexpected API response. no account found');
      }
      localStorage.setItem(this.storageAccountKey, JSON.stringify(response.data));
    } catch (err) {
      console.error(err);
      throw Error(`error setting account: ${err}`);
    }
  }  
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const adminApiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin`;
const adminAuthenticationService = new AdminAuthenticationService(adminApiBaseUrl, StorageKey.ADMIN_TOKEN, StorageKey.ADMIN_ACCT);
const customerAuthenticationService = new AuthenticationService(apiBaseUrl, StorageKey.CUSTOMER_TOKEN, StorageKey.CUSTOMER_ACCT);
export { AuthenticationService, adminAuthenticationService, customerAuthenticationService }
