import axios
  from "axios";
import { config } from "_config";
import { IArticle, ICollection, ISpot, ISpotResponse } from "_interfaces";

class PublicApiService<T> {

  protected singleUrl: string; // endpoint that handles a single row
  protected allUrl: string; // endpoint that handles multiple rows

  constructor(
    protected apiBaseUrl: string,
    protected singleEndpoint: string,
    protected allEndpoint: string,
  ) {
    this.allUrl = `${this.apiBaseUrl}${this.allEndpoint}`;
    this.singleUrl = `${this.apiBaseUrl}${this.singleEndpoint}`;

  }

  async getAll(): Promise<T[]> {

    try {
      const response: any = await axios.get<T[]>(this.allUrl);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as T[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getById(id: number): Promise<T> {
    const url = `${this.singleUrl}/${id}`;
    try {
      const response: any = await axios.get<T>(url);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as T;
    } catch (err: any) {
      throw Error(err.message);
    }
  }
    
}


class PublicArticleApiService extends PublicApiService<IArticle> {
  async getByCollectionId(id: number) {
    const url = `${this.allUrl}?fCollectionUid=${id}`;
    try {
      const response: any = await axios.get<IArticle[]>(url);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IArticle[];

    } catch (error: any) {
      console.error('error getting articles', {error});
      throw Error((error as Error).message);
    }
  }
}



class PublicNftSpotApiService {

  constructor (
    protected apiBaseUrl: string
  ) {}

  async getByNftUid(nftUid: number): Promise<ISpot[]> {
    const url = `${this.apiBaseUrl}/nft-spots/${nftUid}`;
    try {
      const response: any = await axios.get<ISpot[]>(url);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as ISpot[];
    } catch (error:any) {
      console.error('error getting spots', {error});
      throw Error((error as Error).message);
    }
  } 
}


const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const publicCollectionApiService: PublicApiService<ICollection> = new PublicApiService<ICollection>(apiBaseUrl, '/collection', '/collections');
const publicArticleApiService: PublicArticleApiService  = new PublicArticleApiService(apiBaseUrl, '/article', '/articles');
const publicSpotApiService: PublicApiService<ISpotResponse> = new PublicApiService<ISpotResponse>(apiBaseUrl, '/spot', '/spots');
const publicNftSpotApiService: PublicNftSpotApiService = new PublicNftSpotApiService(apiBaseUrl);

export { publicArticleApiService, publicCollectionApiService, publicSpotApiService, publicNftSpotApiService, PublicApiService }