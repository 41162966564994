import React from "react";
import { ICollection, IDataField } from "_interfaces";
import { customerCollectionManagementApiService } from "_services";
import { ListContainer } from "_common";
import { CollectionForm } from "./CollectionForm";
import { CollectionStatusEnum, FormButtons } from "_constants";

const CollectionList: React.FunctionComponent = () => {
  const emptyValues: ICollection = {
    name: "",
    description: "",
    image: "",
    statusId: CollectionStatusEnum.ACTIVE,
  };

  const onSelectedRows = async (ids: string[]) => {
    // try {
    //   await adminLabelService.generateProspects(ids.map(i => Number(i)), 1)
    //   setStatus('Labels Generated');
    // } catch (error: any) {
    //   setStatus(error);
    // }
  };

  const editableFields: IDataField[] = [
    { name: "uid", title: "UID", editable: false },
    { name: "name", title: "Name", editable: true },
    { name: "description", title: "Description", editable: true },
    { name: "image", title: "Cover Image", editable: true },
  ];

  const getCoverImage = (row: any)  => {
    if (row['imageUrl']) {
      return <img className='mintdesk-list-cover-image' src={row['imageUrl']} alt='Cover' />
    }
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ["uid", ["UID", undefined]],
    ["name", ["Name", undefined]],
    ["description", ["Description", undefined]],
    ["image", ["Cover Image", getCoverImage]],
  ]);

  return (
    <div className="container-md my-2">
      <ListContainer
        apiService={customerCollectionManagementApiService}
        emptyValues={emptyValues}
        onSelectedRows={onSelectedRows}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        hasMultiSelect={false}
        title="Manage Collections"
        fieldList={fieldList}
        editableFields={editableFields}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
          return (
            <CollectionForm
              isEditing={isEditing}
              onSuccess={onSuccess}
              initialValues={initialValues}
              onCancelClick={onCancelClick}
              editableFields={editableFields}
            />
          );
        }}
      </ListContainer>
    </div>
  );
};

export { CollectionList };
