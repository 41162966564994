import React from "react";
import { IContract } from "_interfaces";
import { adminContractApiService } from "_services";
import { ListContainer } from "_common";
import { AdminContractForm } from ".";
import { ContractStatusEnum, FormButtons } from "_constants";

const ContractList: React.FunctionComponent = () => {
  
  const emptyValues: IContract = {
    // networkId: 5777, // @TODO: dev only. DO NOT HARDCODE
    statusId: ContractStatusEnum.ACTIVE,
    name: '',
    description: '',
    address: '',
    abi: '',
  };

  const onSelectedRows = (ids: string[]) => {
    //console.log('selected ', ids);
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['statusId', ['Status ID',undefined]],
    ['name', ['Name',undefined]],
    ['description', ['Description',undefined]],
    ['address', ['Address',undefined]],
  ]);

  return (
    <ListContainer 
      apiService={adminContractApiService} 
      emptyValues={emptyValues} 
      onSelectedRows={onSelectedRows}
      buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
      hasMultiSelect={false}
      title="Manage Contracts"
      fieldList={fieldList}
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <AdminContractForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick}/>
        );
      }}
    </ListContainer>
  );
};

export { ContractList as AdminContractList};
