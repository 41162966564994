import React, { useEffect, useState } from "react";

import { DropDown } from "_common/form/DropDown";
import { ICollection, IKeyValue } from "_interfaces";
import { customerCollectionManagementApiService } from "_services";

const CollectionDropDown: React.FunctionComponent<{ errors: any; touched: any; name: string }> = ({ errors, touched, name }) => {
  const [collection, setCollection] = useState<Map<number, string>>();

  useEffect(() => {
    const _load = async () => {
      const result = await customerCollectionManagementApiService.getAll();

      const collection: Map<number, string> = new Map(
        result.map((i: ICollection) => [i.uid as number, i.description as string])
      );
      setCollection(collection);
    };

    _load();
  }, []);

  return (
    <>
      {collection && <DropDown enumMap={collection} 
      defaultItem={{key: 0, value: 'Select Collection'} as IKeyValue<number>}
      errors={errors} 
      touched={touched}
       label="Collection" name={name} />}

      {!collection && <div>Loading Collections...</div>}
    </>
  );
};

export { CollectionDropDown };
