import React from 'react';

const Bottom = () => {
    return (
        <>
            <div className="bottom section-padding triangle-top-dark triangle-bottom-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-7 col-sm-8">
                            <div className="bottom-logo">
                                <img
                                    className="pb-3"
                                    src="/images/logo.png"
                                    alt=""
                                />

                                <p>
                                    Mintdesk blurb here
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-5 col-sm-4 col-6">
                            <div className="bottom-widget">
                                <h4 className="widget-title">About us</h4>
                                <ul>
                                    <li>
                                        <a href="/explore">Explore</a>
                                    </li>
                                    <li>
                                        <a href="/item">Item</a>
                                    </li>
                                    <li>
                                        <a href="/collection">Collection</a>
                                    </li>
                                    <li>
                                        <a href="/connect">Connect</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6">
                            <div className="bottom-widget">
                                <h4 className="widget-title">Settings</h4>
                                <ul>
                                    <li>
                                        <a href="/settings">Settings</a>
                                    </li>
                                    <li>
                                        <a href="/application">Application</a>
                                    </li>
                                    <li>
                                        <a href="/security">Security</a>
                                    </li>
                                    <li>
                                        <a href="/activity">Activity</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8">
                            <div className="bottom-widget">
                                <h4 className="widget-title">Profile</h4>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                        <ul>
                                            <li>
                                                <a href="/profile">Profile</a>
                                            </li>
                                            <li>
                                                <a href="/created">Created</a>
                                            </li>
                                            <li>
                                                <a href="/collected">Collected</a>
                                            </li>
                                            <li>
                                                <a href="/activity">Activity</a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                        <ul>
                                            <li>
                                                <a href="/onsale">On Sale</a>
                                            </li>
                                            <li>
                                                <a href="/liked">Liked</a>
                                            </li>
                                            <li>
                                                <a href="/following">Following</a>
                                            </li>
                                            <li>
                                                <a href="/followers">Followers</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bottom;