import { IRoute } from "_interfaces";
import {
  ProfilePage,
  Faq,
  PrivacyPolicy,
  TermsAndConditions,
  UserRegistration,
  Dashboard,
  LogoutPage,
  ForgotPassword,
  ResetPassword,
  ConfirmEmail,
  WalletProfile,
  MetamaskPage,
  Collections,
  CustomerArticles,
  Collection,
  Collected,
  Activity,
  Created,
  Offered,
  TagList,
} from "_components";

import { Role, Url } from "_constants";
import { CollectionList } from "_components/_publisher/Collection";
import { ArticleList } from "_components/_publisher/Article";
import { ArticleNFTForm } from "_components/_publisher/Article/ArticleNFTForm";
import { MyNfts, NftBidPage, NftBuyPage, SpotRedirector } from "_components/_customer/Nft";


const customerRoutes: IRoute[] = [
  // Customer, Logged In Routes
  { path: Url.CUSTOMER_DASHBOARD, component: Dashboard, roles: [Role.CUSTOMER, Role.PUBLISHER] },

  // Profile Routes
  { path: Url.CUSTOMER_WALLET_PROFILE, component: WalletProfile, roles: [Role.CUSTOMER, Role.PUBLISHER]},
  { path: Url.CUSTOMER_ACCOUNT_PROFILE, component: ProfilePage, roles: [Role.CUSTOMER, Role.PUBLISHER] },
  { path: Url.CUSTOMER_ACCOUNT_COLLECTED, component: Collected, roles: [Role.CUSTOMER, Role.PUBLISHER] },
  { path: Url.CUSTOMER_ACCOUNT_ACTIVITY, component: Activity, roles: [Role.CUSTOMER, Role.PUBLISHER] },
  { path: Url.CUSTOMER_ACCOUNT_CREATED, component: Created, roles: [Role.CUSTOMER, Role.PUBLISHER] },
  { path: Url.CUSTOMER_ACCOUNT_OFFERED, component: Offered, roles: [Role.CUSTOMER, Role.PUBLISHER]},

  // Customer, Anonymous Routes

  { path: Url.CUSTOMER_METAMASK_PAGE, component: MetamaskPage},

  { path: Url.CUSTOMER_LOGOUT, component: LogoutPage },
  { path: Url.CUSTOMER_FAQ, component: Faq },
  { path: Url.CUSTOMER_PRIVACY_POLICY, component: PrivacyPolicy },
  { path: Url.CUSTOMER_TERMS, component: TermsAndConditions },
  { path: Url.CUSTOMER_REGISTRATION, component: UserRegistration },
  { path: Url.CUSTOMER_FORGOT_PASSWORD, component: ForgotPassword },
  { path: Url.CUSTOMER_RESET_PASSWORD, component: ResetPassword },
  { path: Url.CUSTOMER_CONFIRM_EMAIL, component: ConfirmEmail },

  { path: Url.CUSTOMER_COLLECTIONS, component: Collections},
  { path: Url.CUSTOMER_COLLECTIONS_BY_ADDR, component: Collections },
  { path: Url.CUSTOMER_COLLECTION_DETAIL, component: Collection},
  { path: Url.CUSTOMER_ARTICLES, component: CustomerArticles},

  { path: Url.CUSTOMER_SPOT_REDIRECTOR, component: SpotRedirector},

  // auth required
  { path: Url.CUSTOMER_MANAGE_COLLECTION, component: CollectionList, roles: [Role.PUBLISHER]},
  { path: Url.CUSTOMER_MANAGE_ARTICLES, component: ArticleList, roles: [Role.PUBLISHER]},

  { path: Url.CUSTOMER_ARTICLE_NFT_FORM, component: ArticleNFTForm, roles: [Role.PUBLISHER]},
  { path: Url.CUSTOMER_ARTICLE_NFT_FORM_EDIT, component: ArticleNFTForm, roles: [Role.PUBLISHER]},

  { path: Url.CUSTOMER_NFT_BID_SPOT, component: NftBidPage},
  { path: Url.CUSTOMER_NFT_BID, component: NftBidPage},
  { path: Url.CUSTOMER_NFT_BUY, component: NftBuyPage},
  { path: Url.CUSTOMER_MY_NFTS, component: MyNfts, roles: [Role.CUSTOMER, Role.PUBLISHER]},
  { path: Url.PUBLISHER_TAGS, component: TagList, roles: [Role.PUBLISHER, Role.CUSTOMER]}


];

export { customerRoutes }