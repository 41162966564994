enum SpotOfferStatusEnum {
  ACTIVE = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  CANCELED = 4,
}


const SpotOfferStatusNames: Map<number, string> = new Map<number, string>([
  [SpotOfferStatusEnum.ACTIVE, 'Active'],
  [SpotOfferStatusEnum.ACCEPTED, 'Accepted'],
  [SpotOfferStatusEnum.REJECTED, 'Rejected'],
  [SpotOfferStatusEnum.CANCELED, 'Canceled']
])

export { SpotOfferStatusEnum, SpotOfferStatusNames}