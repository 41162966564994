enum NftPriceTypeEnum {
  FIXED = 1,
  AUCTION = 2
}

const NftPriceTypeNames: Map<number, string> = new Map<number, string>([
  [NftPriceTypeEnum.AUCTION, 'Auction'],
  [NftPriceTypeEnum.FIXED, 'Fixed']
])

export { NftPriceTypeEnum, NftPriceTypeNames}
