export enum Url {
  // ADMIN paths
  ADMIN_HOME_PAGE = '/admin',
  ADMIN_PROFILE = '/admin/profile',
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_LOGIN = '/admin/login',
  ADMIN_CONTRACT = '/admin/contract',
  ADMIN_NFT = '/admin/nft',
  ADMIN_LAYOUT = '/admin/layout',

  // CUSTOMER paths
  CUSTOMER_DASHBOARD = '/dashboard',
  CUSTOMER_FAQ = '/faq',
  CUSTOMER_PRIVACY_POLICY = '/privacy-policy',
  CUSTOMER_TERMS = '/terms-and-conditions',
  CUSTOMER_REGISTRATION = '/user-registration',
  CUSTOMER_LOGIN = '/login',
  CUSTOMER_LOGOUT = '/logout',
  CUSTOMER_FORGOT_PASSWORD = '/forgot-password',
  CUSTOMER_RESET_PASSWORD = '/reset-password/:authID',
  CUSTOMER_CONFIRM_EMAIL = '/confirm-email/:authID',

  // ACCOUNT PATHS
  CUSTOMER_ACCOUNT_PROFILE = '/account-profile',
  CUSTOMER_WALLET_PROFILE = '/waccount-profile',
  CUSTOMER_ACCOUNT_COLLECTED = '/account-collected',
  CUSTOMER_ACCOUNT_ACTIVITY = '/account-activity',
  CUSTOMER_ACCOUNT_CREATED = '/account-created',
  CUSTOMER_ACCOUNT_OFFERED = '/account-offered',
  CUSTOMER_ACCOUNT_SECTION = '/account-section/*',

  //ACCOUNT SUBPATHS
  ACCOUNT_SECTION_PROFILE = '/account-section/profile',
  ACCOUNT_SECTION_COLLECTED = '/account-section/collected',
  ACCOUNT_SECTION_ACTIVITY = '/account-section/activity',
  ACCOUNT_SECTION_CREATED = '/account-section/created',
  ACCOUNT_SECTION_OFFERS = '/account-section/offers',
  ACCOUNT_SECTION_TAGS = '/account-section/tags',
  ACCOUNT_SECTION_COLLECTIONS = '/account-section/collections',
  ACCOUNT_SECTION_NFT = '/account-section/nft',

  // poc endpoints
  CUSTOMER_LAZYMINT_PAGE = '/lazymint',
  CUSTOMER_NFT_LISTING_PAGE = '/nft-listing',

  // nft endpoints
  CUSTOMER_METAMASK_PAGE = '/metamask',
  CUSTOMER_COLLECTIONS = '/collections',
  CUSTOMER_COLLECTIONS_BY_ADDR  = '/collections/:ethAddress',
  CUSTOMER_COLLECTION_DETAIL = '/collection/:id',
  CUSTOMER_COLLECTION_DETAIL_PATH = '/collection',
  CUSTOMER_MANAGE_COLLECTION = '/manage-collection',
  CUSTOMER_MANAGE_ARTICLES = '/manage-articles',
  CUSTOMER_MANAGE_NFT = '/manage-nft',
  CUSTOMER_ARTICLE_NFT_FORM = '/article-nft-form',
  CUSTOMER_ARTICLE_NFT_FORM_EDIT = '/article-nft-form/:articleUid/:nftUid',
  CUSTOMER_SPOT_REDIRECTOR = '/spot/:action/:spotUid', // /spot/buy, /spot/collection, 
  CUSTOMER_SPOT_BID = '/spot/bid',
  CUSTOMER_ARTICLES = '/articles',

  CUSTOMER_NFT_BID = '/nft/bid/:articleUid/:nftUid',
  CUSTOMER_NFT_BID_PATH = '/nft/bid',
  CUSTOMER_NFT_BID_SPOT = '/nft/bid/:articleUid/:nftUid/:spotUid',

  CUSTOMER_NFT_BUY = '/nft/buy/:articleUid/:nftUid',
  CUSTOMER_NFT_BUY_PATH = '/nft/buy',
  
  CUSTOMER_VOUCHER_EDIT_FORM ='/nft/edit/:articleUid/:nftUid',

  CUSTOMER_MY_NFTS = '/my-nfts',

  PUBLISHER_TAGS ='/publisher/tags',
  
  // common paths
  LOGOUT = '/logout',
  DEFAULT_HOME = '/',
}