import { Formik, Form } from "formik";
import React, { useContext } from "react";
import { ConfirmCheck, FieldWithError } from "_common";
import { validationMap, Validator } from "_components/_schemas";
import { INft, ISpot } from "_interfaces";
import { AccountContext } from "_providers";
import { nftService } from "_services";
import { Url } from "_constants";

const OfferForm: React.FunctionComponent<{ nft: INft, spot?: ISpot, onSuccess: (message:string | React.ReactNode)=>void, handleClose: ()=>void }> = ({ nft, spot, onSuccess, handleClose }) => {
  const accountContext = useContext(AccountContext);

  const validationSchema = validationMap.get(Validator.ARTICLE_OFFER_FORM);
  const initialValues = {
    price: spot ? spot.price : 0,
    spotUID: spot ? spot.uid : 0,
    expireDays: 1,
    agreeTerms: false,
  };

  const onSubmit = async (data: any, actions: any) => {
    const { setSubmitting, setStatus } = actions;
    console.log({ data });
    try {
      if (accountContext.account.feePctMd) {
        await nftService.makeSpotOffer(nft, data.price, accountContext.account.feePctMd, setStatus, spot);
        setSubmitting(false);
        onSuccess(<>Offer was made succesfully. Go to the <a href={Url.ACCOUNT_SECTION_OFFERS}>Offers</a> tab in your Profile page to view your Offers</>);
        handleClose();
      } else {
        throw Error("Account timed out. Try logging out and logging back in.");
      }
    } catch (error: any) {
      console.error({ error });
      setSubmitting(false);
      setStatus(`Offer failed. ${error.message}`);
    }
  };

  return (
    <div className="container">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors, status, touched, isSubmitting, setSubmitting, values }) => {
          return (
            <>
              <Form className="form">

                <FieldWithError errors={errors} touched={touched} fieldName="price" label="Enter Price" placeholder="0"/>
                {spot && spot.price &&  
                <div className="alert alert-primary my-2">The price is the minimum amount set by the Seller.</div>
                }
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="expireDays"
                  label="Expiration (in days)"
                  placeholder="0"
                />

                {status && <div className="alert alert-danger my-2">{status}</div>}

                {isSubmitting && <div className="spinner-border"></div>}

                <div className="alert alert-primary my-2">
                  IMPORTANT: to ensure that offers are valid, Mintdesk will hold the amount you offered and transferred to the
                  owner upon acceptance of your offer. The amount will be refunded to you immediately after someone offers a
                  higher bid or if the owner rejects your offer.
                </div>

                <ConfirmCheck name="agreeTerms" errors={errors} touched={touched}>
                  <div>I have read and agree with the terms.</div>
                </ConfirmCheck>

                <button disabled={isSubmitting} type="submit" className="btn btn-primary my-2">
                  Submit Offer
                </button>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export { OfferForm };
