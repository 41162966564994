import React, { useEffect, useState } from "react";
import { adminNftApiService } from "_services";

const AdminNftList: React.FunctionComponent = () => {

  const [data, setData] = useState<any>([]);

  useEffect(()=>{
    const _init = async () => {
        const data = await adminNftApiService.getNfts()
        setData(data);
    }

    _init();
  }, [])

  return(
    <div className="container">

    <h1>Auctions</h1>

    <pre>
      {JSON.stringify(data, null, 2)}
    </pre>
    </div>

  );

};

export { AdminNftList };
