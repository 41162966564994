import React, { useContext, useState } from "react";
import { Formik, FieldArray, Form } from "formik";
import { validationMap, Validator } from "_components/_schemas";
import { FieldWithError, FileUpload, PriceTypeField } from "_common";
import { nftService } from "_services";
import { CollectionDropDown } from "../Collection";
import { PriceTypeEnum } from "mdlib/dict/price-type.enum";
import { SlotFieldGroup } from "./SlotFieldGroup";
import { AccountContext, MessengerContext } from "_providers";
import { Helper } from "_common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//@TODO: REMOVE THIS. THIS IS JUST A CONVENIENCE TOOL FOR TESTING.
const TestDates: React.FunctionComponent = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  console.log({
    yesterday: Math.floor(yesterday.getTime() / 1000) + 15,
    ThreeMinsFromNow: Math.floor(today.getTime() / 1000) + (3 * 60),
    ThirtyDaysFromNow: Math.floor(today.getTime() / 1000) + (60 * 60 * 24 * 30),
  })
  return (
    <></>
    // <table className="table">
    //   <tr>
    //     <td>Yesterday</td>
    //     <td><input value={Math.floor(yesterday.getTime() / 1000) + 15}/></td>
    //   </tr>
    //   <tr>
    //     <td>3 minutes from now</td>
    //     <td><input value={Math.floor(today.getTime() / 1000) + (3 * 60)}/></td>
    //   </tr>
    //   <tr>
    //     <td>30 days from now</td>
    //     <td><input value={Math.floor(today.getTime() / 1000) + (60 * 60 * 24 * 30)}/></td>
    //   </tr>
    // </table>
  );
};


const SlotDatePicker: React.FunctionComponent<{
  errors: any,
  touched: any,
  index: any,
  label: string,
  name: string,
  setFieldValue: any,
  values: any,
  isFullDay: boolean
}> = ({errors, touched, index, label, name, setFieldValue, values, isFullDay}) => {

  const selected = values.slots[index][name] ? new Date(values.slots[index][name]) : undefined;

  const onChange = (date: Date) => {
    const time: number = Helper.getAbsoluteDateTime(date, isFullDay);
    setFieldValue(`slots[${index}][${name}]`, time )
  }

  return (
    <div className="form-group">
      {label}
      <DatePicker selected={selected} onChange={onChange} />
    </div>
  );

}

const ArticleNFTForm: React.FunctionComponent = (props: any) => {
  const initialValues = {
    collectionUid: undefined,
    title: "",
    description: "",
    image: "",
    url: "",
    slots: [
      {
        pos: 0,
        amount: 0,
        priceType: 1,
        priceFixed: 0,
        priceStart: 0,
        priceReserve: 0,
        auctionStart: 0,
        auctionEnd: 0,
        protectionEnd: 0,
      },
    ],
  };

  const messengerContext = useContext(MessengerContext);
  const accountContext = useContext(AccountContext);

  const validationSchema = validationMap.get(Validator.ARTICLE_NFT_FORM);

  const onSubmit = async (data: any, actions: any) => {
    const { setStatus } = actions;
    console.log("onSubmit createVoucher data", { data });

    // timestamps are, by default, milliseconds. make it seconds.
    data.slots.forEach( (d: any) => {
      d.auctionStart  = d.auctionStart / 1000;
      d.auctionEnd = d.auctionEnd / 1000;
      d.protectionEnd = d.protectionEnd / 1000;
    })

    console.log("onSubmit createVoucher data2", { data});

    try {
      if (!accountContext.account) {
        throw Error("Account not found");
      }
      if (accountContext.account.feePctMd) {
        const result = await nftService.createVoucher(data, setStatus, accountContext.account.feePctMd);
        console.log({ result });
        messengerContext.setMessage({ title: "Create Article", body: "Success" });
        data = initialValues;
      } else {
        throw Error("Account info missing. Try logging out and logging back in.");
      }
    } catch (error) {
      messengerContext.setMessage({ title: "Error", body: `${(error as Error).message}` });
      setStatus((error as Error).message);
      console.error({ error });
    }
  };

  return (
    <>
      <div className="container-md my-2">
        <h1>Article NFT Form</h1>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
          {({ values, errors, touched, setFieldValue, status, setStatus }) => (
            <Form>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <CollectionDropDown errors={errors} touched={touched} name="collectionUid" />
                </div>

                <div className="col-12 col-lg-6">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="title"
                    placeholder="Article Title"
                    label="Article Title Description"
                  />
                </div>

                <div className="col-12 col-lg-6">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="description"
                    placeholder="Article Description"
                    label="Article Description"
                  />
                </div>

                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <FileUpload
                      name="image-upload"
                      errors={errors}
                      setFieldValue={setFieldValue}
                      title="Article Image"
                      touched={touched}
                      values={values}
                      targetField="image"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <FieldWithError errors={errors} touched={touched} fieldName="url" placeholder="URL" label="URL" />
                </div>
              </div>
              <div className="row slots mt-5">
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <h5>Slots</h5>
                <FieldArray name="slots">
                  {({ insert, remove, push }) => (
                    <div className="container">
                      {values.slots.length > 0 &&
                        values.slots.map((slot: any, index: any) => (
                          <div className="row" key={`__tr_${index}`}>
                            <div className="col">
                              <SlotFieldGroup
                                errors={errors}
                                touched={touched}
                                index={index}
                                label="Position"
                                name="pos"
                                placeholder="Position"
                                readonly={true}
                              />
                            </div>
                            <div className="col">
                              <SlotFieldGroup
                                errors={errors}
                                touched={touched}
                                index={index}
                                label="Shares/Spots"
                                name="amount"
                                placeholder="Shares"
                              />
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <div>Price Type</div>
                                <PriceTypeField errors={errors} touched={touched} name={`slots.${index}.priceType`} />
                              </div>
                            </div>

                            {values.slots[index].priceType.toString() === PriceTypeEnum.FIXED.toString() &&
                              <div className="col">
                                <SlotDatePicker
                                  errors={errors}
                                  index={index}
                                  name="protectionEnd"
                                  label="Exclusivity End Date"
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  isFullDay={true}
                                />
                                  {/* <SlotFieldGroup
                                    errors={errors}
                                    touched={touched}
                                    index={index}
                                    label="Exclusivity End Date"
                                    name="protectionEnd"
                                    placeholder="Exclusivity End Date"
                                  /> */}
                              </div>
                            }


                            {values.slots[index].priceType.toString() === PriceTypeEnum.FIXED.toString() && (
                              <div className="col">
                                <SlotFieldGroup
                                  errors={errors}
                                  touched={touched}
                                  index={index}
                                  label="Fixed Price"
                                  name="priceFixed"
                                  placeholder="Fixed Price"
                                />
                              </div>
                            )}

                            {values.slots[index].priceType.toString() === PriceTypeEnum.AUCTION.toString() && (
                              <>
                                <div className="col">
                                  <SlotFieldGroup
                                    errors={errors}
                                    touched={touched}
                                    index={index}
                                    label="Starting Price"
                                    name="priceStart"
                                    placeholder="Starting Price"
                                  />
                                </div>
                                <div className="col">
                                  <SlotFieldGroup
                                    errors={errors}
                                    touched={touched}
                                    index={index}
                                    label="Reserve Price"
                                    name="priceReserve"
                                    placeholder="Reserve Price"
                                  />
                                </div>
                                <div className="col">
                                  <SlotDatePicker
                                  errors={errors}
                                  index={index}
                                  name="auctionStart"
                                  label="Auction Start"
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  isFullDay={false}
                                  />
                                  {/* <SlotFieldGroup
                                    errors={errors}
                                    touched={touched}
                                    index={index}
                                    label="Auction Start"
                                    name="auctionStart"
                                    placeholder="Auction Start"
                                  /> */}
                                </div>
                                <div className="col">
                                <SlotDatePicker
                                  errors={errors}
                                  index={index}
                                  name="auctionEnd"
                                  label="Auction End"
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  isFullDay={true}
                                  />
                                  {/* <SlotFieldGroup
                                    errors={errors}
                                    touched={touched}
                                    index={index}
                                    label="Auction End"
                                    name="auctionEnd"
                                    placeholder="Auction End"
                                  /> */}
                                </div>
                              </>
                            )}
                            <div className="col">
                              <div className="form-group">
                                <div>&nbsp;</div>
                                <button type="button" className="btn btn-primary" onClick={() => remove(index)}>
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                      <button
                        type="button"
                        className="btn btn-primary mt-2"
                        onClick={() =>
                          push({
                            pos: values.slots.length,
                            /* @TODO: should not be hard-coded */
                            amount: 0,
                            priceType: 1,
                            priceFixed: 0,
                            priceStart: 0,
                            priceReserve: 0,
                            auctionStart: 0,
                            auctionEnd: 0,
                            protectionEnd: 0,
                          })
                        }
                      >
                        Add Slot
                      </button>
                    </div>
                  )}
                </FieldArray>
              </div>
              <TestDates />
              {status && <div className="alert alert-warning">{status}</div>}
              <button type="submit" className="btn btn-primary mt-5">
                Save
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { ArticleNFTForm };
