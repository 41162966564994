import React, { useContext } from "react";
import { Navbar } from "react-bootstrap";
import { Url } from "_constants";
import { Web3Context, AccountContext } from "_providers";
import { useHistory } from "react-router";
import web3 from "_connection/web3";



const Navigation: React.FunctionComponent = () => {
  const accountContext = useContext(AccountContext);
  const history = useHistory();
  const web3Context = useContext(Web3Context);

  const connectWalletHandler = async () => {
    if (!web3) {
      history.push(Url.CUSTOMER_METAMASK_PAGE);
    } else {
      try {
        //const provider = new ethers.providers.Web3Provider(web3, "any");
        await web3Context.connectWallet(web3);
        //const account = await web3Context.connectWallet(provider);
        // if (account) {
        //   // connected. try to load the account.
  
        //   // get from local if available and session has not expired.
  
        //   if (!accountContext.account) { // check if account is loaded
        //     const isExist = await customerAccountService.checkEthAddress(account);
        //     if (isExist) {
        //       // load it
        //       console.log('it exists');
        //       const provider = new ethers.providers.Web3Provider(web3, "any");
        //       const signature: ISignature = await customerAccountService.getSignatureFromSignedMessage(account, provider);
        //       signature.ethAddress = account;
        //       await accountContext.loginWithWallet(signature);
        //       console.log('logged in with wallet');
        //     } else {
        //       // doesnt exist need to register profile
        //       history.push(Url.CUSTOMER_WALLET_PROFILE);
        //     }
        //   } else {
        //     // account is loaded. do nothing.
        //     console.log({account});
        //   }
        // } else {
        //   throw Error('could not get account');
        // }
          
      } catch (error) {
        console.error('error connecting wallet', error);

      }
    }
  };

  const logout = () => {
    accountContext.logout(() => {history.push(Url.DEFAULT_HOME)});
  }

  return (
    <Navbar expand="lg" className="navbar fixed-top bg-white main-container pb-0">
      <div className="container-fluid">
        <Navbar.Brand href={Url.DEFAULT_HOME}>
          <div className="header-logo">Mint Desk</div>
        </Navbar.Brand>

        {web3Context.account && (
          <>
            <div>Wallet Connected {web3Context.account}</div>{" "}
            <button type="button" className="btn btn-primary text-white" onClick={() => history.push(Url.CUSTOMER_WALLET_PROFILE)}>
              Profile
            </button>
          </>
        )}

        {!web3Context.account && (
          <button type="button" className="btn btn-primary text-white" onClick={connectWalletHandler}>
            Connect your wallet
          </button>
        )}

        {!accountContext.account && !web3Context.account && (
          <button type="button" className="btn btn-primary text-white" onClick={() => history.push(Url.CUSTOMER_LOGIN)}>
            Login
          </button>
        )}

        {accountContext.account && !web3Context.account && (
          <button type="button" className="btn btn-primary text-white" onClick={logout}>
            Logout
          </button>
        )}
      </div>
    </Navbar>
  );
};

export { Navigation };
