
import { BaseApiService } from '_services';
import { adminAuthenticationService } from '_services';
import { config } from '_config';
import { IContract, IUser } from '_interfaces';
import { ILayout } from '_interfaces/tags';

class AdminApiService<T> extends BaseApiService<T> {}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin`;
const adminUserApiService: AdminApiService<IUser> = new AdminApiService<IUser>(apiBaseUrl, '/user', '/users', adminAuthenticationService);
const adminContractApiService: AdminApiService<IContract> = new AdminApiService<IContract>(apiBaseUrl, '/contract', '/contracts', adminAuthenticationService);

const adminLayoutApiService: AdminApiService<ILayout> = new AdminApiService<ILayout>(apiBaseUrl, '/layout', '/layouts', adminAuthenticationService);

export { AdminApiService, adminUserApiService, adminContractApiService, adminLayoutApiService }