import React from "react";
import { BaseForm, FieldWithError } from "_common";
import { IUser } from "_interfaces";
import { adminContractApiService as apiService } from "_services";
import { Validator, validationMap } from "_components/_schemas";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IUser) => void;
  initialValues: any;
  onCancelClick: () => void;
}

const ContractForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick }: IAppProps) => {
  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_CONTRACT_FORM)}
    >
      {({ errors, touched }) => {
        return (
          <><pre>{JSON.stringify(errors, null, 2)}</pre>
            {/* <FieldWithError errors={errors} touched={touched} fieldName="networkId" placeholder="Network ID" label="Network ID" /> */}

            <FieldWithError errors={errors} touched={touched} fieldName="statusId" placeholder="Status ID" label="Status ID" />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="name"
              placeholder="Contract Name"
              label="Contract Name"
            />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="description"
              placeholder="Contract Description"
              label="Contract Description"
            />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="address"
              placeholder="Contract Address"
              label="Contract Address"
            />

            <FieldWithError errors={errors} touched={touched} fieldName="abi" placeholder="ABI" label="ABI" />
          </>
        );
      }}
    </BaseForm>
  );
};

export { ContractForm as AdminContractForm };
