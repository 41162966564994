import React from 'react';
import { Modal } from 'react-bootstrap';
import { INft, ISpot } from '_interfaces';
import { OfferForm } from './OfferForm';


const ModalOffer: React.FunctionComponent<{ show: any, handleClose: any, nft: INft, spot?: ISpot, onSuccess: (message: string | React.ReactNode) => void }> = ({ show, handleClose, nft, spot, onSuccess }) => {

  return(
    <Modal
    show={show}
    onHide={handleClose}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">Make an Offer</Modal.Title>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
    </Modal.Header>
    <Modal.Body>
      <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <OfferForm nft={nft} spot={spot} onSuccess={onSuccess} handleClose={handleClose}/>
        </div>
      </div>
      </div>

    </Modal.Body>
  </Modal>

  );
}

export { ModalOffer }