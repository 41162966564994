import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RedirActionEnum, Url } from '_constants';
import { ISpotResponse } from '_interfaces';
import { publicSpotApiService } from '_services';


const SpotRedirector: React.FunctionComponent = (props: any) => {

  const history = useHistory();
  const action = props.match.params.action;
  const spotUid = props.match.params.spotUid;

  useEffect(()=>{
    const _load = async() => {
      try {
        const spotResponse: ISpotResponse = await publicSpotApiService.getById(spotUid);
        console.log({action}, {spotResponse}, {url: `${Url.CUSTOMER_NFT_BID_PATH}/${spotResponse.article.uid}/${spotResponse.nft.uid}/${spotResponse.uid}`});
        switch (action) {
          case RedirActionEnum.BID:
            return history.push(`${Url.CUSTOMER_NFT_BID_PATH}/${spotResponse.article.uid}/${spotResponse.nft.uid}/${spotResponse.uid}`);
          case RedirActionEnum.COLLECTION:
            return history.push(`${Url.CUSTOMER_COLLECTIONS}/${spotResponse.article.ownerEthAddress}`);
          default:
            return history.push(`${Url.DEFAULT_HOME}`);
        }
      } catch (error) {
        console.error({error});
      }
    }
    _load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>Redirecting...</>
  )
}

export { SpotRedirector }