import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { FieldWithError, FileUpload } from "_common";
import { INftSpot } from "_interfaces";
import { customerUserNftSpotApiService, customerStorageService } from "_services";
import { MessengerContext } from "_providers";

const BadgeForm: React.FunctionComponent<{
  nftSpot: INftSpot,
  closeModal: () => void,
  onEdit: (data: any, spotId: number) => void
}> = ({nftSpot, closeModal, onEdit}) => {

  const initialValues: any = 
  {
    spotId: nftSpot.uid,
    badgeAlias: nftSpot.badge?.alias,
    badgeBlurb: nftSpot.badge?.blurb,
    badgeFacebook: nftSpot.badge?.facebook,
    badgeImage: nftSpot.badge?.imageUrl,
    badgeInstagram: nftSpot.badge?.instagram,
    badgeOtherLink: nftSpot.badge?.otherLink,
    badgeSubtitle: nftSpot.badge?.subtitle,
    badgeTitle: nftSpot.badge?.title,
    badgeTwitter: nftSpot.badge?.twitter,
  };

  initialValues['imageUpload'] = null;
console.log({initialValues});

  const messengerContext = useContext(MessengerContext);

  const onSubmit = async (data: any, actions: any) => {

    const { setSubmitting } = actions;

    console.log('onsubmit', {data});
    // upload the badge
    if (data.badgeImage) {
      try {
          data.badgeImage = await customerStorageService.uploadImage(data.badgeImage);
      } catch (error: any) {
          setSubmitting(false);
          messengerContext.setMessage({title: 'Upload error', body: (error as Error).message})   
          console.error('upload error', {error});
          throw Error('error uploading badge image');
      }
    } else {
      delete data['badgeImage'];
    }

    try {
      // remove undefined, null properties
      Object.keys(data).forEach((k) => data[k] === null && delete data[k]);
      const spotId = data['spotId'];
      delete data['spotId'];
      console.log('onsubmit', {data, spotId});
      const response = await customerUserNftSpotApiService.update(data, spotId);
      console.log('badge submit response', {response});
      messengerContext.setMessage({title: 'Badge Update', body: 'Badge Updated'});
      onEdit(data, spotId);
      closeModal();
    } catch (error: any) {
      messengerContext.setMessage({title: 'Badge Error', body: error.message});
      console.error('error updating spot', {error});
      throw Error((error as Error).message);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, status, touched, isSubmitting, values, setFieldValue }) => {
        return (
          <Form className="form">
            <div className="row">
              {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
              <div className="col-6">
                <FieldWithError
                  errors={errors}
                  label="Alias"
                  touched={touched}
                  fieldName="badgeAlias"
                  placeholder="Enter Badge Alias"
                />
              </div>
              <div className="col-6">
                <FieldWithError
                  errors={errors}
                  label="Title"
                  touched={touched}
                  fieldName="badgeTitle"
                  placeholder="Enter Badge Title"
                />
              </div>
              <div className="col-6">
                <FieldWithError
                  errors={errors}
                  label="Subtitle"
                  touched={touched}
                  fieldName="badgeSubtitle"
                  placeholder="Enter Badge Subtitle"
                />
              </div>
              <div className="col-6">
                <FieldWithError
                  errors={errors}
                  label="Facebook link"
                  touched={touched}
                  fieldName="badgeFacebook"
                  placeholder="Enter Badge Facebook link"
                />
              </div>
              <div className="col-6">
                <FieldWithError
                  errors={errors}
                  label="Instagram Link"
                  touched={touched}
                  fieldName="badgeInstagram"
                  placeholder="Enter Badge Instagram link"
                />
              </div>
              <div className="col-6">
                <FieldWithError
                  errors={errors}
                  label="Twitter Link"
                  touched={touched}
                  fieldName="badgeTwitter"
                  placeholder="Enter Badge Twitter link"
                />
              </div>
              <div className="col-6">
                <FieldWithError
                  errors={errors}
                  label="Other Link"
                  touched={touched}
                  fieldName="badgeOtherLink"
                  placeholder="Enter Badge Custom link"
                />
              </div>
              <div className="col-6">
                <FieldWithError
                  errors={errors}
                  label="Blurb"
                  touched={touched}
                  fieldName="badgeBlurb"
                  placeholder="Enter Badge Blurb"
                />
              </div>
              <div className="col-6">
                {values.badgeImage && typeof values.badgeImage === 'string' && values.badgeImage !== "" && <div style={{"width": "100px"}}><img width="100%" src={values.badgeImage} alt="current badge" /></div>}
                <FileUpload
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  name="imageUpload"
                  targetField="badgeImage"
                  touched={touched}
                  title="Badge Image"
                />
              </div>
            </div>

            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              Submit
            </button>
            {isSubmitting && (
              <div className="spinner-border m-5" role="status">
                <span className="visually-hidden">Saving...</span>
              </div>
            )}
            {status && <div className="alert alert-warning">{status}</div>}
          </Form>
        );
      }}
    </Formik>
  );
};

export { BadgeForm };
