enum NftStatusEnum {
  CREATED = 1,
  SELLING = 2,
  RESELLING = 3,
  SOLD = 4,
  UNSOLD = 5,
  RESELLING_BUY_NOW = 6,
}

const NftStatusNames: Map<number, string> = new Map([
  [NftStatusEnum.CREATED, 'Created'],
  [NftStatusEnum.SELLING, 'Selling'],
  [NftStatusEnum.RESELLING, 'For Resale (Offer)'],
  [NftStatusEnum.SOLD, 'Sold'],
  [NftStatusEnum.UNSOLD, 'Unsold'],
  [NftStatusEnum.RESELLING_BUY_NOW, 'For Resale (Buy Now)'],
])


export {NftStatusEnum, NftStatusNames}