import React, { ReactNode, useContext, useEffect, useState } from "react";
import { NftSpotStatusEnum } from "_constants";
import { IMessage, INft, ISpot } from "_interfaces";
import { publicNftSpotApiService } from "_services";
import { Helper } from "_common";
import { ModalOffer } from "./ModalOffer";
import { MessengerContext, Web3Context } from "_providers";


const LoginRequiredButton: React.FunctionComponent = () => {

  const messengerContext = useContext(MessengerContext);

  const onClick = () => {
    messengerContext.setMessage({
      title: 'Buy',
      body: 'You need to be logged in to Buy this item'
    } as IMessage);
  }

  return (
    <button className="btn btn-primary text-center" onClick={()=>onClick()}>Buy</button>
  );
}

const Resale: React.FunctionComponent<{
  nft: INft; spot?: ISpot,
  onSuccessOffer: (message: string | React.ReactNode) => void,
  onBuy?: { (nft: INft): Promise<void> },
  onBuySpot?: { (spot: ISpot): Promise<void> }
}> = ({ nft, spot, onSuccessOffer, onBuy, onBuySpot }) => {

  const [selectedSpot, setSelectedSpot] = useState<ISpot>({} as ISpot);
  const [showModalOffer, setShowModalOffer] = useState<boolean>(false);
  const web3Context = useContext(Web3Context);

  interface IForSale {
    ownerEthAddress?: string,
    price?: string,
    action?: ReactNode,
  }

  const [forSale, setForSale] = useState<IForSale[]>([]);

  useEffect(() => {
    const _load = async () => {
      try {
        let forSale: IForSale[] = [];

        if (nft.uid) {
          const spots = await publicNftSpotApiService.getByNftUid(nft.uid);
          let filteredSpots: ISpot[];

          // only show the resale if exclusivity period has elapsed
          if (nft && nft.protectionEnd && Helper.isPublisherExclusive(nft.protectionEnd) === false) { //@TODO: set this back to false after 7/20
            console.log({ spot });
            // if (spot) {
            //   filteredSpots = spots.filter((s: ISpot) => (Helper.isReselling(s) || s.statusId === NftSpotStatusEnum.SOLD) && spot.uid !== s.uid);
            //   setSpots(filteredSpots);
            // } else {
            //   filteredSpots = spots.filter((s: ISpot) => Helper.isReselling(s) || s.statusId === NftSpotStatusEnum.SOLD);
            //   setSpots(filteredSpots);
            // }
            filteredSpots = spots.filter((s: ISpot) => Helper.isReselling(s) || s.statusId === NftSpotStatusEnum.SOLD);

            filteredSpots.filter((spot: ISpot) => !Helper.isMySpot(spot, web3Context.account)).forEach((spot: ISpot) => {
              let action: ReactNode;

              if (onBuySpot && spot.statusId === NftSpotStatusEnum.RESELLING_BUY_NOW) {
                action = web3Context.account ? <button type="button" className="btn btn-primary w-100" onClick={() => onBuySpot(spot)}>Buy</button>
                  : <LoginRequiredButton />
              }

              if (spot && (spot.statusId === NftSpotStatusEnum.RESELLING_OFFER || spot.statusId === NftSpotStatusEnum.SOLD)) {
                action = web3Context.account ? <button type="button" className="btn btn-primary w-100" onClick={() => onOffer(spot)}>Make Offer</button>
                  : <LoginRequiredButton />
                  
              }

              forSale.push({
                ownerEthAddress: Helper.shortenEthAddress(spot.ownerEthAddress),
                price: Helper.formatEth(spot.price),
                action: action
              })

            })
          }

          // add the publisher's unsold shares
          if (nft && nft.voucher && !spot) {
            let action: ReactNode;
            if (onBuy) {
              action = web3Context.account ? <button type="button" className="btn btn-primary w-100" onClick={() => onBuy(nft)}>Buy</button> 
                : <LoginRequiredButton />
              forSale.push({
                ownerEthAddress: Helper.shortenEthAddress((nft.voucher as any).value.owner),
                price: Helper.formatEth(nft.priceFixed),
                action: action
              })
            }

          }
          setForSale(forSale);


        }
      } catch (error: any) {
        console.error("error loading spots", { error });
        throw Error((error as Error).message);
      }
    };
    _load();
    console.log("in useeffect");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nft, spot, web3Context.account]);


  const onOffer = (spot: ISpot) => {
    setSelectedSpot(spot);
    setShowModalOffer(true);
  }

  return (
    <>
      {nft && <ModalOffer handleClose={() => setShowModalOffer(false)} show={showModalOffer} nft={nft} spot={selectedSpot} onSuccess={onSuccessOffer}/>}

      <div className="container g-0 my-5">

        {forSale && forSale.length > 0 ? <>
          <div className="row">
            <div className="col-12">
              <h5>Shares for Sale</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-4 fw-bold">Owner</div>
            <div className="col-4 fw-bold">Price</div>
            <div className="col-4 fw-bold text-center">Action</div>
          </div>

          {forSale.sort((a, b) => { return (a.price as string) > (b.price as string) ? 1 : -1 }).map((i: IForSale, key: number) =>
            <div className="row" key={`rowkey_${key}`}>
              <div className="col-4">{i.ownerEthAddress}</div>
              <div className="col-4">{i.price || "N/A"}</div>
              <div className="col-4 text-center">{i.action}</div>
            </div>)
          }

        </> : <>
          <div className="row"><div className="col">No other shares available</div></div>
        </>}

      </div>

    </>
  );
};

export { Resale };
