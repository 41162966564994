import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { IAccount } from "_interfaces";
import { Role } from "_constants";

import { AccountContext } from "_providers";

export const PrivateRoute: React.FunctionComponent<{
  component: React.FunctionComponent;
  path: string;
  roles: Role[];
  exact?: boolean;
  redirectUrl: string;
}> = ({ component, path, roles, exact, redirectUrl }) => {
  const accountContext = useContext(AccountContext);
  const account: IAccount = accountContext.account;

  // console.log('in private route', {
  //   path,
  //   account: accountContext.account,
  //   roles,
  // })

  const isAllowed: boolean =
    roles.length === 0 ||
    (account === null && roles.length === 0) ||
    (account && account.roleId && roles.indexOf(account.roleId) >= 0) ||
    false;

  console.log('PrivateRoute', {path, isAllowed});
  return isAllowed ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect
      to={{
        pathname: redirectUrl,
        search: `?redir=${path}`,
      }}
    />
  );
};
