import { BrowserView, MobileOnlyView } from "react-device-detect";

const MetamaskPage = () => {
  // return (
  //   <div className="container metamask mt-5">
  //     You need to install Metamask to use your wallet.

  //     <BrowserView>
  //       Download it <a href="https://metamask.io/download/">here</a>.
  //     </BrowserView>

  //     <MobileOnlyView>
  //       <div className="row">
  //         <a href="https://metamask.app.link/dapp/www.devex.mintdesk.co">Open Mintdesk in Metamask App</a>
  //       </div>
  //     </MobileOnlyView>
  //   </div>
  // );

  return (
    <>
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-4">
                <h4 className="card-title mt-5">
                  Connect your wallet.
                </h4>
                <p className="px-5 mt-3">
                  Connect with Metamask. Additional providers will
                  be available in the future.
                </p>
              </div>
              <div className="auth-form">
                <div className="row">
                  <div className="col">

                    <BrowserView>
                      <a href="https://metamask.io/download/" className="card text-center">
                        <div className="card-body">
                          <img src="/images/wallet/1.webp" alt="" width={50} className="mb-3" />
                          <h5 className="mb-0">Download MetaMask</h5>
                        </div>
                      </a>
                    </BrowserView>

                    <MobileOnlyView>
                      <a href="https://metamask.app.link/dapp/www.devex.mintdesk.co" className="card text-center">
                        <div className="card-body">
                          <img src="/images/wallet/1.webp" alt="" width={50} className="mb-3" />
                          <h5 className="mb-0">Open Mintdesk in the MetaMask App</h5>
                        </div>
                      </a>
                    </MobileOnlyView>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { MetamaskPage }