import React, { useContext, useEffect, useState } from "react";
import { customerNftEventsApiService } from "_services";
import { Web3Context } from "_providers";
import { INftEvent } from "_interfaces";
import { NftEventTypeEnumName } from "_constants/NftEventTypeEnum";
import { Helper } from "_common";

const Activity: React.FunctionComponent = () => {

  const web3Context = useContext(Web3Context);
  const [ nftEvents, setNftEvents ] = useState<INftEvent[]>([]);

  useEffect(()=> {

    const _load = async () => {
      if (web3Context.account) {
        const nftEvents = await customerNftEventsApiService.getByEthAddress(web3Context.account);
        setNftEvents(nftEvents);
      }
    }
    _load();
  }, [web3Context.account])

  return (
        <table className="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>NFT</th>
          <th>Action</th>
          <th>Price</th>
        </tr>
        </thead>
        <tbody>
        {nftEvents && nftEvents.sort((a,b) => { return a.creationTimestamp && b.creationTimestamp && a.creationTimestamp < b.creationTimestamp ? 1 : -1}).map((nftEvent: INftEvent, i: number) => 
        <tr key={`__key_event_${i}`}>
          <td>{nftEvent.creationTimestamp}</td>
          <td>{nftEvent.nftId}</td>
          <td>{NftEventTypeEnumName.get(nftEvent.typeId as number)}</td>
          <td>{nftEvent.price ? `${Helper.formatEth(nftEvent.price)}` : ''}</td>
        </tr>)}
        </tbody>
      </table>
  );
};

export { Activity };
