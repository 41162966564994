import React from "react";
import { Helper } from "_common";
import { NftEventTypeEnum, NftEventTypeEnumName } from "_constants/NftEventTypeEnum";
import { INftEvent } from "_interfaces";

const ItemHistory: React.FunctionComponent<{ nftEvents: INftEvent[] }> = ({ nftEvents }) => {
  return (
    <div className="flat-tabs themesflat-tabs">
      <ul className="menu-tab tab-title">
        <li className="item-title active">
          <h4>Trade History</h4>
        </li>
        {/* <li className="item-title">
        <span className="inner">Info</span>
      </li>
      <li className="item-title">
        <span className="inner">Provenance</span>
      </li> */}
      </ul>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Action</th>
              <th>From</th>
              <th>To</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {nftEvents.length > 0 ? (
              <>
                {nftEvents.sort((a, b) => {return (a.creationTimestamp && b.creationTimestamp) && a.creationTimestamp >= b.creationTimestamp ? -1 : 1}).map((e: INftEvent, i: number) => (
                  <tr key={`key_${i}`}>
                    <td>{e.creationTimestamp}</td>
                    <td>{NftEventTypeEnumName.get(e.typeId as NftEventTypeEnum)}</td>
                    <td className="author-infor">{Helper.shortenEthAddress(e.actorEthAddress)}</td>
                    <td className="author-infor">{Helper.shortenEthAddress(e.toEthAddress)}</td>
                    <td>{Helper.formatEth(e.price)}</td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                <tr>
                  <td>No Data</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        {/* <ul className="bid-history-list">
            {nftEvents.length > 0 ? (
              nftEvents.map((e: INftEvent, i: number) => (
                <li key={`__itemkey_${i}`}>
                  <div className="content">
                    <div className="client">
                      <div className="sc-author-box style-2">
                        <div className="author-avatar">
                          <a href="/">
                            <img src={e.actorAvatarUrl} alt="" className="avatar" style={{ maxWidth: "50px" }} />
                          </a>
                          <div className="badge"></div>
                        </div>
                        <div className="author-infor">
                          <div className="name">
                            <h6>
                              <a href="author02.html">{e.username ? e.username : Helper.shortenEthAddress(e.actorEthAddress)}</a>
                            </h6>{" "}
                          </div>
                          <span className="time">{e.creationTimestamp}</span>
                        </div>
                      </div>
                    </div>
                    <div className="price">
                      <h5>{e.price} ETH</h5>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li>No Data</li>
            )}
          </ul> */}
      </div>
    </div>
  );
};

export { ItemHistory };
