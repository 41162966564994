/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


import { Url } from "_constants";
import { Web3Context, AccountContext } from "_providers";
import { useHistory } from "react-router";
import web3 from "_connection/web3";
import { Helper, ModalWaiting } from "_common";

function NaviButtons(web3Context: { account: any; }, connectWalletHandler: React.MouseEventHandler<HTMLButtonElement> | undefined, accountContext: { account: any; }, history: any, logout: React.MouseEventHandler<HTMLButtonElement> | undefined) {
  return <>
    {!web3Context.account && (
      <button type="button" className="btn btn-primary mx-2" onClick={connectWalletHandler}>
        Connect
      </button>
    )}

    {web3Context.account && (
      <>
        <div className="mx-2">Wallet {Helper.shortenEthAddress(web3Context.account)}</div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => history.push(Url.ACCOUNT_SECTION_PROFILE)}
        >
          Profile
        </button>
      </>
    )}


    {!accountContext.account && !web3Context.account && (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => history.push(Url.CUSTOMER_LOGIN)}
      >
        Login
      </button>
    )}

    {accountContext.account && !web3Context.account && (
      <button type="button" className="btn btn-primary" onClick={logout}>
        Logout
      </button>
    )}
  </>
}


const HeaderLanding = () => {


  const [isToggled, setToggled] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const toggleTrueFalse = () => setToggled(!isToggled);

  const accountContext = useContext(AccountContext);
  const history = useHistory();
  const web3Context = useContext(Web3Context);


  const connectWalletHandler = async () => {
    if (!web3) {
      history.push(Url.CUSTOMER_METAMASK_PAGE);
    } else {
      try {
        setShowModal(true);
        await web3Context.connectWallet(web3);
        setShowModal(false);
      } catch (error) {
        setShowModal(false);
        console.error('error connecting wallet', error);

      }
    }
  };

  const logout = () => {
    accountContext.logout(() => { history.push(Url.DEFAULT_HOME) });
  }

  return (
    <>
      <ModalWaiting show={showModal} />
      <div className="header landing">
        <div className="container">
          <div className="row gx-0">
            <div className="col-xl-12">
              <div className="navigation">


                <Navbar bg="light" expand="lg">
                  <Container fluid>
                    <Navbar.Brand href="/">
                      <img src="/images/logo.png" alt="" className="logo-primary" />
                      <img src="/images/logow.png" alt="" className="logo-white" />
                    </Navbar.Brand>


                    <Navbar.Toggle aria-controls="navbarScroll" style={{"position":"unset"}}/>
                    <Navbar.Collapse id="navbarScroll">
                      <Nav
                        className="mx-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                      >
                        <Nav.Link className="fw-bold" onClick={() => history.push(Url.DEFAULT_HOME)}>Home</Nav.Link>
                        <Nav.Link className="fw-bold" onClick={() => history.push(Url.CUSTOMER_COLLECTIONS)}>Collection</Nav.Link>
                      </Nav>

                      {NaviButtons(web3Context, connectWalletHandler, accountContext, history, logout)}

                    </Navbar.Collapse>
                  </Container>
                </Navbar>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HeaderLanding;
