import React from "react";
import { BaseForm, FieldWithError, FileUpload } from "_common";
import { IArticle } from "_interfaces";
import { customerArticleManagementApiService as apiService, customerStorageService } from "_services";
import { Validator, validationMap } from "_components/_schemas";

import { Slots } from "../Nft/Slots";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IArticle) => void;
  initialValues: any;
  onCancelClick: () => void;
}

const ArticleForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick }: IAppProps) => {
  const beforeSubmit = async (data: any, actions: any) => {
    try {
      data.image = await customerStorageService.uploadImage(data.image);
    } catch (error: any) {
      throw Error(error.message);
    }
  };

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ARTICLE_FORM)}
      beforeSubmit={beforeSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => {
        return (
          <>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <div className="container-md my-2">
              <FieldWithError
                errors={errors}
                touched={touched}
                fieldName="collectionId"
                placeholder="Collection ID"
                label="Collection ID"
              />

              <FieldWithError
                errors={errors}
                touched={touched}
                fieldName="title"
                placeholder="Article Title"
                label="Article Title Description"
              />

              <FieldWithError
                errors={errors}
                touched={touched}
                fieldName="description"
                placeholder="Article Description"
                label="Article Description"
              />

              <FieldWithError errors={errors} touched={touched} fieldName="url" placeholder="URL" label="URL" />

              <div className="form-group">
                <FileUpload
                  name="image-upload"
                  errors={errors}
                  setFieldValue={setFieldValue}
                  title="Article Image"
                  touched={touched}
                  values={values}
                  targetField="image"
                />
              </div>

              <Slots />
            </div>
          </>
        );
      }}
    </BaseForm>
  );
};

export { ArticleForm };
