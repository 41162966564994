import { ArticleSlot } from "mdlib/interface/article-slot";
import React from "react";
import { useHistory } from "react-router-dom";
import { Helper } from "_common";
import { NftPriceTypeEnum, Url } from "_constants";
import { IArticle, INft } from "_interfaces";

interface IArticleSlot extends ArticleSlot {
  id: number;
  uid: number;
}

const SiblingSlots: React.FunctionComponent<{ article: IArticle; nft: INft }> = ({ article, nft }) => {
  const history = useHistory();

  const siblings = article.slots ? article.slots.filter((slot: ArticleSlot) => (slot as IArticleSlot).uid !== nft.uid) : [];

  const priceType: NftPriceTypeEnum | undefined =
    siblings.length > 0 ? (siblings[0] as INft).priceTypeId : NftPriceTypeEnum.FIXED;

  const onBuy = (nft: INft) => {
    history.push(`${Url.CUSTOMER_NFT_BUY_PATH}/${article.uid}/${nft.uid}`);
  };

  const onBid = (nft: INft) => {
    history.push(`${Url.CUSTOMER_NFT_BID_PATH}/${article.uid}/${nft.uid}`);
  };

  return (
    <>
      {siblings && siblings.length > 0 && (
        <>
          <div className="slot section-padding">
            <div className="container">
              <div className="row">
                <h3>Slots</h3>

                {siblings.map((nft: INft, i: number) => (
                  <div className="col-md-4" key={`row__${i}`}>
                    <div className="slot-stats">
                      <h4>Slot {(nft.position as number) + 1}</h4>

                      {priceType === NftPriceTypeEnum.AUCTION && (
                        <>
                          <h5>Current Bid: </h5>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              onBid(nft);
                            }}
                          >
                            Bid
                          </button>
                        </>
                      )}

                      {priceType === NftPriceTypeEnum.FIXED && (
                        <>
                          <h5>Price: {nft.priceFixed} ETH</h5>
                          <h5>Total Shares: {nft.amount}</h5>
                          <h5>Available: {Helper.remainingShares(nft)}</h5>
                          {/* <pre>{JSON.stringify(nft, null, 2)}</pre> */}
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              onBuy(nft);
                            }}
                          >
                            Buy
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { SiblingSlots };
