import { Formik, Form } from "formik";
import React, { useContext } from "react";
import { FieldWithError } from "_common";
import { validationMap, Validator } from "_components/_schemas";
import { INft } from "_interfaces";
import { MessengerContext } from "_providers";
import { nftService } from "_services";

const BidForm: React.FunctionComponent<{nft: INft, onSuccess: any, closeModal: any}> = ({nft, onSuccess, closeModal}) => {
  const initialValues = {
    bid: 0,
  };

  const validationSchema = validationMap.get(Validator.ARTICLE_BID_FORM);
  const messengerContext = useContext(MessengerContext);

  const onSubmit = async (data: any, actions: any) => {
    const { setSubmitting, setStatus } = actions;
    
    try {
      const bidResult = await nftService.bid(nft, data.bid, setStatus);
      closeModal();
      onSuccess("Bid Accepted");
      console.log({bidResult});
    } catch (error: any) {
      messengerContext.setMessage({title: 'Bid Error', body: `${error.message}`})
    }
    setSubmitting(false);

  };

  return (
    <div className="container">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors, status, touched, isSubmitting, setSubmitting }) => {
          return (
            <Form className="form">
              <FieldWithError errors={errors} touched={touched} fieldName="bid" label="Enter Bid" placeholder="0" />

              {status && <div className="alert alert-danger my-2">{status}</div>}

              {isSubmitting && <div className="spinner-border"></div>}

              <button disabled={isSubmitting} type="submit" className="btn btn-primary my-2">
                Submit Bid
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { BidForm };
