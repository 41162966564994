import React from "react";
import { FieldWithError, StateField } from "_common";
import { ErrorMessage } from "formik";

const AddressInfo: React.FunctionComponent<{ errors: any; touched: any; readOnly?: boolean }> = ({
  errors,
  touched,
  readOnly,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="address1"
            placeholder="Address Line 1"
            label="Address Line 1"
            readOnly={readOnly}
          />
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="address2"
            placeholder="Address Line 2"
            label="Address Line 2 (Optional)"
            readOnly={readOnly}
          />
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="city"
            placeholder="City"
            label="City"
            readOnly={readOnly}
          />
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <div className="form-group">
            <label>State</label>
            <StateField
              name="state"
              disabled={readOnly}
              className={"form-control" + (errors && errors["state"] && touched && touched["state"] ? " is-invalid" : "")}
            />
            {errors && <ErrorMessage name="state" component="div" className="invalid-feedback" />}
          </div>
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="zipcode"
            placeholder="Zip Code"
            label="Zip Code"
            readOnly={readOnly}
          />
        </div>

        <div className="col-xxl-6 col-xl-6 col-lg-6 mb-3">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="phoneNumber"
            placeholder="Phone Number"
            label="Phone Number"
            readOnly={readOnly}
          />
        </div>
      </div>
    </>
  );
};

export { AddressInfo };
