import React from "react";
import { Field } from "formik";
import { ResaleTypeNames } from "_constants";

const ResaleTypeField = ({ ...rest }) => {
  return (
    <Field {...rest} as="select">
      {
        Array.from(ResaleTypeNames.keys()).map( (key: number, i: number) =>
          <option key={`code_${i}`} value={key}>{ResaleTypeNames.get(key)}</option>
        )
      }

    </Field>
  );
};

export { ResaleTypeField };
