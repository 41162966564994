import React, { useContext } from "react";
import { NftSpotStatusEnum, NftStatusEnum } from "_constants";
import { IArticle, INft, ISpot } from "_interfaces";
import { Web3Context, MessengerContext } from "_providers";
import { nftService } from "_services";
import { CountdownTimer } from "./CountdownTimer";
import { ItemDetailLayout } from "./ItemDetailLayout";
import { Helper } from "_common";

interface IAppProps {
  article: IArticle;
  onBid: any;
  nft: INft;
  onOffer: any;
  onEdit: any;
  onBuySpot: { (spot: ISpot): Promise<void>};
  bidHistory: any;
  spot?: ISpot;
  refresh: () => void;
  onSuccessfulOffer: (message: string | React.ReactNode) => void,
}

const BidItemDetail: React.FunctionComponent<IAppProps> = ({
  article,
  onBid,
  nft,
  onOffer,
  onEdit,
  onBuySpot,
  bidHistory: nftHistory,
  spot,
  refresh,
  onSuccessfulOffer
}) => {
  const web3Context = useContext(Web3Context);
  const messengerContext = useContext(MessengerContext);

  const onAccept = async (nft: INft) => {
    try {
      const result = await nftService.finalizeAuction(nft, true);
      refresh();
      messengerContext.setMessage({ title: "Finalize Auction", body: "Auction successfully finalized." });
      console.log(result);
    } catch (error: any) {
      console.error({ error });
      messengerContext.setMessage({ title: "Error Occurred", body: `${error.message}` });
    }
  };

  const onCancel = async (nft: INft) => {
    try {
      await nftService.finalizeAuction(nft, false);
      refresh();
      messengerContext.setMessage({ title: "Cancel Auction", body: "Auction successfully canceled." });
    } catch (error: any) {
      console.error({ error });
      messengerContext.setMessage({ title: "Error Occurred", body: `${error.message}` });
    }
  };

  const isBiddable = (nft: INft): boolean => {
    const currentDateTime = new Date().getTime();
    return (
      new Date(`${nft.auctionStart} UTC`).getTime() < currentDateTime &&
      new Date(`${nft.auctionEnd} UTC`).getTime() > currentDateTime
    );
  };

  const onMint = async (nft: INft) => {
    try {
      const result = await nftService.finalizeAuction(nft, true);
      refresh();
      console.log(result);
      messengerContext.setMessage({ title: "Finalize Auction", body: "Auction successfully minted." });
    } catch (error: any) {
      console.error("onMint error", { error });
      messengerContext.setMessage({
        title: "Error Minting",
        body: `Could not mint. See error log. ${JSON.stringify(error, null, 2)}`,
      });
    }
  };

  const getWeb3Account = () => {
    return web3Context.account ? web3Context.account.toUpperCase() : "";
  };

  return (
    <ItemDetailLayout article={article} nft={nft} itemHistory={nftHistory} spot={spot} onBuySpot={onBuySpot} onSuccessfulOffer={onSuccessfulOffer}>
      <div className="meta-item-details style2">
        {!spot && (
          <>
            {(nft.statusId !== NftStatusEnum.SOLD || nft.lastBidder?.toUpperCase() !== getWeb3Account()) && (
              <div className="item meta-price">
                <span className="heading">Current Bid</span>
                <div className="price">
                  <div className="price-box">
                    <h5 className="mb-0">{nft.lastBid ? Helper.formatEth(nft.lastBid) : "No Bid Yet"}</h5>
                  </div>
                </div>
              </div>
            )}

            {nft && new Date(`${nft.auctionStart} UTC`).getTime() > new Date().getTime() && (
              <>
                <CountdownTimer
                  endDateTime={nft.auctionStart}
                  startDateTime={Date()}
                  endedMessage="Active"
                  activeMessage="Starts In"
                />
              </>
            )}

            {nft && new Date(`${nft.auctionStart} UTC`).getTime() <= new Date().getTime() && (
              <CountdownTimer
                endDateTime={nft.auctionEnd}
                startDateTime={Date()}
                endedMessage="Auction Has Ended"
                activeMessage="Ends In"
              />
            )}
          </>
        )}
      </div>

      {nft.voucher && getWeb3Account() === (nft.voucher as any).value.owner.toUpperCase() ? (
        <>
          {new Date().getTime() < new Date(`${nft.auctionEnd} UTC`).getTime() ? (
            <div>You own this item. Auction is ongoing.</div>
          ) : (
            <>
              {nft.lastBidder !== null && nft.statusId === NftStatusEnum.SELLING && (
                <button onClick={() => onAccept(nft)} className="btn btn-primary">
                  Finalize Auction
                </button>
              )}

              {nft.statusId === NftStatusEnum.SELLING && !spot && (
                <button onClick={() => onCancel(nft)} className="btn btn-primary">
                  Cancel Auction
                </button>
              )}

              {nft.statusId === NftStatusEnum.UNSOLD && (
                <>
                  <div>UNSOLD</div>
                  <button className="btn btn-primary" onClick={() => onEdit(nft)}>
                    Edit
                  </button>
                </>
              )}

              {nft.statusId === NftStatusEnum.SOLD && <>SOLD to {Helper.shortenEthAddress(nft.lastBidder)}</>}
            </>
          )}
        </>
      ) : (
        <>
          {/* <pre>
            {JSON.stringify(
              {
                statusId: nft.statusId,
                auctionEnd: new Date(`${nft.auctionEnd} UTC`).toLocaleDateString(),
                now: new Date().toLocaleDateString(),
                lastBidder: nft.lastBidder,
                account: web3Context.account,
              },
              null,
              2
            )}
          </pre> */}

          {/* <pre>{JSON.stringify({nft, spot}, null, 2)}</pre>*/}

          {isBiddable(nft) ? (
            <button onClick={() => onBid(nft)} className="btn btn-primary">
              Bid
            </button>
          ) : (
            <>
              {/* <pre>{JSON.stringify({ spot, web3Context, myspot: Helper.isMySpot(spot, web3Context.account) }, null, 2)}</pre> */}
              {nft.statusId === NftStatusEnum.SELLING &&
                nft.priceReserve &&
                nft.lastBid &&
                nft.priceReserve <= nft.lastBid &&
                Helper.isMySpot(spot, web3Context.account) &&
                new Date(`${nft.auctionEnd} UTC`).getTime() < new Date().getTime() && (
                  <button onClick={() => onMint(nft)} className="btn btn-primary">
                    Mint
                  </button>
                )}

              {nft.statusId === NftStatusEnum.SOLD &&
                Helper.isMySpot(spot, web3Context.account) &&
                new Date(`${nft.auctionEnd} UTC`).getTime() < new Date().getTime() && (
                  <h5>Owned</h5>
                  // <button onClick={() => onSell(nft)} className="btn btn-primary">
                  //   Sell
                  // </button>
                )}

              {/* not minted. bidding on Slot */}
              {nft.statusId === NftStatusEnum.SOLD && !Helper.isMySpot(spot, web3Context.account) && (
                <button onClick={() => onOffer(nft)} className="btn btn-primary">
                  Make an Offer To Buy
                </button>
              )}

              {/* <pre>{JSON.stringify({spot, account: web3Context.account}, null, 2)}</pre> */}

              {/* minted. bidding on spot */}
              {spot && Helper.isMySpot(spot, web3Context.account) && <div className="fw-bold">You own this share</div>}

              {spot && !Helper.isMySpot(spot, web3Context.account) && (
                <>
                  {/* {!web3Context.account && <div className="fw-bold">You must be logged in to make an offer</div>} */}

                  {web3Context.account && <>
                    {spot.statusId === NftSpotStatusEnum.RESELLING_OFFER &&
                      <button onClick={() => onOffer(nft)} className="btn btn-primary">
                      Make an offer
                    </button>                      
                    }
                  </>}

                  {/* {web3Context.account && <>
                    {spot.statusId === NftSpotStatusEnum.RESELLING_BUY_NOW &&
                      <button onClick={() => onBuySpot(spot)} className="btn btn-primary">
                      Buy Now
                    </button>                      
                    }
                  </>} */}

                </>
              )}
            </>
          )}
        </>
      )}

      {/* <a href="#" data-toggle="modal" data-target="#popup_bid" className="sc-button loadmore style bag fl-button pri-3">
        <span>Place a bid</span>
      </a> */}
    </ItemDetailLayout>
  );
};

export { BidItemDetail };
