enum LayoutTypeEnum {
  ARTICLE_PLAIN           = 1, // all positions
  PUBLISHER_PLAIN         = 2, // rotate all articles
  SUPPORTER_CARD          = 3, // return one badge
}


const LayoutTypeNames: Map<number, string> = new Map([
  [LayoutTypeEnum.ARTICLE_PLAIN, 'Article - Targeted'],
  [LayoutTypeEnum.PUBLISHER_PLAIN, 'Targeted'],
  [LayoutTypeEnum.SUPPORTER_CARD, 'Supporter Card'],
])

export { LayoutTypeEnum, LayoutTypeNames }