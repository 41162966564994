import React from 'react';

const Faq: React.FunctionComponent = () => {
  return (
    <div className="faq">
      FAQ Placeholder
    </div>
  );
};

export {Faq}