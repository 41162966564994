import React from "react";
import { Modal } from "react-bootstrap";
import { INftSpot } from "_interfaces";
import { BadgeForm } from "./BadgeForm";

const ModalBadge: React.FunctionComponent<{ show: any; handleClose: any; nftSpot: INftSpot; onEdit: (data: any, spotId: number) => void }> = ({
  show,
  handleClose,
  nftSpot,
  onEdit,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Badge Properties</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <BadgeForm nftSpot={nftSpot} closeModal={handleClose} onEdit={onEdit} />
      </Modal.Body>
    </Modal>
  );
};

export { ModalBadge };
