import { config } from "_config";
import { AuthenticationService, customerAuthenticationService } from "./AuthenticationService";

class SyncNftApiService {

  constructor (protected apiBaseUrl: string,
    protected authService: AuthenticationService
    ) {}
  
  async forceSync () {

    const url = `${this.apiBaseUrl}/mint`;
    const response = await window.fetch(url, {
      method: 'GET',
      mode: 'no-cors',
    })

    try {
      await response.text();
    } catch (error: any) {
      throw Error(error.message);
    }
    
    // try {
    //   const url = `${this.apiBaseUrl}/mint`;
    //   const options: AxiosRequestConfig = {
    //     headers: {
    //       'Content-Type': 'text/html',
    //     },
    //     responseType: 'text',
    //   };      
    //   const response = await axios.get(url, options);
    //   if (response.data.error) {
    //     throw Error(response.data.error.message);
    //   }
    //   return response.data;
    // } catch (error: any) {
    //   throw Error((error as Error).message);
    // }
  }
}

const apiBaseUrl = `${config.apiDomain}`;
const syncNftApiService = new SyncNftApiService(apiBaseUrl, customerAuthenticationService);
export { syncNftApiService }