import React from "react";
import { IArticle, INft, INftEvent, ISpot } from "_interfaces";
import { ItemDetailLayout } from "./ItemDetailLayout";


const BuyItemDetail: React.FunctionComponent<{ article: IArticle; 
  onBuy: { (nft: INft): Promise<void>},
  onBuySpot?: {(spot: ISpot): Promise<void> },
  onSuccessfulOffer: (message:string | React.ReactNode) => void,
  nft: INft, itemHistory: INftEvent[] }> = 
  ({ article, onBuy, onBuySpot, onSuccessfulOffer, nft, itemHistory }) => {

  return (
    <ItemDetailLayout article={article} nft={nft} itemHistory={itemHistory} onBuy={onBuy} onBuySpot={onBuySpot} onSuccessfulOffer={onSuccessfulOffer}>
      {/* {nft && nft.protectionEnd && Helper.isPublisherExclusive(nft.protectionEnd) &&
        <>
          <div className="meta-item-details style2">
            <div className="item meta-price">
              <h5>Total Shares: {nft.amount}</h5>
            </div>

            <div className="item meta-price">
              <h5>Available: {Helper.remainingShares(nft)}</h5>
            </div>

            <div className="item meta-price">
              <span className="heading">Price</span>
              <div className="price">
                <div className="price-box">
                  <h5 className="mb-0">{nft.priceFixed} ETH</h5>
                </div>
              </div>
            </div>
          </div>

          <button onClick={() => onBuy(nft)} className="btn btn-primary">
            Buy
          </button>
        </>
      } */}


    </ItemDetailLayout>
  );
};

export { BuyItemDetail };
