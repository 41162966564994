
import { config } from '_config';
import { ArticleLazyNFT  } from "mdlib/contracts/AcrtileLazyNFT";
import { CommonUtil } from "mdlib/util/common.util"

// import { ArticleLazyNFT } from 'library.mintdesk.co/src/contracts/AcrtileLazyNFT';
// ../library/src/contracts/AcrtileLazyNFT.ts 8:7
// Module parse failed: Unexpected token (8:7)
// File was processed with these loaders:
//  * ./node_modules/react-scripts/node_modules/@pmmmwh/react-refresh-webpack-plugin/loader/index.js
// You may need an additional loader to handle the result of these loaders.
// | import {CommonUtil} from "../util/common.util";
// | 
// > export interface ArticleSlot {
// |     pos: number; //starts from 0
// |     amount: number; //number of items for slot


import { BigNumber, ethers } from "ethers";
import { IArticle, INft, INftSpot, ISpot, ISpotOffer } from "_interfaces";
import { customerStorageService } from "./StorageService";
import { CustomerApiService, CustomerNftManagementService } from '_services/customer';
import articleLazyNFT from "abis/src/mdlib/contracts/ArticleLazyNFT.sol/ArticleLazyNFT.json";
import { syncNftApiService } from '_services/common/SyncNftApiService';
import { NftSpotStatusEnum, SpotOfferActionEnum } from '_constants';
import { Voucher } from 'mdlib';

enum NFTErrorEnum {
  ERR_AUCTION_IS_NOT_ACTIVE       = "ERR_AUCTION_IS_NOT_ACTIVE",
  ERR_AUCTION_IS_ACTIVE           = "ERR_AUCTION_IS_ACTIVE",
  ERR_EXPIRED_VOUCHER             = "ERR_EXPIRED_VOUCHER",
  ERR_INVALID_AUCTION_SETUP       = "ERR_INVALID_AUCTION_SETUP",
  ERR_INVALID_CALLER              = "ERR_INVALID_CALLER",
  ERR_INVALID_CALL                = "ERR_INVALID_CALL",
  ERR_INVALID_OWNER               = "ERR_INVALID_OWNER",
  ERR_INVALID_PRICE_TYPE          = "ERR_INVALID_PRICE_TYPE",
  ERR_INVALID_TOKEN               = "ERR_INVALID_TOKEN",
  ERR_INVALID_SLOT                = "ERR_INVALID_SLOT",
  ERR_INVALID_SLOT_AMOUNT         = "ERR_INVALID_SLOT_AMOUNT",
  ERR_LOW_BID                     = "ERR_LOW_BID"
}

const NFTErrorEnumName: Map<string, string> = new Map([
  [NFTErrorEnum.ERR_AUCTION_IS_NOT_ACTIVE, 'Auction is not active'],
  [NFTErrorEnum.ERR_AUCTION_IS_ACTIVE, 'Auction is active'],
  [NFTErrorEnum.ERR_EXPIRED_VOUCHER, 'Voucher has expired'],
  [NFTErrorEnum.ERR_INVALID_AUCTION_SETUP, 'Invalid Auction Setup'],
  [NFTErrorEnum.ERR_INVALID_CALLER, 'Invalid caller'],
  [NFTErrorEnum.ERR_INVALID_CALL, 'Invalid call'],
  [NFTErrorEnum.ERR_INVALID_OWNER, 'Invalid Owner'],
  [NFTErrorEnum.ERR_INVALID_PRICE_TYPE, 'Invalid Price Type'],
  [NFTErrorEnum.ERR_INVALID_TOKEN, 'Invalid Token'],
  [NFTErrorEnum.ERR_INVALID_SLOT, 'Invalid Slot'],
  [NFTErrorEnum.ERR_INVALID_SLOT_AMOUNT, 'Invalid Slot Amount'],
  [NFTErrorEnum.ERR_LOW_BID, 'Bid does not meet the minimum. Try increasing your bid.'],
]);

class NFTService {

  constructor(
    protected contractAddress: string,
    protected contractId: number,
    protected customerArticleManagementApiService: CustomerApiService<IArticle>,
    protected customerNftManagementService: CustomerNftManagementService,
    protected customerSpotManagementApiService: CustomerApiService<INftSpot>,

  ) {}

  async uploadJsonMeta (data: any, imagePath: string) {
    const slots = await Promise.all(
      data.slots.map(async (slot: any, index: number) => {
        // create the meta data for each slot
        const metadata = {
          title: "Asset Metadata",
          type: "object",
          properties: {
            name: {
              type: "string",
              description: data.title,
            },
            description: {
              type: "string",
              description: data.description,
            },
            image: {
              type: "string",
              description: imagePath,
            },
            url: {
              type: "string",
              description: data.url,
            },
            slotPosition: {
              type: "number",
              description: slot.pos,
            },
          },
        };

        // upload json
        try {
          const jsonPath = await customerStorageService.uploadJson(metadata);
          //{pos:0,uri:'uri',priceType:1,priceFixed:1,priceStart:0,priceReserve:0,auctionStart:0,auctionEnd:0},
          slot.uri = jsonPath;
          slot.pos = parseInt(slot.pos);
          slot.amount = parseInt(slot.amount);
          slot.auctionStart = parseInt(slot.auctionStart);
          slot.auctionEnd = parseInt(slot.auctionEnd);
          console.log({ slot });
          return slot;
        } catch (error: any) {
          console.error(error.message);
          throw Error(error);
        }
      })
    );

    return slots;
  };


  async updateVoucher (data: any, setStatus: any, nftUID: number, feePct: number) {
    try {

      // get voucher
      const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any");; // @TODO: use the Web3Context to get the provider
      await provider.send("eth_requestAccounts", []);

      const signer = provider.getSigner();
      const contractAddress = this.contractAddress; //@TODO: hard-coded temporarily for testing. this should be retrieved from the db contract table.

      try {
        console.log("slot passed to createVoucher", { slot: data.slots });
        const voucher = await ArticleLazyNFT.createVoucher(signer, data.url, feePct, contractAddress, data.slots);
        console.log("createVoucher (update) result:", { voucher });

        const result = await this.customerNftManagementService.updateVoucher(voucher, nftUID);
          console.log('after update', { result });
          setStatus("Successfully updated article");

      } catch (error: any) {
        console.log(error);
        if (error.code && error.code === 4001) {
          throw Error('Signature Denied. Update Canceled.');
        } else {
          throw Error(error.message);
        }
      }
    } catch (error: any) {
      console.error(error.message);
      setStatus(`error creating voucher: ${error.message}`);
      throw Error(error.message);
    }    
  }


  // static async createVoucherNftSpot(
  //   signer: providers.JsonRpcSigner,
  //   contractAddress: string,
  //   //buyer: string,
  //   tokenId: number,
  //   spotUids: number[],
  //   price: number,
  //   feePct: number,
  //   expireTimestamp: number,

  async createVoucherNftSpot (tokenId: number,  price: number,  feePct: number, spotUid: number) {
    const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any");; // @TODO: use the Web3Context to get the provider
    await provider.send("eth_requestAccounts", []);

    const signer = provider.getSigner();
    const contractAddress = this.contractAddress; 
    const expire = Math.round((new Date().getTime())/1000)+86400;

    try {
      console.log('createVoucherNftSpot params', {
        signer, contractAddress, tokenId, spotUid: [spotUid], price, feePct,  expire        
      } )
      const voucher = await ArticleLazyNFT.createVoucherNftSpot(signer, contractAddress, tokenId, [spotUid], price, feePct,  expire);
      console.log("voucher result:", { voucher });

      // save to db
      const postData: INftSpot = {
            statusId: NftSpotStatusEnum.RESELLING_BUY_NOW,
            voucher: voucher,
        }

      const result = await this.customerSpotManagementApiService.update(postData, spotUid)
      console.log('createVoucherNftSpot result', { result });
    } catch (error: any) {
      console.error('createVoucherNftSpot error', {error});
      throw Error(error.message);
    }

  }


  async createVoucher (data: any, setStatus: any, feePct: number ) {
    try {
      // upload image
      const imagePath = await customerStorageService.uploadImage(data.image);

      // upload and get the slots
      console.log('data before upload', {data});
      const slots = await this.uploadJsonMeta(data, imagePath);
      console.log('data after upload', {data});
      // get voucher
      console.log("getting voucher");

      const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any");; // @TODO: use the Web3Context to get the provider
      await provider.send("eth_requestAccounts", []);

      const signer = provider.getSigner();
      const contractAddress = this.contractAddress; //@TODO: hard-coded temporarily for testing. this should be retrieved from the db contract table.

      console.log("params", {
        signer,
        url: data.url,
        feePct,
        contractAddress,
        slots,
      });

      try {
        console.log("about to call createVoucher with contractAddress", { contractAddress });
        console.log("slots passed to createVoucher", { slots });
        const voucher = await ArticleLazyNFT.createVoucher(signer, data.url, feePct, contractAddress, slots);
        console.log("voucher result:", { voucher });

        // save to db
        const postData: IArticle = {
          collectionUid: data.collectionUid,
          title: data.title,
          description: data.description,
          url: data.url,
          image: imagePath,
          contractId: config.contractId,
          voucher: voucher,
        };

        const result = await this.customerArticleManagementApiService.create(postData);
        console.log({ result });
        setStatus("Successfully created article");
      } catch (error: any) {
        throw Error(error.message);
      }
    } catch (error: any) {
      console.error(error.message);
      setStatus(`error creating article: ${error.message}`);
      throw Error(error);
    }
  }


  async makeSpotOffer(nft: INft,  amountOffered: number,  feePct: number, setStatus: any, spot?: ISpot | ISpotOffer) {
    const fee = CommonUtil.pctToFeeContract(feePct);

    console.log("makeSpotOffer", { nft, spot, amountOffered, fee });

    try {

      // ethers Contract
      const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any"); //@TODO: get from Context
      const contract = new ethers.Contract(this.contractAddress, articleLazyNFT.abi, provider.getSigner());

      const overrides = {
        value: ethers.utils.parseEther(amountOffered.toString()),
      };

      // contract method params
      const tokenId = nft.tokenId;
      const spotUid = spot?.uid;
      const address = spot?.ownerEthAddress; // the current owner;
      const expireTimeStamp = new Date();
      expireTimeStamp.setDate(expireTimeStamp.getDate() + 7); //@TODO: make 7 (days) configurable

      console.log('before contract.makeSpotOffer',{
        contractAddress: this.contractAddress,
        tokenId,
        spotUid,
        spotOwner: address,
        fee,
        expireTimeStamp: Math.floor(expireTimeStamp.getTime() / 1000),
        overrides,
      });

      // contract method:
      // function makeSpotOffer(uint256 tokenId, uint256 spotUid, address spotOwner, uint16 fee, uint256 expireTimestamp) public payable {
      let tx = await contract.makeSpotOffer(tokenId, spotUid, address, fee, Math.floor(expireTimeStamp.getTime() / 1000), overrides);
      console.log("tx after makeSpotOffer()", { tx });
      const txResult = await tx.wait();
      console.log("after makeSpotOffer tx.wait", {txResult});

      const eventMakeSpotOffer = txResult.events[0];

      console.log('after bid', {eventMakeSpotOffer});
      setStatus(`Thank you for your offer`);

    } catch (error: any) {
      console.error('makeSpotOffer error', {error});
      if (error.code && error.code === 4001) {
        throw Error(error.message);
      }
      
      if ( error.data && error.data.data.reason ) {
        const message = NFTErrorEnumName.get((error as any).data.data.reason) || (error as any).data.data.reason;
        throw Error(message);
      } else {
        throw Error('Could not submit offer due to unexpected error. See error log for more details.');
      }
    }      
  }

  async finishSpotOffer(spotOffer: ISpotOffer, action: SpotOfferActionEnum, setStatus: any) {
    console.log("finishSpotOffer", { spotOffer, action });
    try {

      if (!spotOffer.price) {
        throw Error('Price cannot be empty');
      }

      // ethers Contract
      const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any"); //@TODO: get from Context
      const contract = new ethers.Contract(this.contractAddress, articleLazyNFT.abi, provider.getSigner());

      // contract method params
      const tokenId = spotOffer.nftTokenId;
      const spotUid = spotOffer.spotUid
      const price = ethers.utils.parseEther(spotOffer.price.toString());
      const accept: boolean = action === SpotOfferActionEnum.ACCEPT; 
      //const address: string = action === SpotOfferActionEnum.REJECT ? spotOffer.ownerEthAddress : spotOffer.bidderEthAddress; 
      const address = spotOffer.bidderEthAddress; 
      console.log('params for finishSpotOffers', {
        tokenId, spotUid, address, price, accept        
      })

      // contract method:
      // function finishSpotOffer(uint256 tokenId, uint256 spotUid, address bidder, uint256 price, bool accept) public {

      let tx = await contract.finishSpotOffer(tokenId, spotUid, address, price, accept);
      console.log("tx after finishSpotOffer()", { tx });
      const txResult = await tx.wait();
      console.log("after finishSpotOffer tx.wait", {txResult});

      const eventFinishSpotOffer = txResult.events[0];

      console.log('after bid', {eventFinishSpotOffer});
      setStatus(`Offer was ${accept ? 'Accepted' : 'Rejected'}`);

    } catch (error: any) {
      console.error('finishSpotOffers error', {error});
      if (error.code && error.code === 4001) {
        throw Error(error.message);
      }
      
      if ( error.data && error.data.data.reason ) {
        const message = NFTErrorEnumName.get((error as any).data.data.reason) || (error as any).data.data.reason;
        throw Error(message);
      } else {
        throw Error('Could not process offer due to unexpected error. See error log for more details.');
      }
    }      
  }
  
  // const redeemNftTrans = contractBuyer.redeemNft(voucherNft.signature, voucherNft.value, {value: price1d2});
  // //make sure event is emitted
  // await expect(redeemNftTrans).to.emit(contractBuyer, 'Sale')
  //         .withArgs(await signerOwner.getAddress(), wallet10.address, 1, spots, price1d2);
  // //contract collects amount
  // await expect(()=>redeemNftTrans).to.changeEtherBalances(
  //         [wallet10, contractBuyer],
  //         [utils.parseEther("-1.2"), price1d2]
  // );
  // //verify new owner
  // expect(await contractBuyer.balanceOf(wallet10.address, 1)).to.equal(1);

  // buy resale spot
  async redeemSpot(spot: ISpot, setStatus: any) {
    console.log('redeemSpot (BUY NOW)', {spot}, JSON.parse((spot as any).voucher));

    try {

      if (!spot.price || spot.price === null) {
        throw Error('could not get the price');
      }

      // ethers Contract
      const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any"); //@TODO: get from Context
      const contract = new ethers.Contract(this.contractAddress, articleLazyNFT.abi, provider.getSigner());


      const overrides = {
        value: ethers.utils.parseEther(spot.price.toString()),
      };

      const voucherJson: Voucher = JSON.parse((spot as any).voucher);
      const signature = voucherJson.signature;
      const voucher = voucherJson.value;

      console.log('before contract.redeemNft',{
        contract,
        provider,
        signature,
        voucher: (spot.voucher as any).value,
        overrides
      });
      let tx = await contract.redeemNft(signature, voucher, overrides);
      console.log("after redeemNft()", { tx });
      const txResult = await tx.wait();
      console.log("after tx.redeemNft", {txResult});

      setStatus(`Thank you for your purchase`);

    } catch (error) {
      console.error('redeem error', {error});
      setStatus(`Could not buy. ${(error as Error).message}`);
      throw Error((error as Error).message);
    }    


  }

  async redeem (nft: INft, setStatus: any) {
    //const contractAddress = config.articleNftContractAddress; //@TODO: should not be hardcoded

    console.log("buy", { nft });
    try {
      // ethers Contract
      const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any"); //@TODO: get from Context
      const contract = new ethers.Contract(this.contractAddress, articleLazyNFT.abi, provider.getSigner());

      // function redeem(bytes calldata signature, ArticleVoucher calldata voucher, uint16 slotPos) public payable returns (uint256) {
      const overrides = {
        value: ethers.utils.parseEther(nft.priceFixed ? nft.priceFixed.toString() : "0"),
      };
      const signature = (nft.voucher as any).signature;
      const voucher = (nft.voucher as any).value;
      const position = nft.position;

      console.log('before contract.redeem',{
        contract,
        provider,
        signature,
        voucher: (nft.voucher as any).value,
        position,
        overrides
      });
      let tx = await contract.redeem(signature, voucher, position, overrides);
      console.log("after redeem()", { tx });
      const txResult = await tx.wait();
      console.log("after tx.wait", {txResult});

      const eventMint = txResult.events[0];
      const tokenId = (eventMint.args[1] as BigNumber).toNumber();

      console.log('after mint', {tokenId});
      setStatus(`Thank you for your purchase`);

    } catch (error) {
      console.error('redeem error', {error});
      setStatus(`Could not buy. ${(error as Error).message}`);
      throw Error((error as Error).message);
    }    
  }

  async bid (nft: INft, bidAmount: number, setStatus: any, withForceSync: boolean = true) {
    console.log("bid on", { nft });

    try {

      // ethers Contract
      const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any"); //@TODO: get from Context
      const contract = new ethers.Contract(this.contractAddress, articleLazyNFT.abi, provider.getSigner());

      const overrides = {
        value: ethers.utils.parseEther(bidAmount.toString()),
      };

      const signature = (nft.voucher as any).signature;
      const voucher = (nft.voucher as any).value;
      const position = nft.position;

      console.log('before contract.bidAuctionVoucher',{
        contract,
        provider,
        signature,
        voucher: (nft.voucher as any).value,
        position,
        overrides
      });

     // contract method:
     // function bidAuctionVoucher(bytes calldata signature, Voucher calldata voucher, uint16 pos) public payable {    
      let tx = await contract.bidAuctionVoucher(signature, voucher, position, overrides);
      console.log("after bidAuctionVoucher()", { tx });
      const txResult = await tx.wait();
      console.log("after tx.wait", {txResult});

      const eventBid = txResult.events[0];
      //const tokenId = (eventMint.args[1] as BigNumber).toNumber();

      console.log('after bid', {eventBid});
      setStatus(`Thank you for your bid`);

    } catch (error: any) {
      console.error('bid error', {error});
      if (error.code && error.code === 4001) {
        throw Error(error.message);
      }
      
      if ( error.data.data.reason === NFTErrorEnum.ERR_AUCTION_IS_NOT_ACTIVE && withForceSync) {
        console.log('forcing sync');
        // try again after sync
        try {
          const response = await syncNftApiService.forceSync();
          console.log('forcesync response', {response});
          console.log('retrying');
          this.bid(nft, setStatus, false);
        } catch (error) {
          console.error('forcesync error', {error});
          throw Error((error as Error).message);
        }
      } else {
        const message = NFTErrorEnumName.get((error as any).data.data.reason) || (error as any).data.data.reason;
        throw Error(message);
      }
    }    
  }

  // contract method
  // function finishAuctionVoucher(bytes calldata signature, Voucher calldata voucher, uint16 pos, bool mint) external {
  // https://redmine.convenahealth.com/issues/125
  async finalizeAuction ( nft: INft, shouldMint: boolean = false, withForceSync: boolean = true) {
    try {
      // ethers Contract
      const provider = new ethers.providers.Web3Provider((window as any).ethereum, "any"); //@TODO: get from Context
      const contract = new ethers.Contract(this.contractAddress, articleLazyNFT.abi, provider.getSigner());

      // function finishAuctionVoucher(bytes calldata signature, Voucher calldata voucher, uint16 pos, bool mint) external {
      const signature = (nft.voucher as any).signature;
      const voucher = (nft.voucher as any).value;
      const position = nft.position;

      console.log('contract.finishAuctionVoucher params', {
        signature,
        voucher: (nft.voucher as any).value,
        position,
        shouldMint,
      });

      let tx = await contract.finishAuctionVoucher(signature, voucher, position, shouldMint);
      console.log("after finalizeAuction()", { tx });
      const txResult = await tx.wait();
      console.log("after finalizeAuction tx.wait", {txResult});

      const eventMint = txResult.events[0];
      const tokenId = (eventMint.args[1] as BigNumber).toNumber();

      console.log('after finalize', {tokenId});

    } catch (error: any) {

      if (error.data && error.data.data.reason) {
        if ( error.data.data.reason === NFTErrorEnum.ERR_AUCTION_IS_ACTIVE && withForceSync) {
          // try again after sync
          try {
            const response = await syncNftApiService.forceSync();
            console.log('forcesync response', {response});
            console.log('retrying');
            await this.finalizeAuction(nft, shouldMint, false);
          } catch (error) {
            console.error('forcesync error', {error});
            throw Error((error as Error).message);
          }
        } else {
          const message = NFTErrorEnumName.get(error.data.data.reason) || error.data.data.reason;
          throw Error(message);
        }
      } else {
        throw Error((error as Error).message);
      }
    }  
  }

}

export { NFTService }