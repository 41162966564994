import * as BaseJoi from "joi";
import {AnySchema, CustomHelpers} from "joi";
import {PriceTypeEnum} from "../dict/price-type.enum";
import JoiDate from "@joi/date";
import {ArticleSlot} from "../interface/article-slot";
import {BigNumber, utils} from "ethers";
import {ValidationError} from "../errors/validation.error";

const Joi = BaseJoi.extend(JoiDate) as BaseJoi.Root;

export class CommonValidator {

    public static voucherMintValue(now: Date = new Date()) {
        const customSlots = (value:ArticleSlot[], helpers:CustomHelpers) => {
            if (!value || value.length==0) {
                return value;
            }
            //@TODO: user can add new pos or not? if yes, it can start from any number
            // for (let i=0;i<value.length;i++) {
            //     const item = value[i];
            //     if (item.pos!=i) {
            //         return helpers.message({custom:`invalid pos number #${item.pos}`});//error('any.invalid').message();
            //     }
            // }
            return value;
        }
        const value = Joi.object().keys({
            owner:  this.ethAddress().required(),
            uri: Joi.string().uri().required(),
            fee: this.fee(),
            slots: Joi.array().items(this.articleSlot(now)).min(1).custom(customSlots).required()
        });
        return value;
    }

    public static voucherMint() {
        return this.voucher(this.voucherMintValue());
    }

    public static voucherSell() {
        const value = Joi.object().keys({
            owner:  this.ethAddress().required(),
            //buyer: this.ethAddress().required(),
            tokenId: Joi.number().required(),
            spots: Joi.array().items(Joi.number()).min(1).required(),
            price: this.price(),
            fee: this.fee(),
            expireTimestamp: Joi.number().required(),
        });
        return this.voucher(value);
    }

    protected static voucher(value: AnySchema) {
        return Joi.object({
          domain: Joi.object().required(),
          types: Joi.object().required(),
          value: value,
          signature: Joi.string(),
      });
    }

    public static articleSlot(now: Date = new Date()) {
        const secsNow = Math.round(now.getTime()/1000);
        const day = 86400;
        // const minStart = secs-day;
        // const maxEnd =
        const allowOnlyZero = ()=>Joi.custom((value:any, helpers:CustomHelpers)=>{
            try {
                const number = BigNumber.from(value);
                if (!number.isZero()) {
                    return helpers.error('any.invalid');
                }
                return value;
            } catch (e) {
                return helpers.error('any.invalid');
            }
        });
        const priceType = 'priceType';
        const reqFixed = ()=>Joi.when(priceType, {
            is: Joi.equal(PriceTypeEnum.FIXED),
            then: this.price(),
            otherwise: allowOnlyZero()
        });
        const reqAuction = ()=>Joi.when(priceType, {
            is: Joi.equal(PriceTypeEnum.AUCTION),
            then: this.price(),
            otherwise: allowOnlyZero(),
        });
        const protectionDateEnd = ()=>Joi.when('amount', {
            not: Joi.equal(1),
            then: Joi.number().min(secsNow).max(secsNow+day*90).required(),
            otherwise: Joi.valid(0)
        });
        const auctionDateStart = ()=>Joi.when(priceType, {
            is: Joi.equal(PriceTypeEnum.AUCTION),
            then: Joi.number().min(secsNow-day).max(secsNow+day*90).required(),
            otherwise: Joi.valid(0)
        });
        const auctionDateEnd = ()=>Joi.when(priceType, {
            is: Joi.equal(PriceTypeEnum.AUCTION),
            then: Joi.number()
                    .min(Joi.ref('auctionStart', {adjust:v=>v+day}))
                    .max(Joi.ref('auctionStart', {adjust:v=>v+day*120}))
                    .required(),
            otherwise: Joi.valid(0)
        });

        const sc = Joi.object({
            pos: Joi.number().min(0).required(),
            amount: Joi.number().min(1).required().when(priceType, {
                is: Joi.equal(PriceTypeEnum.AUCTION),
                then: Joi.number().equal(1), // auction supports only 1 spot
            }),
            uri: Joi.string().uri(),
            [priceType]: Joi.number().valid(...[PriceTypeEnum.FIXED,PriceTypeEnum.AUCTION]),
            priceFixed: reqFixed(),//.custom(this.toBigNumber),
            protectionEnd: protectionDateEnd(),
            priceStart: reqAuction().allow(0),//.custom(this.toBigNumber),
            priceReserve: reqAuction().allow(0),//.custom(this.toBigNumber),
            auctionStart: auctionDateStart(),
            auctionEnd: auctionDateEnd(),
        });
        return sc;
        // const result = sc.validate(data);
        // if (result.error) {
        //     throw new ValidationError(result.error.message);
        // }
        // return result.value;
    }

    static ethAddress() {
        return Joi.string().regex(/^0x[A-Fa-f0-9]{40}$/);
    }

    static toBigNumber(value:any, helpers?:CustomHelpers) {
        if (!value) {
            return value;
        }
        if (value instanceof BigNumber) {
            return value;
        }
        return utils.parseEther(value.toString());
    }

    static validate(sc: AnySchema, data:any) {
        const result = sc.validate(data);
        if (result.error) {
            throw new ValidationError(result.error.message);
        }
        return result.value;
    }

    protected static fee() {
        return Joi.number().min(0).less(100*100).required()
    }

    protected static price() {
        //can be instance of BigNumber or has  "type" property
        // {
        //     "type": "BigNumber",
        //     "hex": "0x0f43fc2c04ee0000"
        // }
        //ethers.BigNumber.isBigNumber
        return Joi.object().allow(0).custom((value,  helpers:CustomHelpers)=>{
            try {
                const number = BigNumber.from(value);
                if (number.isNegative()) {
                    throw new Error('should be positive');
                }
                return value;
            } catch (e) {
                return helpers.error('any.invalid');
            }

            // try {
            //     return BigNumber.from(value)
            // } catch (e) {
            //     return value;
            // }
        });//.instance(BigNumber);
    }
}
