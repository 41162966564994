import * as Yup from 'yup';

enum Validator {
  ADMIN_CONTRACT_FORM = 'admin_contract_form',
  ADMIN_LAYOUT_FORM = 'admin_layout_form',

  COMMON_ADDRESS = 'common_address',
  BILLING_ADDRESS = 'billing_address',
  SHIPPING_ADDRESS = 'shipping_address',
  ACTIVATION_FORM = 'activation_form',
  REGISTRATION_FORM = 'registration_form',
  PROFILE_FORM = 'profile_form',
  CHECKOUT_FORM = 'checkout_form', // anonymous, shipping validator only, billing is same
  CHECKOUT_FORM_LOGGED_IN = 'checkout_form_logged_in', // logged in, shipping validator only, billing is same, logged in
  CHECKOUT_FORM_WITH_BILL = 'checkout_form_with_bill', // anonymous, diff billing so additional validator for billing
  CHECKOUT_FORM_WITH_BILL_LOGGED_IN = 'checkout_form_with_bill_logged_in', // logged in, diff billing so additional validator for billing
  CHECKOUT_FORM_BILL_ONLY = 'checkout_form_bill_only',
  CHECKOUT_FORM_BILL_ONLY_LOGGED_IN = 'checkout_form_bill_only_logged_in',
  FORGOT_PASSWORD = 'forgot_password_form',
  RESET_PASSWORD_FORM = 'reset_password_form',
  SIMPLE_REGISTRATION = 'simple_registration',
  CONFIRM_PROFILE_FORM = 'confirm_profile_form',
  VERIFY_INSURANCE_FORM = 'verify_insurance_form',

  COLLECTION_FORM = 'collection_form',
  ARTICLE_FORM = 'article_form',
  NFT_FORM = 'nft_form',
  ARTICLE_NFT_FORM = 'article_nft_form',

  ARTICLE_BID_FORM = 'article_bid_form',
  ARTICLE_OFFER_FORM = 'article_offer_form',

  NFT_EDIT_FORM = 'nft_edit_form',
  TAG_FORM = 'tag_form',
  SPOT_RESELL_FORM = 'spot_resell_form',


}
const validationMap = new Map<string, object>([]);

/*
// @TODO: use this for strong password
const password = Yup
  .string()
  .required("Please enter your password")
  .matches(
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  )
*/

const password = Yup.string().required("Please enter your password") //@TODO: this should not be in production. for testing only.


const confirmPassword = Yup
  .string()
  .required("Please confirm your password")
  .when("password", {
    is: (password: string) => (password && password.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref("password")], "Password doesn't match")
  })

const phoneNumber = Yup
  .string()
  .required("Phone Number is required")
  .matches(
    /[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    "Please use this format: 123-456-7890"
  )

const usState = Yup
.string()
.required("Shipping State is required");

validationMap.set(Validator.COMMON_ADDRESS, Yup.object().shape({
  email: Yup.string().email("Must be a valid email address").required("Email is Required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber,
}));


const shippingValidators = {
  shippingFirstName: Yup.string().required("First Name is required"),
  shippingLastName: Yup.string().required("Last Name is Required"),
  shippingAddress1: Yup.string().required("Shipping Address is required"),
  shippingCity: Yup.string().required("Shipping City is required"),
  shippingState: usState,
  shippingZip: Yup.string().required("Shipping Zip is required"),
  shippingPhoneNumber: phoneNumber,
};

const billingValidators = {
  billingFirstName: Yup.string().required("First Name is required"),
  billingLastName: Yup.string().required("Last Name is Required"),
  billingAddress1: Yup.string().required("Billing Address is required"),
  billingCity: Yup.string().required("Billing City is required"),
  billingState: Yup.string().required("Billing State is required"),
  billingZip: Yup.string().required("Billing Zip is required"),
  billingPhoneNumber: phoneNumber,
}

const simpleRegistrationValidators = {
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  password,
  confirmPassword,
  agreeTerms: Yup.boolean().oneOf([true], "You must accept the Terms and Conditions"),

}

validationMap.set(Validator.SHIPPING_ADDRESS, Yup.object().shape(shippingValidators));
validationMap.set(Validator.BILLING_ADDRESS, Yup.object().shape(billingValidators));

// anonymous, ship and bill the same
validationMap.set(Validator.CHECKOUT_FORM, Yup.object().shape({
  ...shippingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, ship and bill the same
validationMap.set(Validator.CHECKOUT_FORM_LOGGED_IN, Yup.object().shape({
  ...shippingValidators,
}));

// anonymous, bill different
validationMap.set(Validator.CHECKOUT_FORM_WITH_BILL, Yup.object().shape({
  ...billingValidators,
  ...shippingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, bill different
validationMap.set(Validator.CHECKOUT_FORM_WITH_BILL_LOGGED_IN, Yup.object().shape({
  ...billingValidators,
  ...shippingValidators,
}));


// anonymous, billing only (premailed checkout)
validationMap.set(Validator.CHECKOUT_FORM_BILL_ONLY, Yup.object().shape({
  ...billingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, billing only (premailed checkout)
validationMap.set(Validator.CHECKOUT_FORM_BILL_ONLY_LOGGED_IN, Yup.object().shape({
  ...billingValidators,
}));


validationMap.set(Validator.ACTIVATION_FORM, Yup.object().shape({
  activationCode: Yup.string().required("Activation Code Required"),
  codeA: Yup.string().required('Required'),
  codeB: Yup.string().required('Required'),
}));

validationMap.set(Validator.REGISTRATION_FORM, Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  password,
  confirmPassword,
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber,
  agreeTerms: Yup.boolean().oneOf([true], "You must accept the Terms and Conditions"),
}));

validationMap.set(Validator.PROFILE_FORM, Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber,
}));

validationMap.set(Validator.FORGOT_PASSWORD, Yup.object().shape(
  {
    emailAddress: Yup.string().email("Must be valid email address").required("Email is Required"),
  }
))

validationMap.set(Validator.RESET_PASSWORD_FORM, Yup.object().shape(
  {
    password,
    confirmPassword,
  }
))

validationMap.set(Validator.SIMPLE_REGISTRATION, Yup.object().shape(simpleRegistrationValidators));

validationMap.set(Validator.CONFIRM_PROFILE_FORM, Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber: Yup
    .string().required("In case we need to contact you about your results, your phone number is required")
    .matches(
      /[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
      "Please use this format: 123-456-7890"
    ),
  agreeHipaa: Yup.boolean().oneOf([true], "You must accept the HIPAA Authorization"),
  agreeProductConsent: Yup.boolean().oneOf([true], "You must accept the Product Consent"),
  dateOfBirth: Yup.string().required("You must provide your date of birth"),
}));

validationMap.set(Validator.VERIFY_INSURANCE_FORM, Yup.object().shape({
  insuranceProvider: Yup.string().required("Insurance Provider is required"),
  memberId: Yup.string().required("Member ID is required"),
  stateCoverage: Yup.string().required("State Coverage is required"),
}));


validationMap.set(Validator.COLLECTION_FORM, Yup.object().shape({
  name: Yup.string().required("Collection Name is required"),
  description: Yup.string().required("Description is required"),
}))

validationMap.set(Validator.ARTICLE_FORM, Yup.object().shape({
  collectionId: Yup.number().required("Collection Name is required"),
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  image: Yup.string().required("Image is required"),
  url: Yup.string().required("Url is required"),
}))

validationMap.set(Validator.NFT_FORM, Yup.object().shape({
  articleId: Yup.number().required("Article ID is required"),
  position: Yup.string().required("Position is required"),
  price: Yup.string().required("price is required"),
  voucher: Yup.string().required("voucher is required"),
}))


validationMap.set(Validator.ARTICLE_NFT_FORM, Yup.object().shape({
  collectionUid: Yup.number().required("Collection required"),
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  image: Yup.string().required("Image is required"),
  url: Yup.string().required("Url is required"),
  slots: Yup.array().of(
    Yup.object().shape({
      pos: Yup.number().typeError('Must be a number').required('Invalid position. Please enter a number'),
      priceType: Yup.number().typeError('Must be a number').required('Invalid price type. Please enter a number'),
      priceFixed: Yup.number().typeError('Must be a number').required('Invalid price fixed. Please enter a number'),
      // priceStart: Yup.number().typeError('Must be a number').required('Invalid price. Please enter a number'),
      // priceReserve: Yup.number().typeError('Must be a number').required('Invalid price. Please enter a number'),
      // auctionStart: Yup.number().typeError('Must be a number').required('Invalid price. Please enter a number'),
      // auctionEnd: Yup.number().typeError('Must be a number').required('Invalid price. Please enter a number'),
      
    })
  )
}))

validationMap.set(Validator.ADMIN_CONTRACT_FORM, Yup.object().shape({
  // networkId: Yup.number().required("Network ID is required"),
  statusId: Yup.string().required("Status ID is required"),
  name: Yup.string().required("Contract Name is required"),
  description: Yup.string().required("Contract Description is required"),
  address: Yup.string().required("Contract Address is required"),
  abi: Yup.string().required("abi is required"),
}))

validationMap.set(Validator.ARTICLE_BID_FORM, Yup.object().shape({
  bid: Yup.number().required("Please provide your bid amount.")
}))

validationMap.set(Validator.ARTICLE_OFFER_FORM, Yup.object().shape({
  price: Yup.number().moreThan(0, 'Should be more than 0').typeError('Must be a number').required("Please provide the amount your want to offer for this NFT"),
  expireDays: Yup.number().moreThan(0, 'Should be more than 0').lessThan(8, 'Should be 7 days or less').typeError('Must be a number').required("Please provide number of days this offer is good for"),
  agreeTerms: Yup.boolean().oneOf([true], "Check that you agree to the terms above"),
}))


validationMap.set(Validator.NFT_EDIT_FORM, Yup.object().shape({
  priceStart: Yup.number().typeError('Must be a number').required('Invalid Starting Price. Please enter a number'),
  priceReserve: Yup.number().typeError('Must be a number').required('Invalid Reserve Price. Please enter a number'),
  auctionStart: Yup.number().typeError('Must be a number').required('Invalid Auction Start. Please enter a number'),
  auctionEnd: Yup.number().typeError('Must be a number').required('Invalid Auction End. Please enter a number'),
}))

validationMap.set(Validator.ADMIN_LAYOUT_FORM, Yup.object().shape({
  name: Yup.string().required("name is required"),
  header: Yup.string().required("header is required"),
  title: Yup.string().required("title is required"),
  typeId: Yup.number().typeError('type id must be a number')
}))

validationMap.set(Validator.TAG_FORM, Yup.object().shape({
  layoutUid: Yup.string().required("Layout ID is required"),
  articleUid: Yup.string().typeError("Article UID must be a number"),
  spotUid: Yup.number().typeError("Spot ID must be a number"),
  name: Yup.string().required("Name is required"),
}))


validationMap.set(Validator.SPOT_RESELL_FORM, Yup.object().shape({
  price: Yup.number().typeError("Must be a number").moreThan(0, 'Must be more than 0'),
}))

export { validationMap, Validator }
