import { ArticleSlot } from "mdlib/interface/article-slot";
import React, { useContext, useEffect, useState } from "react";
import { NftEventTypeEnum } from "_constants/NftEventTypeEnum";
import { IArticle, IArticleSlot, INft, INftEvent, ISpot } from "_interfaces";
import { MessengerContext } from "_providers";
import { customerArticleApiService, customerNftEventsApiService, nftService } from "_services";
import { ItemActivity, BuyItemDetail, SiblingSlots } from "./common";


const NftBuyPage: React.FunctionComponent = (props: any) => {

  const [nft, setNft] = useState<INft>({} as INft); // the slot being bid on
  const [status, setStatus] = useState<string>("");
  const [article, setArticle] = useState<IArticle>({} as IArticle);
  const [ counter, setCounter] = useState<number>(0);
  const [ buyHistory, setBuyHistory] = useState<INftEvent[]>([]);

  const nftUid: number = props.match.params.nftUid ? parseInt(props.match.params.nftUid) : 0;
  const articleUid = props.match.params.articleUid;

  const messengerContext = useContext(MessengerContext);

  useEffect(() => {
    const _load = async () => {
      try {
        const article = await customerArticleApiService.getById(articleUid, false);

        if (!article.slots) {
          throw Error("No Slots. Invalid Article");
        }
        const slots = article.slots.filter((slot: ArticleSlot) => (slot as IArticleSlot).uid === nftUid);

        const nftEvents = await customerNftEventsApiService.getByNftId(nftUid);
        setBuyHistory(nftEvents.filter((e: INftEvent) => e.typeId === NftEventTypeEnum.SALE));


        console.log({slots: article.slots});
        console.log(article);
        setNft(slots[0]);
        setArticle(article);
      } catch (error: any) {
        console.error(error);
        setStatus(error.message);

      }
    };

    _load();
  }, [articleUid, nftUid, counter]);


  const setState = (status: string) => {
    setStatus(status);
    messengerContext.setMessage({title: "Buy NFT", body: status});
  }

  const buy = async (nft: INft) => {
    try {
      await nftService.redeem(nft, setState);
      setCounter(counter + 1);
    } catch (error: any) {
      console.error('failed buy', {error});
      messengerContext.setMessage({title: "Error", body: `Failed: ${(error as Error).message}`});
    }
  };

  const buySpot = async (spot: ISpot) => {
    console.log('buySpot', {voucher: spot.voucher});
    try {
      await nftService.redeemSpot(spot, setStatus);
      setCounter(counter + 1);
      messengerContext.setMessage({title: 'NFT Purchase', body: 'Purchase complete.'});
    } catch (error: any) {
      console.error('buySpot Error', {error});
      setStatus((error as Error).message);
    }
  }

  const onSuccessfulOffer = (message: string | React.ReactNode) => {
    messengerContext.setMessage({title: 'Offer', body: message});
    setCounter(counter + 1);
  }

  return (
    <>
      <BuyItemDetail article={article} nft={nft} onBuy={buy} itemHistory={buyHistory} onBuySpot={buySpot} onSuccessfulOffer={onSuccessfulOffer}/>

      <ItemActivity nft={nft} counter={counter}/>

      {article.uid &&  <SiblingSlots article={article} nft={nft}/>}

      {status && <div className="alert alert-warning">{status}</div>}
    </>
  );
};


export { NftBuyPage }
