import React from "react";
import { Helper } from "_common";
import { IArticleNft, INft, INftEvent, ISpot } from "_interfaces";
import { ItemHistory } from "./ItemHistory";
import { Resale } from "./Resale";

const ItemDetailLayout: React.FunctionComponent<{ 
  article: IArticleNft; 
  nft: INft, 
  itemHistory: INftEvent[],
  onSuccessfulOffer: (message: string | React.ReactNode) => void, 
  onBuy?: { (nft: INft): Promise<void> }
  onBuySpot?: { (spot: ISpot): Promise<void> },
  spot?: ISpot }> = ({ article, nft, children, itemHistory, spot, onSuccessfulOffer, onBuy, onBuySpot }) => {

  return (
    <div className="item-single section-padding">
      <div className="container">
        <div className="row">
          <div className="col-xxl-12">
            <div className="top-bid tf-item-details">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <img src={article.imageUrl} className="img-fluid rounded" alt="..." />

                    <div className="my-5">
                      <ItemHistory nftEvents={itemHistory} />
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="content-right">
                      <div className="sc-item-details">
                        <h2 className="style2">{article.title}</h2>
                        <div className="meta-item">
                          <div className="left">
                            <span>
                              <i className="bi bi-eye lh-0 me-2"></i> 225
                            </span>
                            <span>
                              <span className="number-like ms-3">
                                <i className="bi bi-heart lh-0 me-2"></i>
                                100
                              </span>
                            </span>
                          </div>
                          <div className="right">
                            <a href="/">
                              <i className="ri-send-plane-fill"></i>
                            </a>
                          </div>
                        </div>
                        <div className="client-infor sc-card-product">
                          {/* <div className="meta-info">
                            <div className="author">
                              <div className="avatar">
                                <img src="/images/avatar/8.jpg" alt="" />
                              </div>
                              <div className="info">
                                <span>Owned By</span>
                                <h6>
                                  <a href="author02.html">Ralph Garraway</a>
                                </h6>
                              </div>
                            </div>
                          </div> */}
                          <div className="meta-info">
                            <div className="author">
                              <div className="avatar">
                                <img src={article.creatorAvatarUrl} alt="" width="100%" />
                              </div>
                              <div className="info">
                                <span>Created By</span>
                                <h6>
                                  {nft.voucher && Helper.shortenEthAddress((nft.voucher as any).value.owner)}
                                  {/* <a href="author02.html">Freddie Carpenter</a> */}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p>{article.description}</p>

                        {nft &&
                        <p>Slot: {(nft.position || 0) + 1}</p>
                        }

                        {children}

                        {/* available slots here */}
                        {/* {nft.priceTypeId === NftPriceTypeEnum.FIXED &&
                        <Resale nft={nft} spot={spot} onBuy={onBuy} onBuySpot={onBuySpot}/>
                        } */}
                        <Resale nft={nft} spot={spot} onBuy={onBuy} onBuySpot={onBuySpot} onSuccessOffer={onSuccessfulOffer}/>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ItemDetailLayout };
