import React, { useEffect, useState } from 'react';
import { NftEventTypeEnumName } from '_constants/NftEventTypeEnum';
import { INft, INftEvent } from '_interfaces';
import { customerNftEventsApiService } from '_services';
import { Helper } from '_common';

const ItemActivity: React.FunctionComponent<{nft: INft, counter: number}> = ({nft, counter}) => {

  const [nftEvents, setNftEvents] = useState<INftEvent[]>([]);

  useEffect(()=> {

    const _load = async () => {
      if (nft.uid) {
        const nftEvents = await customerNftEventsApiService.getByNftId(nft.uid);
        setNftEvents(nftEvents);
      }
    }
    _load();
  }, [nft.uid, counter])

  return(
    <div className="activity-table section-padding">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <h4 className="card-title mb-3">Item Activity</h4>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Event</th>
                      <th>Price</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nftEvents.length > 0 && nftEvents.sort((a,b) => {return a.creationTimestamp && b.creationTimestamp && a.creationTimestamp < b.creationTimestamp ? 1 : -1}).map((e: INftEvent, i: number) => <tr key={`__key_${i}`}>
                      <td>{e.typeId ? NftEventTypeEnumName.get(e.typeId): 'N/A'}</td>
                      <td>{Helper.formatEth(e.price)}</td>
                      <td>{e.actorEthAddress}</td>
                      <td>{e.toEthAddress}</td>
                      <td>{e.creationTimestamp}</td>
                    </tr>)}

                    {nftEvents.length === 0 && 
                    <tr><td className="text-center" colSpan={4}>No Activity</td></tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    
  )
}

export { ItemActivity }