import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FieldWithError } from "_common";

const CustomUrlForm: React.FunctionComponent<{ customUrl: string }> = ({ customUrl }) => {
  const initialValues = {
    customUrl: customUrl,
  };

  const [message, setMessage] = useState<string>('');

  const copyToClipboard = async (customUrl: string) => {
    try {
      await navigator.clipboard.writeText(customUrl);
      setMessage('Copied!');
    } catch (err) {
      setMessage('Failed to copy!');
    }    
  }

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {({ errors, touched, values }) => {
        return (
          <Form className="form">
            <FieldWithError errors={errors} touched={touched} fieldName="customUrl" label="Custom URL" placeholder="0" />
            <button type="button" className="btn btn-primary my-2" onClick={()=>copyToClipboard(values["customUrl"])}>Copy</button>
            {message && <div className="alert alert-warning">{message}</div>}
          </Form>
        );
      }}
    </Formik>
  );
};

const ModalCustomUrl: React.FunctionComponent<{ show: any; handleClose: any; customUrl: string }> = ({
  show,
  handleClose,
  customUrl,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">My Custom URL</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <CustomUrlForm customUrl={customUrl}/>
      </Modal.Body>
    </Modal>
  );
};

export { ModalCustomUrl };
