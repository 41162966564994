import { IConfig } from '../_interfaces';

export default {
  apiDomain: 'https://portal-api.devex.mintdesk.co',
  apiBasePath: '/v1',
  tokenName: 'tk',
  stripePublicKey: "pk_test_51JeJ9mD3dLGEWyLk4vdqnayzSH39rMXpP5FbFt9TrPvnGYZdob0lwcoHTmRkJBNR72tJVpDMy0rnDykc6XuOfE5t00pfivjbnP", 
  ipfsUrl: 'https://ipfs.infura.io/ipfs',
  ipfsClientUrl: 'https://ipfs.infura.io:5001/api/v0',
  articleNftContractAddress: '0x39c6aDfE1A0713566A8184d7fF3a276d71091f11',
  contractId: 17,
  transactionFeePct: 2.5,
} as IConfig

