interface PageTitleProps {
	pageTitle: string;
	pageTitleSub: string;
	parent: string;
	child: string;
}

function PageTitle({ pageTitle, pageTitleSub, parent, child }: PageTitleProps) {
	return (
		<>
			<div className="page-title">
				<div className="row align-items-center justify-content-between">
					<div className="col-6">
						<div className="page-title-content m-3">
							<h3>{pageTitle}</h3>
							<p className="mb-2">{pageTitleSub}</p>
						</div>
					</div>

				</div>
			</div>
		</>
	);
}
export default PageTitle;
