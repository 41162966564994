import { IAccount, ISignature } from "_interfaces";
import axios from "axios";
import { config } from "_config";
import { customerAuthenticationService } from "_services";
import { StorageKey } from "_constants";
import { ethers } from "ethers";

class CustomerAccountService {
  constructor (
    protected apiBaseUrl: string,
  ) {}

  async register(registrationData: IAccount): Promise<any> {
    const url = `${this.apiBaseUrl}/registration`;
    try {
      const response = await axios.post(url, registrationData);
      if (response.data.error) {
        console.error('throwing', response.data.error);
        throw Error(response.data.error.message);
      }
      localStorage.setItem(StorageKey.CUSTOMER_TOKEN, response.data.token);
      await customerAuthenticationService.setLocalAccount();
      return customerAuthenticationService.getLocalAccount();
    } catch (err: any) {
      console.error('error in reg', {err});
      throw Error(err.message);
    }
  }

  async checkEthAddress(ethAddress: string): Promise<boolean> {
    const url = `${this.apiBaseUrl}/check-eth-address`;
    try {
      const response = await axios.post(url, {ethAddress});
      if (response.data.error) {
        console.error('throwing', response.data.error);
        throw Error(response.data.error.message);
      }
      return response.data.success as boolean
    } catch (err: any) {
      console.error('error in reg', {err});
      throw Error(err.message);
    }    
  }

  async registerWallet(account: IAccount, signature: ISignature): Promise<any> {
    const url = `${this.apiBaseUrl}/registration-eth-address`;
    try {
      const { email, username, password } = account;

      const postData = {
        email,
        username,
        password,
        ethAddress: signature.ethAddress,
        auth:  {
          signature: signature.signature,
          message: signature.message
        }
      };
      const response = await axios.post(url, postData);
      if (response.data.error) {
        console.error('throwing', response.data.error);
        throw Error(response.data.error.message);
      }
      localStorage.setItem(StorageKey.CUSTOMER_TOKEN, response.data.token);
      await customerAuthenticationService.setLocalAccount();
      return customerAuthenticationService.getLocalAccount();
    } catch (err: any) {
      console.error('error in registerWallet', {err});
      throw Error(err.message);
    }    
  }

  async getAuthMessage(address: string): Promise<any> {
    const url = `${this.apiBaseUrl}/auth-message`;
    try {
      const response = await axios.post(url, {ethAddress: address});
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (error: any) {
      console.error('error in getAuthMessage', error);
      throw Error(error.message);
    }
  }

  async getSignature(provider: ethers.providers.Web3Provider, message: string): Promise<any> {
    const expiry = localStorage.getItem(StorageKey.WEB3_EXPIRY);
    const currentTime = new Date().getTime();

    if ((expiry && currentTime > Number.parseInt(expiry)) || !localStorage.getItem(StorageKey.WEB3_SIGNATURE)) {
      // get new signature
      try {
        console.log('refreshing signature', {provider});
        const signer: ethers.providers.JsonRpcSigner = provider.getSigner();
        const signature = await signer.signMessage(message);
        const ethAddress = await signer.getAddress();
  
        const signed: ISignature = {
          message,
          signature,
          ethAddress
        };
  
        const newExpiry = new Date().getTime() + (24 * 60 * 60 * 1000);
        localStorage.setItem(StorageKey.WEB3_SIGNATURE, JSON.stringify(signed));
        localStorage.setItem(StorageKey.WEB3_EXPIRY, newExpiry.toString());
        return signed;
          
      } catch (error) {
        console.error('signMessage error', error);      
      }
    } else {
      // get it from local
      console.log('getting from signature from local storage');
      const sig = localStorage.getItem(StorageKey.WEB3_SIGNATURE) || '' ;
      return JSON.parse(sig) as ISignature;
    }


  }

  async getSignatureFromSignedMessage (account: string, provider: ethers.providers.Web3Provider): Promise<{message: string, signature: string}> {
    const authMessage = await this.getAuthMessage(account);
    const signature: ISignature = await this.getSignature(provider, authMessage.data.message);
    const signed: ISignature = {
      ethAddress: account,
      message: signature.message,
      signature: signature.signature,
    }
    return signed;
  }


  isLoggedIn(): boolean {
    return customerAuthenticationService.getLocalAccount() !== null;
  }

  getAccount(): IAccount | null {
    return customerAuthenticationService.getLocalAccount();
  }

  async updateAccount(accountData: IAccount): Promise<IAccount | null> {
    const token = customerAuthenticationService.getToken();
    if (token === '' || token === null) {
      throw Error('Cannot update without a token');
    }
    const url: string = `${this.apiBaseUrl}/account`;
    try {
      const account = await axios.put(url, accountData, await customerAuthenticationService.getAuthHeader());
      if (!account.data || account.data.error) {
        throw Error(`error updating: ${account.data.error.message}`);
      }
      await customerAuthenticationService.setLocalAccount();
      return customerAuthenticationService.getLocalAccount();
    } catch (err: any) {
      throw Error(err);
    }
  }


  async requestResetPassword(emailAddress: string): Promise<any> {
    const url = `${this.apiBaseUrl}/request-reset-password`;
    const data = {
      email: emailAddress,
      ip: 0,
    }

    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      console.error({err});
      throw Error(err.message);
    }
  }

  async resetPassword(data: {authId: string, password: string, repeatPassword: string}) {
    const url = `${this.apiBaseUrl}/reset-password`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async confirmEmail(data: {authId: string}) {
    const url = `${this.apiBaseUrl}/confirm-email`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async verifyAccount() {
    const url = `${this.apiBaseUrl}/account/send-email-confirmation`;
    try {
      const response = await axios.post(url, {}, await customerAuthenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }


}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const customerAccountService = new CustomerAccountService(apiBaseUrl);
export { customerAccountService }