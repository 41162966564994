import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalWaiting: React.FunctionComponent<{ show: any }> = ({ show }) => {
  return(
    <Modal
    show={show}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Body>
      <div className="row justify-content-center">
        <div className="col-md-5">
  Waiting for Metamask          
        </div>
      </div>

    </Modal.Body>
  </Modal>

  );
}

export { ModalWaiting }